const initialState = {
  allDevices: null,
  unusedDevices: null
};

export default function deviceReducer(state = initialState, action) {
  switch (action.type) {
    case "ALL_DEVICES_FETCHED": {
      return {
        ...state,
        allDevices: [...action.data]
      };
    }
    case "UNUSED_DEVICES_FETCHED": {
      return {
        ...state,
        unusedDevices: [...action.data]
      }
    }
    case "NEW_DEVICE_ADDED": {
      return {
        ...state,
        allDevices: [
          ...state.allDevices,
          {...action.data}
        ]
      }
    }
    case "DEVICE_DELETED": {
      return {
        ...state,
        allDevices: [
          ...state.allDevices.filter(device => device.id !== action.deviceId)
        ]
      }
    }
    case "ONE_DEVICE_EDITED": {
      return {
        ...state,
        allDevices: [
          ...state.allDevices.map(device => {
            if (+device.id === action.data.id) {
              return {
                ...action.data
              };
            }
            return device;
          })
        ]
      }
    }
    default: {
      return state;
    }
  }
};