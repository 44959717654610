import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { closeFSPopUp } from "../../../actions/screen-activities";
import { archiveGroup } from "../../../actions/group";
// import { removeGroup, archiveGroup } from "../../../actions/group";

class DeleteGroup extends Component {
  componentDidMount = () => {
    const foundGroup = this.props.groupData.find(
      (group) => group.id === this.props.groupId
    );
    if (!foundGroup) {
      this.props.closeFSPopUp();
    }
  };

  deleteGroup = () => {
    // this.props.removeGroup(this.props.groupId);
    this.props.archiveGroup(this.props.groupId, Date.now());
    this.props.closeFSPopUp();
  };

  render() {
    const foundGroup = this.props.groupData.find(
      (group) => group.id === this.props.groupId
    );

    return (
      <Fragment>
        <div className="d-flex justify-content-end px-3 pb-3">
          <div className="container bg-light modal-body-container-scrollable">
            <div className="modal-body-container-internal">
              <div className="row mt-3">
                <h4 className="text-center">Delete Group</h4>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <div className="row ps-3 mt-3">
                    Are you sure you want to delete this group?
                  </div>
                  <div className="row ps-3 mt-3">Name: {foundGroup?.title}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-end mb-3 me-3">
          <button
            className="btn btn-light me-3"
            onClick={this.props.closeFSPopUp}
          >
            CANCEL
          </button>
          <button className="btn btn-armor-red me-3" onClick={this.deleteGroup}>
            DELETE
          </button>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    groupId: state.screenActivitiesReducer?.id,
    groupData: state.groupReducer?.allGroups,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    closeFSPopUp,
    archiveGroup,
    // removeGroup
  })(DeleteGroup)
);
