import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import moment from "moment";

import { faThermometerFull } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CONFIG from "../../config";

const { datatypes } = CONFIG;
const { HEARTRATE_DATATYPE, CORE_TEMPERATURE_DATATYPE, PSI_DATATYPE } =
  datatypes;

const oneMinuteInMs = 60000;
const minutes5inMs = 300000;

class SessionCard extends Component {
  render() {
    const heartrateData = this.props.selectedDatasession.latestData.find(
      (data) =>
        +data.deviceId === +this.props.meta.deviceId &&
        +data.datatype === HEARTRATE_DATATYPE
    );
    // console.log("Heartrate: ", heartrateData);
    // console.log("Heartrate: ", heartrateData?.deviceId, new Date(+heartrateData?.timestamp));
    const tempData = this.props.selectedDatasession.latestData.find(
      (data) =>
        +data.deviceId === +this.props.meta.deviceId &&
        +data.datatype === CORE_TEMPERATURE_DATATYPE
    );
    // console.log("tempData: ", tempData?.deviceId, new Date(+tempData?.timestamp));

    const psiLatest = this.props.selectedDatasession.latestData.find(
      (data) =>
        +data.deviceId === +this.props.meta.deviceId &&
        +data.datatype === PSI_DATATYPE
    );
    const highestPsi = this.props.selectedDatasession.maxPsi.find(
      (data) =>
        +data.deviceId === +this.props.meta.deviceId &&
        +data.datatype === PSI_DATATYPE
    );
    const tooOldData = Date.now() - +tempData?.timestamp > oneMinuteInMs * 2;
    const dataMissingLast5Minutes =
      Date.now() - +tempData?.timestamp > minutes5inMs;
    const maxPsiAndLatestPsiDifferent = +highestPsi?.value > +psiLatest?.value;

    const warningReached =
      +psiLatest?.value > +this.props.userSettings.warningThreshold
        ? true
        : false;
    const dangerReached =
      +psiLatest?.value > +this.props.userSettings.dangerThreshold
        ? true
        : false;
    const tempWarningReached =
      +tempData?.value > +this.props.userSettings.tempWarningThreshold
        ? true
        : false;
    const tempDangerReached =
      +tempData?.value > +this.props.userSettings.tempDangerThreshold
        ? true
        : false;

    if (tempData && psiLatest) {
      if (this.props.userSettings.assesmentPsiTemp === "PSI") {
        if (
          (heartrateData && +heartrateData.value === 1) ||
          dataMissingLast5Minutes
        ) {
          // Data corrupt PSI card
          return (
            <Fragment>
              <Link
                to={`/history/${+this.props.meta.deviceId}/chart/${+this.props
                  .meta.datasessionId}`}
                className="col-11 col-md-5 col-xl-4 col-xxl-3 m-1 m-md-3 pb-3 card-shadow"
              >
                <div className="row card-top-border bg-traffic-grey"></div>
                <div className="row mt-3 p-3">
                  <div className="col-8">
                    <div>Name/ID</div>
                    <h4>
                      <b>{this.props.meta.participantName}</b>
                    </h4>
                    <div>PSI</div>
                    <h2>
                      <b>--</b>
                    </h2>
                  </div>
                  <div className="col-4"></div>
                </div>
                <div className="row p-3">
                  <div className="col-6">
                    <div>Tcore</div>
                    <div>
                      <b>-- °C</b>
                    </div>
                  </div>
                  <div className="col-6 text-end">
                    <div>Heart Rate</div>
                    <div>
                      <b>-- bpm</b>
                    </div>
                  </div>
                </div>
              </Link>
            </Fragment>
          );
        }
        // PSI card - valid data
        return (
          <Fragment>
            <Link
              to={`/history/${+this.props.meta.deviceId}/chart/${+this.props
                .meta.datasessionId}`}
              className="col-11 col-md-5 col-xl-4 col-xxl-3 m-1 m-md-3 pb-3 card-shadow"
            >
              <div
                className={`row card-top-border 
                  ${tooOldData ? "opacity-20 " : ""}
                  ${
                    dangerReached
                      ? "bg-traffic-red"
                      : warningReached
                      ? "bg-traffic-yellow"
                      : "bg-traffic-green"
                  }
                `}
              ></div>
              <div className="row px-3 pt-3">
                <div className="col-6">
                  <div>Name/ID</div>
                  <h4>
                    <b>{this.props.meta.participantName}</b>
                  </h4>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-9">
                      {tooOldData ? (
                        <small className="text-traffic-red">
                          <b>
                            Last seen {moment(+tempData?.timestamp).fromNow()}
                          </b>
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-3 text-traffic-red">
                      {dangerReached ? (
                        <FontAwesomeIcon icon={faThermometerFull} size="lg" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row px-3">
                <div className="col-12">
                  <div>PSI</div>
                  <h2>
                    <b>
                      {psiLatest?.value}{" "}
                      {maxPsiAndLatestPsiDifferent
                        ? `[${highestPsi?.value}]`
                        : ""}
                    </b>
                  </h2>
                </div>
              </div>
              <div className="row px-3 pt-3">
                <div className="col-6">
                  <div>Tcore</div>
                  <div>
                    <b>{tempData?.value.toFixed(1)} °C</b>
                  </div>
                </div>
                <div className="col-6 text-end">
                  <div>Heart Rate</div>
                  <div>
                    <b>{heartrateData?.value.toFixed(0)} bpm</b>
                  </div>
                </div>
              </div>
            </Link>
          </Fragment>
        );
      } else {
        if (
          (heartrateData && +heartrateData.value === 1) ||
          dataMissingLast5Minutes
        ) {
          // Data corrupt Temperature card
          return (
            <Fragment>
              <Link
                to={`/history/${+this.props.meta.deviceId}/chart/${+this.props
                  .meta.datasessionId}`}
                className="col-11 col-md-5 col-xl-4 col-xxl-3 m-1 m-md-3 pb-3 card-shadow"
              >
                <div className="row card-top-border bg-traffic-grey"></div>
                <div className="row mt-3 p-3">
                  <div className="col-8">
                    <div>Name/ID</div>
                    <h4>
                      <b>{this.props.meta.participantName}</b>
                    </h4>
                    <div>Tcore</div>
                    <h2>
                      <b>-- °C</b>
                    </h2>
                  </div>
                  <div className="col-4"></div>
                </div>
                <div className="row p-3">
                  <div className="col-6">
                    <div>PSI</div>
                    <div>
                      <b>--</b>
                    </div>
                  </div>
                  <div className="col-6 text-end">
                    <div>Heart Rate</div>
                    <div>
                      <b>-- bpm</b>
                    </div>
                  </div>
                </div>
              </Link>
            </Fragment>
          );
        }
      
        // Temperature card - valid data
        return (
          <Fragment>
            <Link
              to={`/history/${+this.props.meta.deviceId}/chart/${+this.props
                .meta.datasessionId}`}
              className="col-11 col-md-5 col-xl-4 col-xxl-3 m-1 m-md-3 pb-3 card-shadow"
            >
              <div
                className={`row card-top-border 
                  ${tooOldData ? "opacity-20 " : ""}
                  ${
                    tempDangerReached
                      ? "bg-traffic-red"
                      : tempWarningReached
                      ? "bg-traffic-yellow"
                      : "bg-traffic-green"
                  }
                `}
              ></div>
              <div className="row px-3 pt-3">
                <div className="col-6">
                  <div>Name/ID</div>
                  <h4>
                    <b>{this.props.meta.participantName}</b>
                  </h4>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-9">
                      {tooOldData ? (
                        <small className="text-traffic-red">
                          <b>
                            Last seen {moment(+tempData?.timestamp).fromNow()}
                          </b>
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-3 text-traffic-red">
                      {tempDangerReached ? (
                        <FontAwesomeIcon icon={faThermometerFull} size="lg" />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row px-3">
                <div className="col-12">
                  <div>Tcore</div>
                  <h2>
                    <b>{tempData?.value.toFixed(1)} °C</b>
                  </h2>
                </div>
              </div>
              <div className="row px-3 pt-3">
                <div className="col-6">
                  <div>PSI</div>
                  <div>
                    <b>
                      {psiLatest?.value}{" "}
                      {maxPsiAndLatestPsiDifferent
                        ? `[${highestPsi?.value}]`
                        : ""}
                    </b>
                  </div>
                </div>
                <div className="col-6 text-end">
                  <div>Heart Rate</div>
                  <div>
                    <b>{heartrateData?.value.toFixed(0)} bpm</b>
                  </div>
                </div>
              </div>
            </Link>
          </Fragment>
        );
      }
    } else {
      return (
        <Fragment>
          {/* No data card */}
          <div className="col-11 col-md-5 col-xl-4 col-xxl-3 m-1 m-md-3 pb-3 card-shadow">
            <div className="row card-top-border bg-traffic-grey"></div>
            <div className="row mt-3 p-3">
              <div className="col-8">
                <div>Name/ID</div>
                <h4>
                  <b>{this.props.meta.participantName}</b>
                </h4>
                <div>Tcore</div>
                <h2>
                  <b>-- °C</b>
                </h2>
              </div>
              <div className="col-4"></div>
            </div>
            <div className="row p-3">
              <div className="col-6">
                <div>PSI</div>
                <div>
                  <b>--</b>
                </div>
              </div>
              <div className="col-6 text-end">
                <div>Heart Rate</div>
                <div>
                  <b>-- bpm</b>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    selectedDatasession: state.datasessionReducer?.selectedDatasession,
    userSettings: state.userReducer.armorUser.settings,
  };
}

export default withRouter(connect(mapStateToProps, {})(SessionCard));
