import React, { Component, Fragment } from 'react';

import { connect } from "react-redux";
import { withRouter } from "react-router";

import { closeFSPopUp } from '../../../actions/screen-activities';
import { logoutUser } from "../../../actions/user";

class LogOutBody extends Component {

  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-end px-3 pb-3">
          <div className="container bg-light modal-body-container-scrollable">
            <div className="modal-body-container-internal">
              <div className="row pt-3 ps-3">
                Are you sure you want to Log Out?
              </div>
            </div>
          </div>
        </div>
        <div className="text-end mb-3 me-3">
          <button 
            className="btn btn-light me-3"
            onClick={() => this.props.closeFSPopUp()}
          >
            CANCEL
          </button>
          <button 
            className="btn btn-armor-red me-3"
            onClick={() => this.props.logoutUser()}
          >
            LOG OUT
          </button>
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default withRouter(
  connect(mapStateToProps, {
    closeFSPopUp,
    logoutUser
  })(LogOutBody)
);
