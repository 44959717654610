import { faStop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter, matchPath } from "react-router";

import { closeFSPopUp, openFSPopUp } from '../../../actions/screen-activities';
import { toggleDataSession } from "../../../actions/datasession";

const YES = "YES";
const NO = "NO";

const initialState = {
  saveData: NO
}
class StopDatasession extends Component {
  state = initialState;

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  }

  stopSession = () => {
    if (this.state.saveData === YES) {
      if (this.props.timerId) {
        clearInterval(this.props.timerId);
      }
      const matchGroup = matchPath(window.location.pathname, {
        path: "/group/:id/active-session",
      });
      if (matchGroup) {
        this.props.toggleDataSession(matchGroup.params.id);
      }
      return this.props.closeFSPopUp();
    }

    this.props.openFSPopUp("STOP_DATASESSION_WITHOUT_DATA");
  }

  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-end px-3 pb-3">
          <div className="container bg-light modal-body-container-scrollable">
            <div className="modal-body-container-internal">
              <div className="row mt-3 mb-2">
                <div className="col-12">
                  <div className="row ms-3 mt-3">
                    <b className="ps-0">Group name</b>
                  </div>
                  <div className="row ms-3 mt-3">
                    {this.props.datasessionMeta?.groupTitle}
                  </div>
                  <div className="row ms-3 mt-3">
                    <b className="ps-0">Group size</b>
                  </div>
                  <div className="row ms-3 mt-3">
                    {this.props.datasessionMeta?.participantCount} persons
                  </div>
                  <div className="row ms-3 mt-3">
                    <b className="ps-0">Started at</b>
                  </div>
                  <div className="row ms-3 mt-3">
                    {this.props.datasessionMeta?.startedAt}
                  </div>

                  <div className="row ms-3 mt-3">
                    <b className="ps-0">Store Session data</b>
                  </div>
                  <div className="row ms-3 mt-3">
                    <div className="form-check">
                      <input 
                        className="form-check-input" 
                        type="radio" 
                        name="saveData" 
                        value={NO} 
                        id="saveDataNo" 
                        onChange={(e) => this.handleChange(e)}
                        checked={this.state.saveData === NO}
                      />
                      <label className="form-check-label" htmlFor="saveDataNo">
                        No
                      </label>
                    </div>
                    <div className="form-check">
                      <input 
                        className="form-check-input" 
                        type="radio" 
                        name="saveData" 
                        value={YES} 
                        id="saveDataYes" 
                        onChange={(e) => this.handleChange(e)}
                        checked={this.state.saveData === YES}
                      />
                      <label className="form-check-label" htmlFor="saveDataYes">
                        Yes
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-end mb-3 me-3">
          <button className="btn btn-light me-3" onClick={this.props.closeFSPopUp}>
            CANCEL
          </button>
          <button className="btn btn-armor-red me-3" 
            onClick={
              () => {
                this.stopSession();
              }
            }
          >
            <FontAwesomeIcon icon={faStop} />{' '}
            STOP SESSION
          </button>
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    datasessionId: state.screenActivitiesReducer?.id,
    datasessionMeta: state.screenActivitiesReducer?.metaObj,
    timerId: state.screenActivitiesReducer?.timerId,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    closeFSPopUp,
    toggleDataSession,
    openFSPopUp
  })(StopDatasession)
);
