const initialState = {
  allLocations: null,
};

export default function deviceReducer(state = initialState, action) {
  switch (action.type) {
    case "ALL_LOCATIONS_FETCHED": {
      return {
        ...state,
        allLocations: [...action.data],
      };
    }
    default: {
      return state;
    }
  }
}
