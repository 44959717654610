const initialState = {
  allGroups: null,
  oneGroup: null,
  groups: null,
};

export default function groupReducer(state = initialState, action) {
  switch (action.type) {
    case "ALL_GROUPS_RECEIVED": {
      return {
        ...state,
        allGroups: [...action.data],
      };
    }
    case "GROUPS_RECEIVED": {
      return {
        ...state,
        groups: [...action.data],
      };
    }
    case "ONE_GROUP_RECEIVED": {
      return {
        ...state,
        oneGroup: action.data,
      };
    }
    case "NEW_GROUP": {
      return {
        ...state,
        allGroups: [{ ...action.data }, ...state.allGroups],
      };
    }
    case "EDIT_GROUP": {
      return {
        ...state,
        allGroups: state.allGroups.map((group) => {
          if (+group.id === +action.data.groupId) {
            return {
              ...group,
              title: action.data.groupName,
            };
          }
          return group;
        }),
        oneGroup: {
          ...state.oneGroup,
          title: action.data.groupName,
        },
      };
    }
    case "REMOVE_GROUP": {
      return {
        ...state,
        allGroups: state.allGroups.filter(
          (group) => group.id !== action.groupId
        ),
      };
    }
    case "ARCHIVE_GROUP": {
      //get the clicked id and remove from the list
      const remainingGroups = state.allGroups.filter(
        (group) => group.id !== action.data.groupId
      );
      return {
        ...state,
        allGroups: [...remainingGroups],
      };
    }
    case "DELETE_PARTICIPANT": {
      return {
        ...state,
        oneGroup: {
          ...state.oneGroup,
          participants: state.oneGroup.participants.filter(
            (participant) => +participant.id !== +action.participantId
          ),
        },
      };
    }
    case "ADD_PARTICIPANT": {
      return {
        ...state,
        oneGroup: {
          ...state.oneGroup,
          participants: [{ ...action.data }, ...state.oneGroup.participants],
        },
      };
    }
    case "DATASESSION_STOPPED": {
      return {
        ...state,
        allGroups: state.allGroups.map((group) => {
          if (+group.id === +action.data.datasession.groupId) {
            return {
              ...group,
              datasessions: group.datasessions.map((datasession) => {
                if (datasession.stoppedAt) {
                  return datasession;
                }
                return {
                  ...datasession,
                  stoppedAt: action.data.datasession.stoppedAt,
                };
              }),
            };
          }
          return group;
        }),
      };
    }
    case "EDIT_PARTICIPANT": {
      return {
        ...state,
        oneGroup: {
          ...state.oneGroup,
          participants: [
            ...state.oneGroup.participants.map((participant) => {
              if (+action.data.id === +participant.id) {
                return {
                  ...action.data,
                };
              }
              return participant;
            }),
          ],
        },
      };
    }
    default: {
      return state;
    }
  }
}
