import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";

import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import LoaderRoller from "../../components/loader/LoaderRoller";

import {
  fetchOneDeviceData,
  clearOneDeviceData,
} from "../../actions/datasession";
import { setPollingTimer } from "../../actions/screen-activities";
import CONFIG from "../../config";
import SimpleMap from "./SimpleMap";

import globalFunctions from "../../helpers/globalFunctions";
const { averageFromArr } = globalFunctions;

const { datatypes } = CONFIG;

const LIVE_MODE = "LIVE_MODE";

const {
  GNSS_ARR_DATATYPE,
  HEARTRATE_DATATYPE,
  CORE_TEMPERATURE_DATATYPE,
  PSI_DATATYPE,
} = datatypes;

const initialState = {
  zoomLevel: 1,
  chartMode: LIVE_MODE,
  measurementAlertPopUp: false,
};

const minutes5inMs = 300000;

class MapParticipant extends Component {
  state = initialState;
  lineReference = null;

  componentDidMount = () => {
    const { deviceId, datasessionId } = this.props.match.params;
    this.fetchData({ deviceId, datasessionId });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.oneDeviceData?.data &&
      this.props.oneDeviceData.data.length
    ) {
      // Show alert, if HR data is 1
      const hrDataLast = [...this.props.oneDeviceData.data]
        .reverse()
        .find((datapoint) => datapoint.datatype === HEARTRATE_DATATYPE);

      if (+hrDataLast.value === 1 && !this.state.measurementAlertPopUp) {
        this.setState({
          ...this.state,
          measurementAlertPopUp: true,
        });
        this.clearPollingTimer();
      }
    }

    if (this.state.chartMode !== prevState.chartMode) {
      if (this.state.chartMode === LIVE_MODE) {
        // Resume Polling with Zoom & Pan
        const { deviceId, datasessionId } = this.props.match.params;
        this.fetchData({ deviceId, datasessionId });
      } else {
        // Cancel Polling
        this.clearPollingTimer();
      }
    }
  };

  componentWillUnmount = () => {
    this.props.clearOneDeviceData();
    this.clearPollingTimer();
  };

  fetchData = ({ deviceId, datasessionId }) => {
    this.props.fetchOneDeviceData({ deviceId, datasessionId });

    // this.timer = setInterval(
    //   () => this.props.fetchOneDeviceData({ deviceId, datasessionId }),
    // POLLING_INTERVAL);

    // if (this.timer) {
    //   this.props.setPollingTimer(this.timer);
    // }
  };

  clearPollingTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  };

  render() {
    if (this.props.oneDeviceData && this.props.oneDeviceData.data) {
      let deviceData = [];
      let noDataInLast5Minutes = false;
      if (!this.props.stopped) {
        deviceData = this.props.oneDeviceData.data.slice(-60);
      } else {
        deviceData = this.props.oneDeviceData.data;
      }

      const uniqueData = deviceData.filter(
        (data) => +data.datatype === HEARTRATE_DATATYPE
      );
      const locationData = deviceData.filter(
        (data) => +data.datatype === GNSS_ARR_DATATYPE
      );

      if (locationData.length < 1) {
        return (
          <Fragment>
            <div className="row">
              <div className="armor-card col-12">
                <div className="row">
                  <div className="col-12">
                    <div className="row mt-3">
                      <div className="col-12 col-xl-10 col-xxl-8">
                        <h1>No location data have been received yet.</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        );
      }
      const latArr = locationData.map((location) => location.arrayValue[0]);
      const lonArr = locationData.map((location) => location.arrayValue[1]);
      const latCenter = averageFromArr(latArr);
      const lonCenter = averageFromArr(lonArr);

      const coreTempData = deviceData.filter(
        (data) => +data.datatype === CORE_TEMPERATURE_DATATYPE
      );
      const psiData = deviceData.filter(
        (data) => +data.datatype === PSI_DATATYPE
      );

      const timestamps = uniqueData.map((data) => {
        return moment(+data.timestamp).format("HH:mm");
      });

      if (
        timestamps &&
        timestamps.length &&
        this.state.chartMode === LIVE_MODE
      ) {
        noDataInLast5Minutes =
          Date.now() - +uniqueData[uniqueData.length - 1]?.timestamp >
          minutes5inMs;
      }
      console.log("data", coreTempData, psiData);
      return (
        <Fragment>
          <div className="row">
            <div className="armor-card col-12">
              <div className="row">
                <div className="col-12">
                  {this.state.measurementAlertPopUp ? (
                    <div className="row bg-armor-red p-3">
                      <div className="col-1 my-2">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                      </div>
                      <div className="col-10 my-2">
                        <b>Invalid data.</b>{" "}
                        <i>
                          HR data is invalid. “Measurement ended for this
                          participant.”
                        </i>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {noDataInLast5Minutes ? (
                    <div className="row-12 d-flex align-items-center bg-armor-red p-3 alig">
                      <div className="col-1">
                        <FontAwesomeIcon icon={faExclamationTriangle} />
                      </div>
                      <div className="col-10">
                        <b>
                          No data has been received for more than 5 minutes.
                        </b>{" "}
                        <i>
                          Values may be inaccurate within 20 minutes after gap
                          in measurement.
                        </i>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="row mt-3">
                    <div className="col-12 col-xl-10 col-xxl-8">
                      {/* <iframe 
                        width="100%"
                        height="100%"
                        frameBorder={0} 
                        style={{
                          border: 0,
                          minHeight: 600
                        }}
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        src={
                          `https://www.google.com/maps/embed/v1/directions?origin=${
                            locationData[0].arrayValue.join(',')
                          }&destination=${locationData[locationData.length - 1].arrayValue.join(',')}${
                            locationData.length > 3 ? (
                              `&waypoints=${
                                locationData
                                  .filter((coordinates, i) => (i !== 0 && i !== (locationData.length - 1)))  
                                  .map((coordinates) => coordinates.arrayValue.join(',')).join('|')
                              }`
                            ) : (
                              ""
                            )
                          }&center=${latCenter},${lonCenter}&zoom=14&mode=walking&key=AIzaSyCiGaFhxJVDKG8tAzS3GWcMV0olCqERavU`
                        }
                        allowFullScreen
                      /> */}
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-12">
                      <SimpleMap
                        center={{ lat: latCenter, lng: lonCenter }}
                        locationData={locationData}
                        coreTempData={coreTempData}
                        psiData={psiData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return <LoaderRoller />;
    }
  }
}

function mapStateToProps(state) {
  return {
    oneDeviceData: state.datasessionReducer.oneDeviceData,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchOneDeviceData,
    clearOneDeviceData,
    setPollingTimer,
  })(MapParticipant)
);
