import axios from "axios";

import CONFIG from "../config";

const {
  armorServerUrl
} = CONFIG;

const ALL_DEVICES_FETCHED = "ALL_DEVICES_FETCHED";
const UNUSED_DEVICES_FETCHED = "UNUSED_DEVICES_FETCHED";

const getAllDevicesSuccess = (data) => ({
  type: ALL_DEVICES_FETCHED,
  data
});

const getUnusedDevicesSuccess = (data) => ({
  type: UNUSED_DEVICES_FETCHED,
  data
});

export const getAllDevices = () => (dispatch, getState) => {
  const { token } = getState().userReducer;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .get(`${armorServerUrl}/device/index`)
    .then((res) => {
        dispatch(getAllDevicesSuccess(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getUnusedDevices = (groupId) => (dispatch, getState) => {
  const { token } = getState().userReducer;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .get(`${armorServerUrl}/device/index${groupId ? (`?free=true&groupId=${groupId}`) : ('')}`)
    .then((res) => {
        dispatch(getUnusedDevicesSuccess(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
};