import axios from "axios";

import CONFIG from "../config";

const ALL_GROUPS_RECEIVED = "ALL_GROUPS_RECEIVED";
const ONE_GROUP_RECEIVED = "ONE_GROUP_RECEIVED";
const NEW_GROUP = "NEW_GROUP";
const EDIT_GROUP = "EDIT_GROUP";
const REMOVE_GROUP = "REMOVE_GROUP";
const ARCHIVE_GROUP = "ARCHIVE_GROUP";
const GROUPS_RECEIVED = "GROUPS_RECEIVED";
const { armorServerUrl } = CONFIG;

const fetchAllGroupsSuccess = (data) => ({
  type: ALL_GROUPS_RECEIVED,
  data,
});

const fetchGroupsSuccess = (data) => ({
  type: GROUPS_RECEIVED,
  data,
});

const fetchOneGroupSuccess = (data) => ({
  type: ONE_GROUP_RECEIVED,
  data,
});

const addGroupSuccess = (data) => ({
  type: NEW_GROUP,
  data,
});

const editGroupSuccess = (data) => ({
  type: EDIT_GROUP,
  data,
});

const removeGroupSuccess = (groupId) => ({
  type: REMOVE_GROUP,
  groupId,
});

const archiveGroupSuccess = (groupId, archivedAt) => ({
  type: ARCHIVE_GROUP,
  data: { groupId, archivedAt },
});

//get only data where archivedAt is not null
export const fetchAllGroups = () => (dispatch, getState) => {
  const { token } = getState().userReducer;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .get(`${armorServerUrl}/group/index`)
    .then((res) => {
      dispatch(fetchAllGroupsSuccess(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

//get all data from groups
export const fetchGroups = () => (dispatch, getState) => {
  const { token } = getState().userReducer;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .get(`${armorServerUrl}/group/groupList`)
    .then((res) => {
      dispatch(fetchGroupsSuccess(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchOneGroup = (groupId) => (dispatch, getState) => {
  const { token } = getState().userReducer;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .get(`${armorServerUrl}/group/${groupId}`)
    .then((res) => {
      dispatch(fetchOneGroupSuccess(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addGroup = (groupData) => (dispatch, getState) => {
  const { token } = getState().userReducer;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .post(`${armorServerUrl}/group`, {
      title: groupData,
    })
    .then((res) => {
      dispatch(addGroupSuccess(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const editGroup = (groupData) => (dispatch, getState) => {
  const { token } = getState().userReducer;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .put(`${armorServerUrl}/group/${groupData.groupId}`, {
      title: groupData.groupName,
    })
    .then((res) => {
      if (+res.status === 200) {
        dispatch(editGroupSuccess(groupData));
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const removeGroup = (groupId) => (dispatch, getState) => {
  const { token } = getState().userReducer;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .delete(`${armorServerUrl}/group/${groupId}`)
    .then((res) => {
      if (+res.status === 200) {
        dispatch(removeGroupSuccess(groupId));
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const archiveGroup =
  (groupId, archivedGroup) => (dispatch, getState) => {
    const { token } = getState().userReducer;

    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios
      .patch(`${armorServerUrl}/group/archive/${groupId}`, {
        archivedAt: archivedGroup,
      })
      .then((res) => {
        if (+res.status === 200) {
          dispatch(archiveGroupSuccess(groupId, archivedGroup));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
