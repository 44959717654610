import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { closeFSPopUp, startDownload } from '../../../actions/screen-activities';
 
const CSV = "CSV";
const JSON = "JSON";

const initialState = {
  type: CSV,
  dropdownOpened: false
};

class SaveDatasession extends Component {
  state = initialState;

  toggleDropdown = (type) => {
    this.setState({
      ...this.state,
      dropdownOpened: !this.state.dropdownOpened,
      type: type ? type : this.state.type
    });
  }

  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-end px-3 pb-3">
          <div className="container bg-light modal-body-container-scrollable">
            <div className="modal-body-container-internal">
              <div className="row mt-3">
                <div className="col-12">
                  <div className="row ms-3 mt-3">
                    <b className="ps-0">Date range</b>
                  </div>
                  <div className="row ms-3 mt-3">
                    {this.props.datasessionMeta?.startedAt} to {this.props.datasessionMeta?.stoppedAt}
                  </div>
                  <div className="row ms-3 mt-3">
                    <b className="ps-0">Group name</b>
                  </div>
                  <div className="row ms-3 mt-3">
                    {this.props.datasessionMeta?.groupTitle}
                  </div>
                  <div className="row ms-3 mt-3">
                    <b className="ps-0">Group size</b>
                  </div>
                  <div className="row ms-3 mt-3">
                    {this.props.datasessionMeta?.participantCount} persons
                  </div>
                  <div className="row ms-3 mt-3">
                    <b className="ps-0">Format</b>
                  </div>
                  <div className="row ms-0 my-3">
                    <div className="dropdown" onClick={() => {
                      if (this.state.dropdownOpened) return;
                      this.toggleDropdown();
                    }}>
                      <button className="btn btn-secondary dropdown-toggle" type="button">
                        {this.state.type}
                      </button>
                      <div className={`dropdown-menu ${(this.state.dropdownOpened) ? ("d-block") : ("")}`}>
                        <button className="dropdown-item"
                          onClick={() => {
                            this.toggleDropdown(JSON)
                          }}
                        >
                          .JSON
                        </button>
                        <button className="dropdown-item"
                          onClick={() => {
                            this.toggleDropdown(CSV)
                          }}
                        >
                          .CSV
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-end mb-3 me-3">
          <button className="btn btn-light me-3" onClick={this.props.closeFSPopUp}>
            CANCEL
          </button>
          <button className="btn btn-armor-red me-3" 
            onClick={
              () => {
                this.props.startDownload(this.state.type, this.props.datasessionMeta?.datasessionId);
                this.props.closeFSPopUp();
              }
            }
          >
            <FontAwesomeIcon icon={faArrowDown} />{' '}
            EXPORT
          </button>
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    datasessionId: state.screenActivitiesReducer?.id,
    datasessionMeta: state.screenActivitiesReducer?.metaObj
  };
}

export default withRouter(
  connect(mapStateToProps, {
    closeFSPopUp,
    startDownload
  })(SaveDatasession)
);
