import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";

import armorLogo from "../../images/logo/armor_logo.png";

import "./style.css";

import CONFIG from '../../config';
const { 
  loginUrl, 
  clientId, 
  clientSecret, 
  passResetUrl 
} = CONFIG;

class Index extends Component {
  render() {
    return (
      <Fragment>
        <div className="row">
          <div 
            className="col-12 text-center" 
            style={{
              height: "100%",
              marginTop: "20vh",
            }}
          >
            <div className="row justify-content-center">
              <div className="col-3"></div>
              <div className="col-6">
                <img 
                  src={armorLogo} 
                  className="img-fluid logo-height-15" 
                  alt="Armor Logo"
                />
              </div>
              <div className="col-3"></div>
            </div>
            <a 
              rel="noopener noreferrer"
              href={`${loginUrl}?ClientId=${clientId}&ClientSecret=${clientSecret}`}
            >
              <button className="btn btn-orange btn-login my-5 px-5">LOGIN</button>
            </a>
            <br />
            <a
              className="text-white"
              href={passResetUrl}
              rel="noopener noreferrer"
            >
              <b>Forgot / change password</b>
            </a>
          </div>
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default withRouter(
  connect(mapStateToProps, {

  })(Index)
);