import axios from 'axios';
import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";

import LoaderRoller from '../../components/loader/LoaderRoller';

import { getAllDevices } from "../../actions/device";
import { getAllLocations } from "../../actions/admin";
import { openFSPopUp } from "../../actions/screen-activities";

import DeviceLocationCard from '../../components/location-management/DeviceLocationCard';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';

import CONFIG from "../../config";
const { armorServerUrl } = CONFIG;

const initialState = {
  locationName: ""
};

class LocationManagement extends Component {
  state = initialState;

  componentDidMount = () => {
    this.props.getAllDevices();
    this.props.getAllLocations();
  }

  locationNameChanged = (e) => {
    this.setState({
      ...this.state,
      locationName: e.target.value
    })
  }

  clearLocationName = () => {
    this.setState({...initialState});
  }

  clearAllCache = () => {
    if (!window.confirm("Do you really want to clear Redis Cache?")) {
      console.log("Redis cache was not flushed.");
      return;
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${this.props.userToken?.token}`;
    axios
      .post(`${armorServerUrl}/cache`, {
        function: "flushRedisCache"
      })
      .then(res => {
        if (res?.data?.success) {
          console.log("Redis cache completely flushed.");
        }
      })
      .catch(err => console.log(err));
  }

  render() {
    if (this.props.allDevices && this.props.allLocations) {
      const filteredLocations = this.state.locationName !== "" ? (
        this.props.allLocations.filter(location => (location?.baceGroup?.label.toLowerCase().search(this.state.locationName.toLowerCase()) !== -1))
      ) : (
        this.props.allLocations
      );
      const filteredLocationIds = filteredLocations.map(location => (location?.userAcc?.id));

      const devicesToShow = this.state.locationName !== "" ? (
        this.props.allDevices.filter(device => (filteredLocationIds.includes(device?.userAccessId)))
      ) : (
        this.props.allDevices
      );

      return (
        <Fragment>
          <div className="p-3 p-md-3 p-lg-5">
            <div className="row pb-3">
              <div className="col-12 col-md-6">
                <h4>Clear Redis Cache</h4>
                <p>
                  By clicking this button backend will destroy all the data cached in Redis.
                  Make sure you know what you do, before you click it. 
                </p>
                <button 
                  className="btn btn-armor-red"
                  onClick={this.clearAllCache}
                >
                  Clear Cache
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <h4>Devices</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="input-group">
                  <input 
                    placeholder="Search by location..."
                    type="text" 
                    name="locationName" 
                    className="form-control"
                    onChange={this.locationNameChanged}
                    value={this.state.locationName}
                  />
                  <button 
                    className="input-group-text"
                    type="button"
                    title="Reset Search"
                    onClick={() => this.clearLocationName()}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </button>
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="d-none d-md-block col-6">
                <button 
                  className="btn btn-armor-red"
                  onClick={() => this.props.openFSPopUp("ADD_NEW_DEVICE")}
                >
                  Add device
                </button>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-12">
                <div className="row mt-1">
                  {
                    (devicesToShow.length > 0) ? (
                      devicesToShow.map(device => (
                        <DeviceLocationCard device={device} key={device.id} />
                      ))
                    ) : ('No devices found.')
                  }
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else {
      return (
        <LoaderRoller />
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    allDevices: state.deviceReducer.allDevices,
    allLocations: state.adminReducer.allLocations,
    userToken: state.userReducer.token
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getAllDevices,
    getAllLocations,
    openFSPopUp,
  })(LocationManagement)
);