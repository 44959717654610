import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { deleteDevice } from "../../actions/admin";
import { openFSPopUp } from "../../actions/screen-activities";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearchLocation,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";

const IN_ACTIVE_DATASESSION = "IN_ACTIVE_DATASESSION";
// const NOT_IN_ACTIVE_DATASESSION = "NOT_IN_ACTIVE_DATASESSION";

class DeviceLocationCard extends Component {
  render() {
    const location = this.props.allLocations
      .filter((location) => location.userAcc)
      .find((location) => {
        return location.userAcc.id === this.props.device.userAccessId;
      });
    return (
      <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2 mt-3">
        <div className="card h-100">
          <div className="card-body">
            <h5 className="card-title">{this.props.device.baceGoId}</h5>
            <p className="card-text">
              {location ? (
                location?.baceGroup?.label
              ) : (
                <span className="text-warning">Location is not assigned</span>
              )}
            </p>
            {this.props.device.status === IN_ACTIVE_DATASESSION ? (
              <p className="card-text text-success">
                Device is in Active Datasession
              </p>
            ) : (
              <Fragment>
                <button
                  className="btn btn-sm btn-warning"
                  type="button"
                  onClick={() =>
                    this.props.openFSPopUp(
                      "CHANGE_DEVICE_LOCATION",
                      this.props.device.id
                    )
                  }
                  title={`Change location for ${this.props.device.baceGoId}`}
                >
                  <FontAwesomeIcon icon={faSearchLocation} />
                </button>
                <button
                  className="btn btn-sm btn-danger ms-1"
                  type="button"
                  onClick={() => this.props.deleteDevice(this.props.device.id)}
                  title={`Delete ${this.props.device.baceGoId}`}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allLocations: state.adminReducer.allLocations,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    openFSPopUp,
    deleteDevice,
  })(DeviceLocationCard)
);
