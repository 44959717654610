import { combineReducers } from "redux";

import userReducer from "./user";
import redirectReducer from "./redirect";
import screenActivitiesReducer from "./screen-activities";
import groupReducer from "./group";
import deviceReducer from "./device";
import adminReducer from "./admin";
import datasessionReducer from "./datasession";
import alertReducer from "./alert"; 

export default combineReducers({
  userReducer,
  redirectReducer,
  screenActivitiesReducer,
  groupReducer,
  deviceReducer,
  adminReducer,
  datasessionReducer,
  alertReducer
});
