import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { fetchOneDatasession } from "../../actions/datasession";
import { fetchAllGroups } from "../../actions/group";

import LoaderRoller from '../../components/loader/LoaderRoller';

// import CONFIG from '../../config';
import HistoricalSessionLine from '../../components/session-line/HistoricalSessionLine';
// const { datatypes } = CONFIG;
// const {
//   CORE_TEMPERATURE_DATATYPE,
//   PSI_DATATYPE
// } = datatypes;


class HistoricalDatasessionListView extends Component {
  componentDidMount = () => {
    const { id } = this.props.match.params;
    if (!this.props.groupData || (this.props.groupData.length === 0)) {
      this.props.fetchAllGroups();
    }
    this.props.fetchOneDatasession(id);
  }

  render() {
    // console.log(this.props.groupData);
    if (
      this.props.selectedDatasession && this.props.selectedDatasession?.maxPsi 
      && this.props.userSettings?.assesmentPsiTemp && this.props.selectedDatasession?.maxTemp
    ) {
      let sortedDataIds = [];
      let sortedMetaToShow = [];
      if (this.props.userSettings.tileSortType === "COLOR") {
        // Sort by color
        if (this.props.userSettings.assesmentPsiTemp === "PSI") {
          sortedDataIds = this.props.selectedDatasession.maxPsi.sort((a, b) => {
            if (+b.value === +a.value) {
              // If same PSI, sort by CoreTemp
              const aTemp = this.props.selectedDatasession.maxTemp
                .find(data => (+a.deviceId === +data.deviceId));
              const bTemp = this.props.selectedDatasession.maxTemp
                .find(data => (+b.deviceId === +data.deviceId));
              return (+bTemp.value - +aTemp.value);
            }
            return (+b.value - +a.value);
          });
        } else {
          sortedDataIds = this.props.selectedDatasession.maxTemp.sort((a, b) => {
            if (+b.value === +a.value) {
              // If same PSI, sort by CoreTemp
              const aTemp = this.props.selectedDatasession.maxPsi
                .find(data => (+a.deviceId === +data.deviceId));
              const bTemp = this.props.selectedDatasession.maxPsi
                .find(data => (+b.deviceId === +data.deviceId));
              return (+bTemp.value - +aTemp.value);
            }
            return (+b.value - +a.value);
          });
        }
        // console.log("sortedDataIds:", sortedDataIds);
        let datasessionMeta = [...this.props.selectedDatasession?.datasession?.datasessionMeta];
        // console.log("datasessionMeta:", datasessionMeta);
        const sortedMeta = sortedDataIds.map(data => (
          datasessionMeta.find((meta) => (+meta.deviceId === +data.deviceId))
        ));
        // console.log("Sorted meta", sortedMeta);
        const sortedMetaIds = sortedMeta.map(meta => meta.id);
        const unsortedMeta = datasessionMeta.filter(meta => (sortedMetaIds.indexOf(meta.id) === -1));
        sortedMetaToShow = [...sortedMeta, ...unsortedMeta];
      } else {
        // Sort by name
        sortedMetaToShow = [...this.props.selectedDatasession?.datasession?.datasessionMeta]
          .sort((a, b) => {
            if (a.participantName > b.participantName) {
              return 1;
            }
            if (a.participantName < b.participantName) {
              return -1;
            }
            return 0;
          });
      }

      return (
        <Fragment>
          <div className="col-12 mt-3 mb-5">
            <div className="armor-card">
            <div className="table-responsive-lg">
              <table className="table">
              <thead>
                <tr>
                  <th scope="col">Status</th>
                  <th scope="col">Alert type</th>
                  <th scope="col">Signal</th>
                  <th scope="col">Name/ID</th>
                  <th scope="col">Tcore</th>
                  <th scope="col">PSI</th>
                  <th scope="col">Heart Rate</th>
                  <th scope="col">View details</th>
                </tr>
              </thead>
              <tbody>
                {sortedMetaToShow.map((meta, i) => {
                  return (
                    <HistoricalSessionLine meta={meta} key={i} />
                  )
                })}
              </tbody>
              </table>
              {/* <Pagination /> */}
            </div>
            </div>
          </div>
        </Fragment>
      )
    } else {
      return (
        <LoaderRoller />
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    selectedDatasession: state.datasessionReducer?.histotricalDatasession,
    userSettings: state.userReducer.armorUser.settings,
    groupData: state.groupReducer?.allGroups,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchOneDatasession,
    fetchAllGroups
  })(HistoricalDatasessionListView)
);