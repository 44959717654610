import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";

// import GroupListRow from '../../components/group-overview/GroupListRow';
import LoaderRoller from '../../components/loader/LoaderRoller';

import { fetchOneGroup, fetchAllGroups } from "../../actions/group";
import { fetchActiveDatasession } from "../../actions/datasession";

import FullScreenModal from '../../components/modals/FullScreenModal';
import ParticipantListLine from '../../components/group-overview/ParticipantListLine';

class EditOneGroup extends Component {
  componentDidMount = () => {
    // this.props.fetchAllGroups();
    if (this.props?.match?.params?.id) {
      this.props.fetchOneGroup(+this.props?.match?.params?.id);
      this.props.fetchActiveDatasession(+this.props?.match?.params?.id, false);
    } else {
      this.props.history.push("/");
    }
  }

  componentDidUpdate = () => {
    if (this.props.selectedDatasession) {
      this.props.history.push(`/group/${+this.props?.match?.params?.id}/active-session`);
    }
  }

  render() {
    if (this.props.oneGroup) {
      return (
        <Fragment>
          <div className="col-12 mt-3 mb-5">
            <div className="armor-card">
            <div className="table-responsive-lg">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">All</th>
                    <th scope="col">Name</th>
                    <th scope="col">Initial Tcore</th>
                    <th scope="col">Device ID</th>
                    {/* <th scope="col">View device settings</th> */}
                    <th scope="col">Edit / Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.props.oneGroup.participants.length > 0 ? (
                      this.props.oneGroup?.participants.map(participant => (
                        <ParticipantListLine key={participant.id} participant={participant} />
                      ))
                    ) : ('')
                  }
                </tbody>
              </table>
              {/* <Pagination /> */}
            </div>
            </div>
          </div>
          {
            this.props.fullScreenPopUp ? (
              <FullScreenModal />
            ) : (
              <div></div>
            )
          }
        </Fragment>
      )
    } else {
      return (
        <LoaderRoller />
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    oneGroup: state.groupReducer?.oneGroup,
    selectedDatasession: state.datasessionReducer?.selectedDatasession,
    fullScreenPopUp: state.screenActivitiesReducer?.fullScreenPopUp,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchOneGroup,
    fetchAllGroups,
    fetchActiveDatasession
  })(EditOneGroup)
);

// return (
//   <Fragment>
//     <div className="row">
//       <div 
//         className="col-12 text-center" 
//         style={{
//           minHeight: "100%",
//         }}
//       >
//       </div>
//     </div>
    
//     <div className="col-12 col-md-8 col-lg-6 col-xl-4">
//       <div className="row mt-3 px-3">
//         <div className="col-4">
//           <b>Name</b>
//         </div>
//         <div className="col-3 text-center">
//           <b>
//             Initial °C
//           </b> 
//         </div>
//         <div className="col-4">
//           <b>
//             Sensor
//           </b>
//         </div>
//         <div className="col-1">

//         </div>
//       </div>
//       {
//         this.props.oneGroup.participants.length > 0 ? (
//           this.props.oneGroup?.participants.map(participant => (
//             <ParticipantListRow key={participant.id} participant={participant} />
//           ))
//         ) : ('')
//       }
//     </div>
//     {
//       this.props.fullScreenPopUp ? (
//         <FullScreenModal />
//       ) : (
//         <div></div>
//       )
//     }
//     <EditOneGroupSubMenu />
//   </Fragment>
// )