import axios from "axios";

import { newRedirect } from "./redirect";

import CONFIG from "../config";

const {
  baseUrl,
  serverUrl,
  armorServerUrl,
} = CONFIG;

const USER_AUTHENTICATED = "USER_AUTHENTICATED";
const ARMOR_USER_RECEIVED = "ARMOR_USER_RECEIVED";
const LOGOUT_USER = "LOGOUT_USER";

const userAuthSuccess = (user) => ({
  type: USER_AUTHENTICATED,
  user,
});

const getArmorUserSettngsSuccess = (armorUser) => ({
  type: ARMOR_USER_RECEIVED,
  armorUser,
});

const logoutUserSuccess = () => ({
  type: LOGOUT_USER,
});

export const refreshToken = (token) => (dispatch) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .post(
      `${serverUrl}/oauth/refresh-token`, {
        refresh_token: token.refresh
      }
    )
    .then((res) => {
      if (res.data?.refreshed) {
        dispatch(tryUserAuth({
          ...token,
          expires: res.data?.expires
        }));
      } else {
        console.log("Unknown error, refreshing token.");
      }
    })
    .catch((err) => {
      dispatch(tryUserAuth({
        ...token
      }));
      console.log(err);
    });
};

// Finish here
export const tryUserAuth = (token) => (dispatch) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .get(`${baseUrl}/user/self?include_permissions=1&include_roles=1`)
    .then((res) => {
      dispatch(
        userAuthSuccess({
          token,
          user: res.data?.user,
          roles: res.data?.roles,
          permissions: res.data?.permissions,
        })
      );
      dispatch(getArmorUserSettngs(token));
      if (window.location.pathname === "/authorization/credentials") {
        dispatch(newRedirect("/"));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(newRedirect("/"));
    });
};

export const getArmorUserSettngs = (token) => (dispatch) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .get(`${armorServerUrl}/user/settings`)
    .then((res) => {
      dispatch(
        getArmorUserSettngsSuccess(res.data)
      );
    })
    .catch((err) => {
      console.log(err);
    });
};

export const logoutUser = () => (dispatch, getState) => {
  const { token } = getState().userReducer;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;

  axios 
    .get(`${baseUrl}/user/logout`)
    .then(() => {
      dispatch(logoutUserSuccess());
      dispatch(newRedirect("/"));
    })
    .catch((err) => {
      console.log(err);
    })
};