const initialState = {
  status: null,
  message: ""
};

export default function alertReducer(state = initialState, action) {
  switch (action.type) {
    case "PRIMARY": {
      return {
        status: "PRIMARY",
        message: action.message
      };
    }
    case "WARNING": {
      return {
        status: "WARNING",
        message: action.message
      };
    }
    case "DANGER": {
      return {
        status: "DANGER",
        message: action.message
      };
    }
    case "CLOSE": {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
}
