import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import globalFunctions from '../../helpers/globalFunctions';
const { timestampToDateTime } = globalFunctions;

class ActiveSessionsCard extends Component {
  render() {
    const startTimestamp = this.props.group?.activeDatasession ? (
      this.props.group?.activeDatasession?.startedAt
    ) : (
      this.props.group?.latestDatasession?.startedAt
    );
    const startedAt = timestampToDateTime(startTimestamp);

    return (
      <Fragment>
        <Link 
          to={`/group/${+this.props.group?.id}/active-session`} 
          className="col-11 col-md-5 col-xl-4 col-xxl-3 m-1 m-md-3 pb-3 card-shadow"
        >
          <div className={`row card-top-border bg-armor-red`}></div>
          <div className="row px-3 pt-3">
            <div className="col-12">
              <div>Started at</div>
              <div><b>{startedAt}</b></div>
            </div>
          </div>
          <div className="row px-3 pt-3">
            <div className="col-12">
              <div>Group name</div>
              <h4><b>{this.props.group?.title}</b></h4>
            </div>
          </div>
          <div className="row px-3 pt-3">
            <div className="col-12">
              <div>Group size</div>
              <div><b>{this.props.group?.participants} persons</b></div>
            </div>
          </div>
        </Link>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    selectedDatasession: state.datasessionReducer?.selectedDatasession,
    userSettings: state.userReducer.armorUser.settings
  };
}

export default withRouter(
  connect(mapStateToProps, {
  })(ActiveSessionsCard)
);