import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';

import { faCircle, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CONFIG from '../../config';

const { colorPalette } = CONFIG;

class SessionLine extends Component {
  render() {
    const maxHr = this.props.selectedDatasession?.maxHr.find(data => (
      +data.deviceId === +this.props.meta.deviceId
    ));
    const maxPsi = this.props.selectedDatasession?.maxPsi.find(data => (
      +data.deviceId === +this.props.meta.deviceId
    ));
    const maxTemp = this.props.selectedDatasession?.maxTemp.find(data => (
      +data.deviceId === +this.props.meta.deviceId
    ));
    const warningReached = +maxPsi?.value > +this.props.userSettings.warningThreshold ? true : false;;
    const dangerReached = +maxPsi?.value > +this.props.userSettings.dangerThreshold ? true : false;;
    const tempWarningReached = +maxTemp?.value > +this.props.userSettings.tempWarningThreshold ? true : false;
    const tempDangerReached = +maxTemp?.value > +this.props.userSettings.tempDangerThreshold ? true : false;

    if (maxHr && maxPsi && maxTemp) {
      if (this.props.userSettings.assesmentPsiTemp === "PSI") {
        // PSI line
        return (
          <Fragment>
            <tr>
              <td>
                {
                  (dangerReached) ? (
                    <Fragment>
                      <FontAwesomeIcon icon={faCircle} color={colorPalette.cardRed} style={{opacity: "20%"}} />{' '}
                      Critical
                    </Fragment>
                  ) : (
                    warningReached ? (
                      <Fragment>
                        <FontAwesomeIcon icon={faCircle} color={colorPalette.cardOrange} style={{opacity: "20%"}} />{' '}
                        Warning
                      </Fragment>  
                    ) : (
                      <Fragment>
                        <FontAwesomeIcon icon={faCircle} color={colorPalette.cardGreen} style={{opacity: "20%"}} />{' '}
                        OK
                      </Fragment>
                    )
                  )
                }
              </td>
              <td>
                {
                  (dangerReached) ? (
                    "High temperature"
                  ) : (
                    warningReached ? (
                      "Medium temperature"
                    ) : (
                      "--"
                    )
                  )
                }
              </td>
              <td>
              </td>
              <td>{this.props.meta.participantName}</td>
              <td>{maxTemp?.value.toFixed(1)} °C</td>
              <td>{maxPsi?.value}</td>
              <td>{maxHr?.value.toFixed(0)} bpm</td>
              <td>
                <Link
                  className="text-armor-red"
                  to={{
                    pathname: `/history/${+this.props.meta.deviceId}/chart/${+this.props.meta.datasessionId}/stopped`,
                    state: {
                      fromRoute: window?.location?.pathname
                    }
                  }} 
                >
                  <FontAwesomeIcon icon={faEye} />
                </Link>
              </td>
            </tr>
          </Fragment>   
        )
      } else {
        // Temperature line
        return (
          <Fragment>
            <tr>
              <td>
                {
                  (tempDangerReached) ? (
                    <Fragment>
                      <FontAwesomeIcon icon={faCircle} color={colorPalette.cardRed} style={{opacity: "20%"}} />{' '}
                      Critical
                    </Fragment>
                  ) : (
                    tempWarningReached ? (
                      <Fragment>
                        <FontAwesomeIcon icon={faCircle} color={colorPalette.cardOrange} style={{opacity: "20%"}} />{' '}
                        Warning
                      </Fragment>  
                    ) : (
                      <Fragment>
                        <FontAwesomeIcon icon={faCircle} color={colorPalette.cardGreen} style={{opacity: "20%"}} />{' '}
                        OK
                      </Fragment>
                    )
                  )
                }
              </td>
              <td>
                {
                  (tempDangerReached) ? (
                    "High temperature"
                  ) : (
                    tempWarningReached ? (
                      "Medium temperature"
                    ) : (
                      "--"
                    )
                  )
                }
              </td>
              <td>
              </td>
              <td>{this.props.meta.participantName}</td>
              <td>{maxTemp?.value.toFixed(1)} °C</td>
              <td>{maxPsi?.value}</td>
              <td>{maxHr?.value.toFixed(0)} bpm</td>
              <td>
                <Link
                  className="text-armor-red"
                  to={{
                    pathname: `/history/${+this.props.meta.deviceId}/chart/${+this.props.meta.datasessionId}/stopped`,
                    state: {
                      fromRoute: window?.location?.pathname
                    }
                  }} 
                >
                  <FontAwesomeIcon icon={faEye} />
                </Link>
              </td>
            </tr>
          </Fragment>   
        )
      }
    } else {
      return (
        <Fragment>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>{this.props.meta.participantName}</td>
            <td>-- °C</td>
            <td>--</td>
            <td>-- bpm</td>
            <td>
              <FontAwesomeIcon icon={faEye} className="color-armor-red" />
            </td>
          </tr>
        </Fragment>                
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    selectedDatasession: state.datasessionReducer?.histotricalDatasession,
    userSettings: state.userReducer.armorUser.settings
  };
}

export default withRouter(
  connect(mapStateToProps, {
  })(SessionLine)
);