import { connect } from 'react-redux';
import { withRouter } from "react-router";

import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { closeMobileMenu, toggleMobileMenu } from "../../actions/screen-activities";

import armorLogo from "../../images/logo/armor_logo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faArrowDown, 
  faBars, 
  // faKey, 
  faRunning, 
  faTachometerAlt, 
  faUsersCog,
  faWindowClose
} from '@fortawesome/free-solid-svg-icons';
// import { MdOutlineRouter } from "react-icons/md";
// import { IoMdDownload } from 'react-icons/io';

const CHART_VIEW = "ACTIVE_DATASESSIONS";
const ACTIVE_DATASESSIONS = "ACTIVE_DATASESSIONS";
const GROUPS = "GROUPS";
const HISTORICAL_DATASESSIONS = "HISTORICAL_DATASESSIONS";
const SETTINGS = "SETTINGS";

class LeftMenu extends Component {

  getActiveMenuItem = () => {
    const pathname = this.props?.history?.location?.pathname;
    // console.log(pathname);
    if (!pathname) return;
    if ((pathname.includes('/chart/') || pathname.includes('/map/')) && !pathname.includes('/stopped')) {
      return CHART_VIEW;
    }
    if ((pathname.includes('/chart/') || pathname.includes('/map/')) && pathname.includes('/stopped')) {
      return HISTORICAL_DATASESSIONS;
    }
    if (
      pathname.startsWith("/active-sessions") || pathname.endsWith("/active-session") || 
      pathname.endsWith("/active-session/map") || pathname.endsWith("/active-session/list") || 
      pathname === "/"
    ) {
      return ACTIVE_DATASESSIONS;
    }
    if (
      pathname.startsWith("/groups") || 
      (pathname.startsWith("/group") && pathname.endsWith("/edit"))
    ) {
      return GROUPS;
    }
    if (
      pathname.startsWith("/historical-datasessions") || (
        (pathname.startsWith("/datasession") && (pathname.endsWith("/card") || pathname.endsWith("/list")))
      )
    ) {
      return HISTORICAL_DATASESSIONS;
    }
    if (pathname.startsWith("/settings")) {
      return SETTINGS;
    }
  }
  
  render() {
    return (
      <Fragment>
        <div className="position-fixed fixed-bottom mobile-menu-btn">
          <div className="d-block d-lg-none col-12 my-3 ps-3 m-lg-0">
            <button className="btn btn-armor-red" onClick={this.props.toggleMobileMenu}>
              {
                (this.props.mobileMenuOpened) ? (
                  <FontAwesomeIcon icon={faWindowClose} size="lg" />
                ) : (
                  <FontAwesomeIcon icon={faBars} size="lg" />
                )
              }
            </button>
          </div>
        </div>
        <div
          onClick={this.props.closeMobileMenu}
          className={`left ${
            this.props.mobileMenuOpened ? ("navbar-opened") : ("")
          }`}
        >
          <Link to="/active-sessions">
            <div className="mainMenuLogoWrapper">
              <img src={armorLogo} alt="ARMOR logo" className="mainMenuLogo img-fluid" />
            </div>
          </Link>
          <div className="mt-5"></div>
          <div className="d-block">
            <div className={`mt-1 ps-3 py-2 ${
              (this.getActiveMenuItem() === ACTIVE_DATASESSIONS) ? 
              ('border-left-active') : 
              ('border-left')
            }`}>
              <Link 
                className={`no-underline ${
                  (this.getActiveMenuItem() === ACTIVE_DATASESSIONS) ? 
                  ('menu-item-active') : 
                  ('text-white')}`
                } 
                to="/active-sessions"
              >
                <FontAwesomeIcon icon={faRunning} className="me-2" />
                Active Sessions
              </Link>  
            </div>
            <div className={`mt-1 ps-3 py-2 ${
              (this.getActiveMenuItem() === GROUPS) ? 
              ('border-left-active') : 
              ('border-left')
            }`}>
              <Link 
                className={`no-underline ${
                  (this.getActiveMenuItem() === GROUPS) ? 
                  ('menu-item-active') : 
                  ('text-white')}`
                }  
                to="/groups"
              >
                <FontAwesomeIcon icon={faUsersCog} className="me-2" />
                Groups
              </Link>  
            </div>
            <div className={`mt-1 ps-3 py-2 ${(this.getActiveMenuItem() === HISTORICAL_DATASESSIONS) ? ('border-left-active') : ('border-left')}`}>
              <Link 
                className={`${(this.getActiveMenuItem() === HISTORICAL_DATASESSIONS) ? ('menu-item-active') : ('text-white')} no-underline`} 
                to="/historical-datasessions/1"
              >
                <FontAwesomeIcon icon={faArrowDown} className="me-2" />
                {/* <IoMdDownload className="me-2" /> */}
                Stored Sessions
              </Link>
            </div>
            <div className={`mt-1 ps-3 py-2 ${(this.getActiveMenuItem() === SETTINGS) ? ('border-left-active') : ('border-left')}`}>
              <Link className={`${(this.getActiveMenuItem() === SETTINGS) ? ('menu-item-active') : ('text-white')} no-underline`} to="/settings">
                <FontAwesomeIcon icon={faTachometerAlt} className="me-2" />
                Settings
              </Link>
            </div>
            {/* <div className={`mt-1 ps-3 py-2 border-left`}>
              <Link className="text-white no-underline" to="/configuration">
                <MdOutlineRouter className="me-2" />
                Configuration
              </Link>
            </div> */}
            {/* <div className={`mt-1 ps-3 py-2 border-left`}>
              <Link className="text-white no-underline" to="/accounts">
                <FontAwesomeIcon icon={faKey} className="me-2" />
                Accounts
              </Link>
            </div> */}
          </div>
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    mobileMenuOpened: state.screenActivitiesReducer.mobileMenuOpened,
  };
}

export default withRouter(connect(mapStateToProps, { closeMobileMenu, toggleMobileMenu })(LeftMenu));