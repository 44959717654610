import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";

import { Line, Chart } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import zoomPlugin from 'chartjs-plugin-zoom';

import LoaderRoller from '../../components/loader/LoaderRoller';

import { fetchOneDeviceData, clearOneDeviceData } from "../../actions/datasession";

import CONFIG from '../../config';
const { datatypes, colorPalette } = CONFIG;

const ZOOM_MODE = "ZOOM_MODE";

const {
  HEARTRATE_DATATYPE,
  CORE_TEMPERATURE_DATATYPE,
  PSI_DATATYPE
} = datatypes;

const initialState = {
  zoomLevel: 1,
  chartMode: ZOOM_MODE,
};


class HistoryChartStopped extends Component {
  state = initialState;
  lineReference = null;

  componentDidMount = () => {
    Chart.register([ChartDataLabels, zoomPlugin]);
    const { deviceId, datasessionId } = this.props.match.params;
    this.fetchData({ deviceId, datasessionId });
  }

  componentWillUnmount = () => {
    this.props.clearOneDeviceData();
  }

  fetchData = ({ deviceId, datasessionId }) => {
    this.props.fetchOneDeviceData({ deviceId, datasessionId });
  }

  render() {
    if (this.props.oneDeviceData) {
      let deviceData = [];
      deviceData = this.props.oneDeviceData.data;

      const uniqueData = deviceData
        .filter(data => (+data.datatype === HEARTRATE_DATATYPE));
      const coreTempData = deviceData
        .filter(data => (+data.datatype === CORE_TEMPERATURE_DATATYPE))
        .map(data => ( ((+data.value - 36) * 2) + 5 ));
      const heartrateData = deviceData
        .filter(data => (+data.datatype === HEARTRATE_DATATYPE))
        .map(data => ( (+data.value / 22) + 10 ));
      const psiData = deviceData
        .filter(data => (+data.datatype === PSI_DATATYPE))
        .map(data => (+data.value.toFixed(1)));

      const timestamps = uniqueData.map(data => {
        return moment(+data.timestamp).format("HH:mm");
      });


      const data = {
        labels: timestamps,
        datasets: [
          {
            label: "Heart Rate (bpm)",
            data: heartrateData,
            fill: false,
            borderColor: colorPalette?.chartRed,
            tension: 0.1,
            pointRadius: 1
          },
          {
            label: "TCore (°C)",
            data: coreTempData,
            fill: false,
            borderColor: colorPalette?.chartGreen,
            tension: 0.1,
            pointRadius: 1
          },
          {
            label: "PSI",
            data: psiData,
            fill: false,
            borderColor: colorPalette?.chartOrange,
            tension: 0.1,
            pointRadius: 1
          }
        ]
      };

      const options = {
        normalized: true,
        plugins: {
          legend: {
            position: 'bottom',
            align: 'start',
            labels: {
              boxHeight: 0
            }
          },
          tooltip: {
            enabled: true,
            intersect: false,
            interaction: {
              mode: 'index'
            },
            callbacks: {
              label: function(context) {
                let value = context?.raw;
                if (context?.dataset?.label === "Heart Rate (bpm)") {
                  value = ((value - 10) * 22).toFixed(0);
                } else if (context?.dataset?.label === "TCore (°C)") {
                  value = (((value - 5) / 2) + 36).toFixed(1);
                } else {
                  value = value.toFixed(0);
                }
                return value;
              }
            }
          },

          datalabels: {
            align: 'top',
            formatter: function(value, context) {
              if (context.datasetIndex === 0) {
                // Heartrate
                return ((value - 10) * 22).toFixed(0);
              } else if (context.datasetIndex === 1) {
                // Core temperature
                return (((value - 5) / 2) + 36).toFixed(1);
              } else if (context.datasetIndex === 2) {
                // PSI
                return value.toFixed(0);
              }
              return value;
            }
          },
          zoom: {
            pan: {
              enabled: true,
              mode: 'x',
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true
              },
              mode: 'x',
              // onZoomComplete({chart}) {
              //   console.log("zoomed in");
              //   chart.update('none');
              // }
            },
          }
        },
        scales: {
          yAxes: {
            ticks: {
              display: false,
            }
          },
          xAxes: {
            title: {
              display: true,
              align: 'end',
              text: 'Time',
              font: {
                weight: 'bolder',
                size: 16 
              }
            }
          }
        }
      };

      return (
        <Fragment>
          <div className="row">
            <div className="armor-card col-12">
              <div className="row">
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 col-xl-10 col-xxl-8">
                      <div className="row">
                        <div className="col-6 d-flex align-items-center">
                          <h4>
                            <b>
                              {this.props.oneDeviceData?.datasessionMeta[0]?.participantName}
                            </b>
                          </h4>
                        </div>
                      </div>
                      <Line 
                        id="chartJs"
                        options={options}
                        data={data}
                        ref={(reference) => this.lineReference = reference}
                      />
                    </div>
                  </div>
               </div>
              </div>
            </div>
          </div>
        </Fragment>
      )
    } else {
      return (
        <LoaderRoller />
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    oneDeviceData: state.datasessionReducer.oneDeviceData
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchOneDeviceData,
    clearOneDeviceData,
  })(HistoryChartStopped)
);