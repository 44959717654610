import React, { Component, Fragment } from 'react';

import { connect } from "react-redux";
import { withRouter, matchPath } from "react-router";

import FullScreenModal from '../modals/FullScreenModal';

import { logoutUser } from "../../actions/user";
import { saveUserSettings } from "../../actions/settings";
import { openFSPopUp } from '../../actions/screen-activities';

import sortColor from "../../images/icons/ic_sort_color.png";
import sortName from "../../images/icons/ic_sort_alpha.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faCog, faPen } from '@fortawesome/free-solid-svg-icons';

const initialState = {
  rightBtnIcon: null,
  rightBtnPng: null,
};
class MainMenu extends Component {
  state = initialState;
  componentDidMount = () => {
    this.rightBtnIcon();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props !== prevProps) {
      this.rightBtnIcon();
    }
  }

  leftBtnTitle = () => {
    const pathname = window?.location?.pathname;
    if (pathname.indexOf('/chart/') > -1) {
      return 'Live Session';
    }
    if (pathname === '/') {
      return 'Log Out';
    }
    return 'Group Overview';
  }

  leftBtnClickEvent = () => {
    const pathname = window?.location?.pathname;
    if (pathname.indexOf('/chart/') > -1) {
      return this.props.history.goBack();
    }
    if (pathname === '/') {
      return this.props.openFSPopUp("LOG_OUT");
    }
    if (pathname.endsWith("/edit")) {
      return this.props.history.push('/');
    }
    if (pathname.endsWith("/active-session") || pathname.endsWith("/historical-datasessions")) {
      return this.props.history.push('/');
    }
    return this.props.history.goBack();
  }

  rightBtnIcon = () => {
    const pathname = window?.location?.pathname;
    if (pathname.indexOf('/chart/') > -1 || pathname.endsWith('historical-datasessions')) {
      this.setState({
        rightBtnPng: null,
        rightBtnIcon: null
      });
      return;
    }
    if (pathname === '/') {
      this.setState({
        rightBtnPng: null,
        rightBtnIcon: faCog
      });
      return;
    }
    if (pathname.endsWith("/active-session")) {
      this.setState({
        rightBtnIcon: null,
        rightBtnPng: this.props?.armorUser?.settings?.tileSortType === "COLOR" ? sortColor : sortName
      });
      return;
    }
    this.setState({
      rightBtnIcon: faPen,
      rightBtnPng: null
    });
    return;
  }

  rightBtnClickEvent = () => {
    const pathname = window?.location?.pathname;
    if (pathname === '/') {
      return this.props.openFSPopUp("INITIAL_CONFIGURATION");
    }
    if (pathname.endsWith("/edit")) {
      return this.props.openFSPopUp("EDIT_GROUP");
    }
    if (pathname.endsWith("/active-session")) {
      return this.props.saveUserSettings({
        tileSortType: this.props?.armorUser?.settings?.tileSortType === "COLOR" ? "NAME" : "COLOR"
      });
    }
    return this.props.history.goBack();
  }

  getHeader = () => {
    const pathname = window?.location?.pathname;
    if (pathname.endsWith("/historical-datasessions")) {
      return "Historical datasessions";
    }
    if (pathname.indexOf('/chart/') > -1) {
      if (this.props.participantMeta) {
        return `${this.props.participantMeta.participantName}`;
      }
      return "Chart data";

    }
    const matchGroupEdit = matchPath(window.location.pathname, {
      path: "/group/:id/edit",
    });

    const matchActiveSession = matchPath(window.location.pathname, {
      path: "/group/:id/active-session",
    });

    if (matchGroupEdit && (this.props.groupData || this.props.oneGroup)) {
      if (this.props.oneGroup) {
        return this.props.oneGroup.title;
      }
      return this.props.groupData
        .find(group => (group.id === matchGroupEdit.params.id))
        ?.name;
    }

    if (matchActiveSession) {
      return this.props.groupData ? (
        this.props.groupData
          .find(group => (+group.id === +matchActiveSession.params.id))
          ?.title
      ) : ('Active Datasession')
    }
    return "Group Overview";
  }

  render() {
    // console.log(this.props.armorUser);
    if (this.props.user) {
      // Admin Header
      if (this.props.isAdmin) {
        return (
          <Fragment>
            <nav className="navbar navbar-expand navbar-dark bg-armor-red fixed-top">
              <div className="container-fluid">
                <button 
                  className="btn text-white px-3"
                  title="Log Out"
                  onClick={() => this.props.openFSPopUp("LOG_OUT")}
                >
                  <FontAwesomeIcon 
                    icon={faAngleLeft} 
                    className="fa-2x" 
                  />
                </button>
                <h2>Device & Location Management</h2>
                <div></div>
              </div>
            </nav>
            {
              this.props.fullScreenPopUp ? (
                <FullScreenModal />
              ) : (
                <div></div>
              )
            }
          </Fragment>
        );
      }

      const header = this.getHeader();
      return (
        <Fragment>
          <nav className="navbar navbar-expand navbar-dark bg-dark-green fixed-top">
            <div className="container-fluid">
              <button 
                className="btn text-white px-3"
                title={this.leftBtnTitle()}
                onClick={this.leftBtnClickEvent}
              >
                <FontAwesomeIcon 
                  icon={faAngleLeft} 
                  className="fa-2x" 
                />
              </button>

              <h2>{header}</h2>
              
              {
                this.state.rightBtnIcon ? (
                  <button 
                    className="btn text-white px-3"
                    title="Settings"
                    type="button"
                    onClick={this.rightBtnClickEvent}
                  >
                    <FontAwesomeIcon 
                      icon={this.state.rightBtnIcon}
                      className="fa-2x" 
                    />
                  </button>
                ) : (
                  this.state.rightBtnPng ? (
                    <button 
                      className="btn bg-light px-3"
                      title="Sort By"
                      type="button"
                      onClick={this.rightBtnClickEvent}
                    >
                      <img 
                        src={this.state.rightBtnPng} 
                        alt="Sort by Color" 
                        className="main-menu-icon-png" 
                        title="Sort by Color" 
                      />
                    </button>
                  ) : (
                    <div></div>
                  )
                )
              }
            </div>
          </nav>
          {
            this.props.fullScreenPopUp ? (
              <FullScreenModal />
            ) : (
              <div></div>
            )
          }
        </Fragment>
      )
    } else {
      return (
        <div className="row">
          <nav className="navbar navbar-expand-sm navbar-dark bg-armor-red">
            <div className="container-fluid py-3">
              {/* <Link to="/" className="link-light">
                ARMOR
              </Link> */}
            </div>
          </nav>
        </div>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer?.user,
    isAdmin: state.userReducer?.armorUser?.user?.isAdmin,
    armorUser: state.userReducer?.armorUser,
    fullScreenPopUp: state.screenActivitiesReducer?.fullScreenPopUp,
    groupData: state.groupReducer?.allGroups,
    oneGroup: state.groupReducer?.oneGroup,
    selectedDatasession: state.datasessionReducer.selectedDatasession,
    participantMeta: state.datasessionReducer?.oneDeviceData ? (
      state.datasessionReducer?.oneDeviceData.datasessionMeta.length > 0 ? (
        state.datasessionReducer?.oneDeviceData.datasessionMeta[0]
      ) : null
    ) : null
  };
}

export default withRouter(
  connect(mapStateToProps, {
    logoutUser,
    openFSPopUp,
    saveUserSettings
  })(MainMenu)
);
