const PRIMARY = "PRIMARY";
const WARNING = "WARNING";
const DANGER = "DANGER";
const CLOSE = "CLOSE";

const alertPrimarySuccess = (message) => ({
  type: PRIMARY,
  message
});

const alertWarningSuccess = (message) => ({
  type: WARNING,
  message
});

const alertDangerSuccess = (message) => ({
  type: DANGER,
  message
});

const alertCloseSuccess = () => ({
  type: CLOSE,
});

export const alertPrimary = (message) => (dispatch) => {
  dispatch(alertPrimarySuccess(message));
};

export const alertWarning = (message) => (dispatch) => {
  dispatch(alertWarningSuccess(message));
};

export const alertDanger = (message) => (dispatch) => {
  dispatch(alertDangerSuccess(message));
};

export const alertClose = () => (dispatch) => {
  dispatch(alertCloseSuccess());
};