import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, Fragment } from 'react';

import { connect } from "react-redux";
import { withRouter } from "react-router";

import { resetUserSettings, saveUserSettings } from '../../../actions/settings';

import { closeFSPopUp } from '../../../actions/screen-activities';

const initialState = {
  assesmentPsiTemp: "PSI", // isIn: [['PSI', 'TEMP']]
  warningThreshold: 6,
  dangerThreshold: 8,
  tempWarningThreshold: 39.5,
  tempDangerThreshold: 39.9,
  notificationOnOff: false,
  tileSortType: "COLOR", // isIn: [['COLOR', 'NAME']],
};

class UserSettings extends Component {
  state = initialState;

  componentDidMount = () => {
    this.setState({
      ...this.state,
      ...this.props.userSettings
    });
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.userSettings !== prevProps.userSettings) {
      this.setState({
        ...this.state,
        ...this.props.userSettings
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  }

  handlePsiRange = (e) => {
    if (e.target.name === 'warningThreshold') {
      if (e.target.value > 11) return;
      this.setState({
        ...this.state,
        warningThreshold: e.target.value,
        dangerThreshold: e.target.value < this.state.dangerThreshold ? this.state.dangerThreshold : +e.target.value + 1
      });
    } else {
      if (e.target.value < 2) return;
      this.setState({
        ...this.state,
        dangerThreshold: e.target.value,
        warningThreshold: e.target.value > this.state.warningThreshold ? this.state.warningThreshold : +e.target.value - 1,
      });
    }
  }

  handleTempRange = (e) => {
    if (e.target.name === 'tempWarningThreshold') {
      if (e.target.value > 39.9) return;
      this.setState({
        ...this.state,
        tempWarningThreshold: e.target.value,
        tempDangerThreshold: e.target.value < this.state.tempDangerThreshold ? this.state.tempDangerThreshold : (+e.target.value + 0.1).toFixed(1)
      });
    } else {
      if (e.target.value < 38.6) return;
      this.setState({
        ...this.state,
        tempDangerThreshold: e.target.value,
        tempWarningThreshold: e.target.value > this.state.tempWarningThreshold ? this.state.tempWarningThreshold : (+e.target.value - 0.1).toFixed(1)
      });
    }
  }

  submitForm = (e) => {
    e.preventDefault();
    // this.props.addNewParticipant(this.state);
    this.props.saveUserSettings(this.state);
    this.props.closeFSPopUp();
  }

  render() {
    return (
      <Fragment>
        <div className="row mt-3">
          <div className="col-12">
            <form onSubmit={(e) => this.submitForm(e)}>
              <div className="row">
                <div>
                  <b>Assesment based on:</b>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <button 
                    type="button" 
                    className={`btn ${this.state.assesmentPsiTemp === "TEMP" ? 'btn-outline-secondary' : 'btn-armor-red'} me-1`}
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        assesmentPsiTemp: "PSI"
                      })
                    }}
                  >
                    PSI
                  </button>

                  <button 
                    type="button" 
                    className={`btn ${this.state.assesmentPsiTemp === "PSI" ? 'btn-outline-secondary' : 'btn-armor-red'} ms-1`}
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        assesmentPsiTemp: "TEMP"
                      })
                    }}
                  >
                    Temperature
                  </button>
                </div>
                
                {
                  this.state.assesmentPsiTemp === "PSI" ? (
                    <Fragment>
                      <div className="row mt-3">
                        <div className="col-12">
                          <label htmlFor="psiWarningThreshold" className="form-label">
                            Orange (warning) {'>'}
                            <span className="lt-grey-bg py-1 px-3">{this.state.warningThreshold}</span>
                          </label>
                          <input 
                            type="range"
                            name="warningThreshold" 
                            className="form-range slider-warning" 
                            min="1" 
                            max="12" 
                            step="1" 
                            id="psiWarningThreshold"
                            value={this.state.warningThreshold}
                            onChange={(e) => {
                              this.handlePsiRange(e);
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-12">
                          <label htmlFor="psiDangerThreshold" className="form-label">
                            Red (critical) {'>'}
                            <span className="lt-grey-bg py-1 px-3">{this.state.dangerThreshold}</span>
                          </label>
                          <input 
                            type="range"
                            name="dangerThreshold" 
                            className="form-range slider-danger" 
                            min="1" 
                            max="12" 
                            step="1" 
                            id="psiDangerThreshold"
                            value={this.state.dangerThreshold}
                            onChange={(e) => {
                              this.handlePsiRange(e);
                            }}
                          />
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="row mt-3">
                        <div className="col-12">
                          <label htmlFor="temperatureWarningThreshold" className="form-label">
                            Orange (warning) {'>'}
                            <span className="lt-grey-bg py-1 px-3">{this.state.tempWarningThreshold} °C</span>
                          </label>
                          <input 
                            type="range"
                            name="tempWarningThreshold" 
                            className="form-range slider-warning" 
                            min="38.5" 
                            max="40" 
                            step="0.1" 
                            id="temperatureWarningThreshold"
                            value={this.state.tempWarningThreshold}
                            onChange={(e) => {
                              this.handleTempRange(e);
                            }}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-12">
                          <label htmlFor="temperatureDangerThreshold" className="form-label">
                            Red (critical) {'>'}{' '}
                            <span className="lt-grey-bg py-1 px-3">{this.state.tempDangerThreshold} °C</span>{' '}
                          </label>
                          <input 
                            type="range"
                            name="tempDangerThreshold" 
                            className="form-range slider-danger" 
                            min="38.5" 
                            max="40" 
                            step="0.1" 
                            id="temperatureDangerThreshold"
                            value={this.state.tempDangerThreshold}
                            onChange={(e) => {
                              this.handleTempRange(e);
                            }}
                          />
                        </div>
                      </div>
                    </Fragment>
                  )
                }
              </div>

              {/* <div className="row">
                <label htmlFor="participantNameOrNumber" className="col-12 col-md-3 col-xl-2 form-label">
                  Name/Number
                </label>
                <div className="col-12 col-md-9 col-xl-10">
                  <input 
                    name="name"
                    // onChange={(e) => this.handleChange(e)}
                    // value={this.state.name}
                    type="text" 
                    className="form-control" 
                    id="participantNameOrNumber" 
                  />
                </div>
              </div> */}

              <div className="row mt-3 mb-5">
                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-armor-red m-2"
                    onClick={this.props.resetUserSettings}
                  >
                    Reset to Default
                  </button>
                  <button 
                    type="submit"
                    className="btn btn-light m-2"
                  >
                    Save <FontAwesomeIcon icon={faSave} />
                  </button>
                </div>

              </div>
            </form>
          </div>
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    userSettings: state.userReducer?.armorUser?.settings
  };
}

export default withRouter(
  connect(mapStateToProps, {
    closeFSPopUp,
    resetUserSettings,
    saveUserSettings
  })(UserSettings)
);
