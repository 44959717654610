
const initialState = {
  redirectTo: null
};

export default function redirectReducer(state = initialState, action) {
  switch (action.type) {
    case "NEW_REDIRECT": {
      return {
        redirectTo: action.newRoute
      };
    }
    case "CLEAR_REDIRECT": {
      return initialState;
    }
    default: {
      return state;
    }
  }
};