const initialState = {
  user: null,
  token: null,
  roles: null,
  permissions: null
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case "USER_AUTHENTICATED": {
      localStorage.setItem('userData', JSON.stringify(action.user.token));
      return {
        ...action.user
      };
    }
    case "ARMOR_USER_RECEIVED": {
      return {
        ...state,
        armorUser: action.armorUser
      }
    }
    case "USER_SETTINGS_CHANGED": {
      return {
        ...state,
        armorUser: {
          user: state.armorUser.user,
          settings: {...action.settings}
        }
      }
    }
    case "LOGOUT_USER": {
      localStorage.removeItem('userData');
      return {...initialState};
    }
    default: {
      return state;
    }
  }
};