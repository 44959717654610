import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import axios from "axios";
import globalFunctions from "../../helpers/globalFunctions";

import { CSVLink } from "react-csv";

import CONFIG from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";

import { openFSPopUp, stopDownload } from "../../actions/screen-activities";
import { Link } from "react-router-dom";

const { armorServerUrl, datatypes } = CONFIG;

const {
  HEARTRATE_DATATYPE,
  CORE_TEMPERATURE_DATATYPE,
  ESTIMATED_VARIANCE_CORE_TEMP_DATATYPE,
  PSI_DATATYPE,
} = datatypes;

const initialState = {
  data: [],
};

const headers = [
  { label: "Device Name", key: "deviceName" },
  { label: "Participant Name", key: "participantName" },
  { label: "Initial Temp", key: "participantInitialTemp" },
  { label: "Timestamp", key: "timestamp" },
  { label: "Datatype", key: "datatype" },
  { label: "Value", key: "value" },
];
const { timestampToDateTime } = globalFunctions;

class HistoricalDsLine extends Component {
  state = initialState;
  csvLinkEl = React.createRef();

  componentDidUpdate = (prevProps) => {
    if (
      this.props.download?.start !== prevProps.download?.start &&
      this.props.download?.start &&
      this.props.download?.datasessionId &&
      this.props.download?.datasessionId === this.props.datasession?.id
    ) {
      this.props.stopDownload();
      this.downloadReport(
        this.props.datasession?.id,
        this.props.download?.type
      );
    }
  };

  fetchData = async (datasessionId) => {
    try {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${this.props.userToken}`;
      const data = await axios
        .get(`${armorServerUrl}/datasession/${datasessionId}/data`)
        .then((res) => {
          if (res.data?.data) {
            return res.data?.data
              .filter(
                (datapoint) =>
                  +datapoint.datatype !== ESTIMATED_VARIANCE_CORE_TEMP_DATATYPE
              )
              .map((datapoint) => {
                const meta = res.data?.datasessionMeta.find(
                  (meta) => +meta.deviceId === +datapoint.deviceId
                );

                let datatypeLabel = "";
                if (+datapoint.datatype === HEARTRATE_DATATYPE) {
                  datatypeLabel = "HEARTRATE";
                } else if (+datapoint.datatype === PSI_DATATYPE) {
                  datatypeLabel = "PSI";
                } else if (+datapoint.datatype === CORE_TEMPERATURE_DATATYPE) {
                  datatypeLabel = "CORE_TEMPERATURE";
                }

                return {
                  deviceName: meta.baceGoId,
                  participantName: meta.participantName,
                  participantInitialTemp: meta.participantInitialTemp,
                  timestamp: +datapoint.timestamp,
                  datatype: datatypeLabel,
                  value: +datapoint.value.toFixed(1),
                };
              });
          } else {
            return [];
          }
        })
        .catch((err) => {
          console.log(err);
        });
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  handleSaveToPC = (jsonData, filename) => {
    const fileData = JSON.stringify(jsonData);
    const blob = new Blob([fileData], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = `${filename}.json`;
    link.href = url;
    link.click();
  };

  downloadReport = async (datasessionId, downloadType) => {
    const data = await this.fetchData(datasessionId);
    if (!downloadType || downloadType === "CSV") {
      this.setState({ data: data }, () => {
        setTimeout(() => {
          this.csvLinkEl.current.link.click();
        }, 0);
      });
      return;
    }

    const group = this.props.groups?.find(
      (group) => +group.id === +this.props.datasession.groupId
    );
    this.handleSaveToPC(
      data,
      `data_${group?.title}_${+this.props.datasession.startedAt}-${+this.props
        .datasession.stoppedAt}`
    );
  };

  render() {
    const startedAt = timestampToDateTime(this.props.datasession.startedAt);
    const stoppedAt = timestampToDateTime(this.props.datasession.stoppedAt);
    const group = this.props.groups?.find(
      (group) => +group.id === +this.props.datasession.groupId
    );

    return (
      <Fragment>
        <tr>
          <td>{startedAt}</td>
          <td>{stoppedAt}</td>
          <td>{group?.title}</td>
          <td>{this.props.datasession?.participantCount || "--"}</td>
          <td>
            {this.props.datasession?.tempMax
              ? this.props.datasession?.tempMax.toFixed(1)
              : "--"}
          </td>
          <td>{this.props.datasession?.psiMax || "--"}</td>
          <td>{this.props.datasession?.hrMax || "--"}</td>
          <td>
            <Link
              to={{
                pathname: `/datasession/${this.props.datasession?.id}/card`,
                state: {
                  fromRoute: window?.location?.pathname,
                },
              }}
            >
              <button className="btn btn-sm text-armor-red">
                <FontAwesomeIcon icon={faEye} />
              </button>
            </Link>
            {this.props.userToken ? (
              <Fragment>
                <button
                  className="btn btn-sm text-armor-red"
                  onClick={() =>
                    this.props.openFSPopUp(
                      "SAVE_DATASESSION",
                      this.props.datasession?.id,
                      {
                        startedAt,
                        stoppedAt,
                        groupTitle: group?.title,
                        participantCount:
                          this.props.datasession?.participantCount || "--",
                        datasessionId: this.props.datasession?.id,
                      }
                    )
                  }
                >
                  <FontAwesomeIcon icon={faArrowDown} />
                </button>
                <CSVLink
                  headers={headers}
                  filename={`data_${group?.title}_${+this.props.datasession
                    .startedAt}-${+this.props.datasession.stoppedAt}.csv`}
                  data={this.state.data}
                  ref={this.csvLinkEl}
                />
              </Fragment>
            ) : (
              ""
            )}
            {this.props.userToken ? (
              <Fragment>
                <button
                  className="btn btn-sm text-armor-red"
                  onClick={() =>
                    this.props.openFSPopUp(
                      "DELETE_ONE_DATASESSION",
                      this.props.datasession?.id,
                      {
                        startedAt,
                        stoppedAt,
                        groupTitle: group?.title,
                        participantCount:
                          this.props.datasession?.participantCount || "--",
                        datasessionId: this.props.datasession?.id,
                      }
                    )
                  }
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </Fragment>
            ) : (
              ""
            )}
          </td>
        </tr>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    userToken: state.userReducer?.token?.token,
    allGroups: state.groupReducer.allGroups,
    groups: state.groupReducer?.groups,
    download: state.screenActivitiesReducer?.download,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    openFSPopUp,
    stopDownload,
  })(HistoricalDsLine)
);
