import { connect } from 'react-redux';
import { withRouter } from "react-router";

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class BreadcrumbMenu extends Component {
  render() {
    const pathname = this.props.location.pathname;
    if (pathname.startsWith("/group") && (
      pathname.endsWith("/active-session/list") || pathname.endsWith("/active-session") || pathname.endsWith("/active-session/map")
    )) {
      const selectedGroup = 
        this.props.groupData ?
          (
            this.props.groupData
            .find((group) => (+group.id === +this.props.selectedDatasession?.datasession?.groupId))
          ) : null;

      return (
        <div className="row top-menu">
          <div className="container-fluid">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb h5">
                <li className="breadcrumb-item">
                  <Link to="/active-sessions">
                    Active Sessions
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {selectedGroup?.title}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      )
    } else if (pathname.startsWith("/history") && !pathname.includes('/stopped')) {
      return (
        <div className="row top-menu">
          <div className="container-fluid">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb h5">
                <li className="breadcrumb-item">
                  <Link to="/active-sessions">
                    Active Sessions
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`/group/${this.props?.oneDeviceData?.group?.id}/active-session`}>
                    {this.props?.oneDeviceData?.group?.title}
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  {this.props?.oneDeviceData?.datasessionMeta[0]?.participantName}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      )
    } else if (pathname.startsWith("/history") && pathname.includes('/stopped')) {
      return (
        <div className="row top-menu">
          <div className="container-fluid">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/historical-datasessions/1">
                    Stored Sessions
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link 
                    to={
                      (this.props?.location?.state?.fromRoute) ? 
                      (this.props?.location?.state?.fromRoute) : 
                      `/datasession/${this.props?.oneDeviceData?.id}/card`
                    }
                  >
                    {this.props?.oneDeviceData?.group?.title}
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  {this.props?.oneDeviceData?.datasessionMeta[0]?.participantName}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      )
    } else if (pathname === "/active-sessions") {
      return (
        <div className="row top-menu">
          <div className="container-fluid">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb h5">
                <li className="breadcrumb-item active">
                  Active Sessions Test
                </li>
              </ol>
            </nav>
          </div>
        </div>
      )
    } else if (pathname.startsWith("/group") && pathname.endsWith("/edit")) {
      return (
        <div className="row top-menu">
          <div className="container-fluid">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb h5">
                <li className="breadcrumb-item">
                  <Link to="/groups">
                    Groups
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  {this.props?.group?.title}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      )
    } else if (pathname.startsWith("/datasession") && (pathname.endsWith("/card") || pathname.endsWith("/list"))) {
      const selectedGroup = 
        this.props.groupData ?
          (
            this.props.groupData
            .find((group) => (+group.id === +this.props.selectedDatasession?.datasession?.groupId))
          ) : null;
      
      return (
        <div className="row top-menu">
          <div className="container-fluid">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={(this.props?.location?.state?.fromRoute) ? (this.props?.location?.state?.fromRoute) : "/historical-datasessions/1"}>
                    Stored Sessions
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  {selectedGroup?.title}
                </li>
              </ol>
            </nav>
          </div>
        </div>
      )
    } else if (pathname === "/groups") {
      return (
        <div className="row top-menu">
          <div className="container-fluid">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb h5">
                <li className="breadcrumb-item active">
                  Groups
                </li>
              </ol>
            </nav>
          </div>
        </div>
      )
    } else if (pathname.startsWith("/historical-datasessions")) {
      return (
        <div className="row top-menu">
          <div className="container-fluid">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb h5">
                <li className="breadcrumb-item active">
                  Stored Sessions
                </li>
              </ol>
            </nav>
          </div>
        </div>
      )
    } else if (pathname === "/settings") {
      return (
        <div className="row top-menu">
          <div className="container-fluid">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb h5">
                <li className="breadcrumb-item active">
                  Settings
                </li>
              </ol>
            </nav>
          </div>
        </div>
      )
    } else {
      return (
        <></>
      )
    }
  }
}

function mapStateToProps(state, ownProps) {
  const pathname = ownProps.location.pathname;

  if (
    (
      pathname.startsWith("/group") && (
        pathname.endsWith("/active-session") || pathname.endsWith("/active-session/list") || pathname.endsWith("/active-session/map")
      )
    )
  ) { 
    return {
      selectedDatasession: state.datasessionReducer?.selectedDatasession,
      groupData: state.groupReducer?.allGroups,
    }
  } else if (pathname.startsWith("/datasession") && (pathname.endsWith("/card") || pathname.endsWith("/list"))) {
    return {
      groupData: state.groupReducer?.allGroups,
      selectedDatasession: state.datasessionReducer?.histotricalDatasession,
    }
  } else if (pathname.startsWith("/history")) {
    return {
      oneDeviceData: state?.datasessionReducer?.oneDeviceData,
    }
  } else if (pathname.startsWith("/group") && pathname.endsWith("/edit")) {
    return {
      group: state?.groupReducer?.oneGroup,
    }
  }
  return {
  };
}

export default withRouter(connect(mapStateToProps, {  })(BreadcrumbMenu));