import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { alertClose } from "../../actions/alert";

import CONFIG from '../../config';
const { ALERT_TIMEOUT } = CONFIG;

class Flash extends Component {
  componentDidUpdate = (prevProps) => {
    if (this.props.alert?.status && this.props.alert.status !== prevProps.alert.status) {
      this.timeout = setTimeout(() => {
        this.props.alertClose();
      }, ALERT_TIMEOUT);
    }
  }

  componentWillUnmount = () => {
    clearTimeout(this.timeout);
    this.timeout = null;
  }

  render() {
    if (this.props.alert.status === "PRIMARY") {
      return (
        <Fragment>
          <div className="alert alert-primary alert-dismissible fade show mt-3" role="alert">
            {this.props.alert.message}
            <button 
              type="button" 
              className="btn-close" 
              data-bs-dismiss="alert" 
              aria-label="Close"
              onClick={this.props.alertClose}
            />
          </div>
        </Fragment>
      );
    } else if (this.props.alert.status === "WARNING") {
      return (
        <Fragment>
          <div className="alert alert-warning alert-dismissible fade show mt-3" role="alert">
            {this.props.alert.message}
            <button 
              type="button" 
              className="btn-close" 
              data-bs-dismiss="alert" 
              aria-label="Close"
              onClick={this.props.alertClose}
            />
          </div>
        </Fragment>
      );
    } else if (this.props.alert.status === "DANGER") {
      return (
        <Fragment>
          <div className="alert alert-danger alert-dismissible fade show mt-3" role="alert">
            {this.props.alert.message}
            <button 
              type="button" 
              className="btn-close" 
              data-bs-dismiss="alert" 
              aria-label="Close"
              onClick={this.props.alertClose}
            />
          </div>
        </Fragment>
      );
    }
    // No Alert
    return (
      <Fragment>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    alert: state.alertReducer
  };
}

export default withRouter(
  connect(mapStateToProps, {
    alertClose
  })(Flash)
);