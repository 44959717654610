const NEW_REDIRECT = "NEW_REDIRECT";
const CLEAR_REDIRECT = "CLEAR_REDIRECT";

const newRedirectSuccess = (newRoute) => ({
  type: NEW_REDIRECT,
  newRoute
});

const clearRedirectSuccess = () => ({
  type: CLEAR_REDIRECT
});

// This action can be used to redirect to newRoute using this.prop.history.push(newRoute) in Main.jsx
export const newRedirect = (newRoute) => dispatch => {
  dispatch(newRedirectSuccess(newRoute));
};

export const clearRedirect = () => dispatch => {
  dispatch(clearRedirectSuccess());
};