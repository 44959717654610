import { connect } from "react-redux";
import { withRouter } from "react-router";

import { closeFSPopUp } from '../../../actions/screen-activities';
import { addNewDevice } from "../../../actions/admin";

import { faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, Fragment } from 'react';

const initialState = {
  baceGoId: "",
  baceGroupId: null
};

class AddNewDevice extends Component {
  state = initialState;

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  }

  submitForm = (e) => {
    e.preventDefault();
    console.log(this.state);
    this.props.addNewDevice(this.state);
    this.props.closeFSPopUp();
  }

  render() {
    if (this.props.allLocations) {
      return (
        <Fragment>
          <div className="d-flex justify-content-end px-3 pb-3">
            <div className="container bg-light modal-body-container-scrollable">
              <div className="modal-body-container-internal">

                <div className="row mt-3">
                  <div className="col-12">
                    <form onSubmit={(e) => this.submitForm(e)}>
                      <div className="row">
                        <label htmlFor="baceGoDeviceId" className="col-12 col-md-5 col-xl-4 form-label">
                          BACE Go Device Id
                        </label>
                        <div className="col-12 col-md-7 col-xl-8">
                          <input 
                            name="baceGoId"
                            onChange={(e) => this.handleChange(e)}
                            value={this.state.baceGoId}
                            type="text" 
                            className="form-control" 
                            id="baceGoDeviceId" 
                          />
                        </div>
                      </div>
        
                      <div className="row mt-3">
                        <label htmlFor="selectLocation" className="col-12 col-md-5 col-xl-4">Select location</label>
                        <div className="col-12 col-md-7 col-xl-8">
                          <select 
                            className="form-select" 
                            aria-label="Select location" 
                            id="selectLocation" 
                            required={true}
                            name="baceGroupId"
                            defaultValue={null}
                            onChange={(e) => this.handleChange(e)}
                          >
                            <option>Select location</option>
                            {this.props.allLocations.map(location => (
                              <option
                                key={location.userAcc.baceGroupId + location.baceGroup.created_at} 
                                value={location.userAcc.baceGroupId}
                              >
                                {location.baceGroup.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>                  
          </div>

          <div className="text-end mb-3 me-3">
            <button className="btn btn-light me-3" onClick={this.props.closeFSPopUp}>
              CANCEL
            </button>
            <button className="btn btn-armor-red me-3" 
              onClick={(e) => this.submitForm(e)}
            >
              Save <FontAwesomeIcon icon={faSave} />
            </button>
          </div>
        </Fragment>
      )
    } else {
      <Fragment>
        <div className="row mt-3">
          <h4 className="text-center">Locations not found</h4>
        </div>
      </Fragment>
    }
  }
}

function mapStateToProps(state) {
  return {
    allLocations: state.adminReducer.allLocations
      .filter((location) => (location.userAcc))
      .sort((a, b) => {
        if(a.baceGroup.label < b.baceGroup.label) { return -1; }
        if(a.baceGroup.label > b.baceGroup.label) { return 1; }
        return 0;
      }),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    closeFSPopUp,
    addNewDevice
  })(AddNewDevice)
);
