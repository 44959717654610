import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import UserSettings from '../modals/modal-bodies/UserSettings';


class PsiTempSettings extends Component {
  render() {
    return (
      <div className="row">
        <div className="armor-card col-12">
          <div className="row">
            <div className="col-12 col-md-8">
              <UserSettings />

            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default withRouter(
  connect(mapStateToProps, {
  })(PsiTempSettings)
);