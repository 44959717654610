import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { fetchActiveDatasession, clearOneDatasession, datasessionPolling } from "../../actions/datasession";
import { fetchAllGroups } from "../../actions/group";

import LoaderRoller from '../../components/loader/LoaderRoller';

import { setPollingTimer } from "../../actions/screen-activities";

import CONFIG from '../../config';

import globalFunctions from "../../helpers/globalFunctions";
import GroupMap from '../../components/map/GroupMap';
const { averageFromArr } = globalFunctions;

const { datatypes } = CONFIG;
const {
  CORE_TEMPERATURE_DATATYPE,
  PSI_DATATYPE,
  GNSS_ARR_DATATYPE
} = datatypes;

const initialState = {
  measurementAlertPopUp: true,
  interval: 1000,
  polledTimes: 0
}

class GroupActiveSessionMap extends Component {
  state = initialState;
  componentDidMount = () => {
    const groupId = this.props.match.params.id;
    this.props.fetchAllGroups();
    this.fetchData(groupId);
  }

  componentDidUpdate = (prevProps) => {
    if (this.state.measurementAlertPopUp) {
      const minutes20inMs = 1200000;
      if ((Date.now() - +this.props.selectedDatasession?.datasession?.startedAt) > minutes20inMs) {
        this.closePopup();
      }
    }

    if (this.props.match.params.id !== prevProps.match.params.id && +this.props.match.params.id) {
      const groupId = this.props.match.params.id;
      this.fetchData(groupId);
    }
  }

  componentWillUnmount = () => {
    this.props.clearOneDatasession();
    this.clearPollingTimer();
  }

  fetchData = (groupId) => {
    if (!+groupId) return;
    this.props.fetchActiveDatasession(+groupId, true);
  }

  closePopup = () => {
    this.setState({
      ...this.state,
      measurementAlertPopUp: false
    })
  }

  clearPollingTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  render() {
    if (this.props.selectedDatasession && this.props?.selectedDatasession?.latestData && this.props.userSettings?.assesmentPsiTemp) {
      const allLocations = this.props.selectedDatasession.latestData.filter((data) => (data.datatype === GNSS_ARR_DATATYPE));
      if (allLocations.length < 1) {
        return (
          <Fragment>
          <div className="row">
            <div className="armor-card col-12">
              <div className="row">
                <div className="col-12">
                  <div className="row mt-3">
                    <div className="col-12 col-xl-10 col-xxl-8">
                      <h1>No location data have been received yet.</h1>
                    </div>
                  </div>
               </div>
              </div>
            </div>
          </div>
        </Fragment>
        )
      }
      const latArr = allLocations.map(location => location.arrayValue[0]);
      const lonArr = allLocations.map(location => location.arrayValue[1]);
      const latCenter = averageFromArr(latArr);
      const lonCenter = averageFromArr(lonArr);

      const datasessionMetaWithData = this.props.selectedDatasession.datasession.datasessionMeta.map((meta) => {
        const locationData = this.props.selectedDatasession.latestData.find((data) => 
          (data.datatype === GNSS_ARR_DATATYPE && +data.deviceId === +meta.deviceId)
        );
        const coreTemp = this.props.selectedDatasession.latestData.find((data) => 
          (data.datatype === CORE_TEMPERATURE_DATATYPE && +data.deviceId === +meta.deviceId)
        );
        const psi = this.props.selectedDatasession.latestData.find((data) => 
          (data.datatype === PSI_DATATYPE && +data.deviceId === +meta.deviceId)
        );
        return {
          ...meta,
          locationData,
          coreTemp,
          psi
        };
      });

      return (
        <Fragment>
          <div className="row">
            <div className="armor-card col-12">
              <div className="row">
                <div className="col-12">
                  <div className="row mt-3">
                    <div className="col-12">
                      <GroupMap
                        center={{lat: latCenter, lng: lonCenter}}
                        groupData={datasessionMetaWithData}
                      />
                    </div>
                  </div>
               </div>
              </div>
            </div>
          </div>
        </Fragment>
      )
    } else {
      return (
        <LoaderRoller />
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    selectedDatasession: state.datasessionReducer?.selectedDatasession,
    selectedDatasessionPolled: state.datasessionReducer?.selectedDatasessionPolled,
    pollingCounter: state.datasessionReducer?.pollingCounter,
    userSettings: state.userReducer.armorUser.settings
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchActiveDatasession,
    clearOneDatasession,
    fetchAllGroups,
    datasessionPolling,
    setPollingTimer
  })(GroupActiveSessionMap)
);