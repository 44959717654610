import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import { fetchAllGroups } from "../../actions/group";
import { openFSPopUp } from "../../actions/screen-activities";

import LoaderRoller from "../../components/loader/LoaderRoller";

import "./style.css";
import GroupListLine from "../../components/group-overview/GroupListLine";
import FullScreenModal from "../../components/modals/FullScreenModal";

class GroupList extends Component {
  componentDidMount = () => {
    this.props.fetchAllGroups();
  };

  render() {
    if (this.props.groupData) {
      return (
        <Fragment>
          <div className="col-12 mt-3 mb-5">
            <div className="armor-card">
              <div className="table-responsive-lg">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Groupname</th>
                      <th scope="col">Group size</th>
                      <th scope="col">In active session</th>
                      <th scope="col">Start date</th>
                      <th scope="col">End date</th>
                      <th scope="col">Start / View session</th>
                      <th scope="col">Edit / Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.groupData.map((group) => {
                      return <GroupListLine group={group} key={group.id} />;
                    })}
                  </tbody>
                </table>
                {/* <Pagination /> */}
              </div>
            </div>
          </div>
          {this.props.fullScreenPopUp ? <FullScreenModal /> : <div></div>}
        </Fragment>
      );
    } else {
      return <LoaderRoller />;
    }
  }
}

function mapStateToProps(state) {
  return {
    groupData: state.groupReducer?.allGroups,  
    fullScreenPopUp: state.screenActivitiesReducer?.fullScreenPopUp,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchAllGroups,
    openFSPopUp,
  })(GroupList)
);
