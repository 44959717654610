import {
  faEye,
  faPlay,
  faPen,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import globalFunctions from "../../helpers/globalFunctions";
import { toggleDataSession } from "../../actions/datasession";
import { openFSPopUp } from "../../actions/screen-activities";

const { timestampToDateTime } = globalFunctions;

class GroupListLine extends Component {
  startDatasession = (groupId) => {
    this.setState({
      ...this.state,
      clickedGroup: +groupId,
    });
    this.props.toggleDataSession(groupId);
  };

  render() {
    const startTimestamp = this.props.group?.activeDatasession
      ? this.props.group?.activeDatasession?.startedAt
      : this.props.group?.latestDatasession?.startedAt;
    const stopTimestamp = this.props.group?.activeDatasession
      ? this.props.group?.activeDatasession?.stoppedAt
      : this.props.group?.latestDatasession?.stoppedAt;
    const startedAt = startTimestamp
      ? timestampToDateTime(startTimestamp)
      : "--";
    const stoppedAt = stopTimestamp ? timestampToDateTime(stopTimestamp) : "--";

    return (
      <Fragment>
        <tr>
          <td>{this.props.group?.title}</td>
          <td>{this.props.group?.participants}</td>
          <td>{this.props.group?.activeDatasession ? "Yes" : "No"}</td>
          <td>{startedAt}</td>
          <td>{stoppedAt}</td>
          <td>
            {this.props.group?.activeDatasession ? (
              <Fragment>
                <button className="btn">
                  <Link
                    to={`/group/${this.props.group.id}/active-session`}
                    className="armor-page-link"
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      className="me-2 text-armor-red"
                    />
                    <span className="d-none d-lg-inline">View Session</span>
                  </Link>
                </button>
              </Fragment>
            ) : (
              <Fragment>
                <button
                  className="btn "
                  onClick={() => this.startDatasession(this.props.group.id)}
                >
                  <FontAwesomeIcon
                    icon={faPlay}
                    className="me-2 text-armor-red"
                  />
                  <span className="d-none d-lg-inline">Start New</span>
                </button>
              </Fragment>
            )}
          </td>
          <td>
            {this.props.group?.activeDatasession ? (
              ""
            ) : (
              <Fragment>
                <button className="btn">
                  <Link to={`/group/${this.props.group.id}/edit`}>
                    <FontAwesomeIcon icon={faPen} className="text-armor-red" />
                  </Link>
                </button>
                <button
                  className="btn"
                  // onClick={() =>
                  //   this.props.openFSPopUp("DELETE_GROUP", this.props.group.id)
                  // }
                  onClick={() =>
                    this.props.openFSPopUp("ARCHIVE_GROUP", this.props.group.id)
                  }
                >
                  <FontAwesomeIcon icon={faTrash} className="text-armor-red" />
                </button>
              </Fragment>
            )}
          </td>
        </tr>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    userToken: state.userReducer?.token?.token,
    allGroups: state.groupReducer.allGroups,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    toggleDataSession,
    openFSPopUp,
  })(GroupListLine)
);
