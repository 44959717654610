import React, { Component, Fragment } from 'react';

import { connect } from "react-redux";
import { withRouter } from "react-router";

import { closeFSPopUp } from '../../../actions/screen-activities';
import { addGroup } from '../../../actions/group';

const initialState = {
  newGroupName: ""
}
class NewGroupBody extends Component {
  state = initialState;

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    })
  }

  submitForm = (e) => {
    e.preventDefault();
    this.props.addGroup(this.state.newGroupName);
    this.props.closeFSPopUp();
  }

  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-end px-3 pb-3">
          <div className="container bg-light modal-body-container-scrollable">
            <div className="modal-body-container-internal">
              <form onSubmit={(e) => this.submitForm(e)}>
                <div className="row mt-2">
                  <div className="form-group">
                    <label htmlFor="groupname"><b>Enter groupname</b></label>
                    <input 
                      type="text" 
                      className="form-control mt-3" 
                      id="groupname" 
                      aria-describedby="groupName" 
                      placeholder="Enter groupname" 
                      name="newGroupName"
                      onChange={(e) => {
                        this.handleChange(e)
                      }}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="text-end mb-3 me-3">
          <button className="btn btn-light me-3" onClick={this.props.closeFSPopUp}>
            CANCEL
          </button>
          <button className="btn btn-armor-red me-3" 
            onClick={(e) => {this.submitForm(e)}}
          >
            OK
          </button>
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default withRouter(
  connect(mapStateToProps, {
    closeFSPopUp,
    addGroup
  })(NewGroupBody)
);
