import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { fetchActiveDatasession, clearOneDatasession, datasessionPolling } from "../../actions/datasession";
import { fetchAllGroups } from "../../actions/group";

import LoaderRoller from '../../components/loader/LoaderRoller';

import { faExclamationTriangle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setPollingTimer } from "../../actions/screen-activities";

import CONFIG from '../../config';
import SessionLine from '../../components/session-line/SessionLine';
const { datatypes, POLLING_INTERVAL } = CONFIG;
const {
  CORE_TEMPERATURE_DATATYPE,
  PSI_DATATYPE
} = datatypes;

const initialState = {
  measurementAlertPopUp: true,
  interval: 1000,
  polledTimes: 0
}

const minutes5inMs = 300000;

class GroupActiveSessionList extends Component {
  state = initialState;
  componentDidMount = () => {
    const groupId = this.props.match.params.id;
    this.props.fetchAllGroups();
    this.fetchData(groupId);
  }

  componentDidUpdate = (prevProps) => {
    if (this.state.measurementAlertPopUp) {
      const minutes20inMs = 1200000;
      if ((Date.now() - +this.props.selectedDatasession?.datasession?.startedAt) > minutes20inMs) {
        this.closePopup();
      }
    }

    if (this.props.match.params.id !== prevProps.match.params.id && +this.props.match.params.id) {
      const groupId = this.props.match.params.id;
      this.fetchData(groupId);
    }
  }

  componentWillUnmount = () => {
    this.props.clearOneDatasession();
    this.clearPollingTimer();
  }

  fetchData = (groupId) => {
    if (!+groupId) return;
    this.props.fetchActiveDatasession(+groupId, true);
    
    // Polling every x seconds, interval defined in CONFIG
    this.timer = setInterval(
      ()=> {
        this.props.datasessionPolling();
        this.props.fetchActiveDatasession(+groupId, true)
      }, 
    POLLING_INTERVAL);
    
    if (this.timer) {
      this.props.setPollingTimer(this.timer);
    }

    this.setState({
      ...this.state,
      polledTimes: this.state.polledTimes + 1
    });
  }

  closePopup = () => {
    this.setState({
      ...this.state,
      measurementAlertPopUp: false
    })
  }

  clearPollingTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  render() {
    if (this.props.selectedDatasession && this.props?.selectedDatasession?.latestData && this.props.userSettings?.assesmentPsiTemp) {
      let sortedDataIds = [];
      let sortedMetaToShow = [];
      if (this.props.userSettings.tileSortType === "COLOR") {
        // Sort by color
        if (this.props.userSettings.assesmentPsiTemp === "PSI") {
          // console.log(this.props.selectedDatasession.latestData);
          sortedDataIds = this.props.selectedDatasession.latestData
            .filter(data => (+data.datatype === +PSI_DATATYPE))
            .sort((a, b) => {
              if (b.value === a.value) {
                // If same PSI, sort by CoreTemp
                const aTemp = this.props.selectedDatasession.latestData
                  .find(data => (+data.datatype === +CORE_TEMPERATURE_DATATYPE) && (a.deviceId === data.deviceId));
                const bTemp = this.props.selectedDatasession.latestData
                  .find(data => (+data.datatype === +CORE_TEMPERATURE_DATATYPE) && (b.deviceId === data.deviceId));
                return (bTemp.value - aTemp.value);
              }
              return (b.value - a.value);
            });
        } else {
          sortedDataIds = this.props.selectedDatasession.latestData
            .filter(data => (+data.datatype === +CORE_TEMPERATURE_DATATYPE))
            .map(data => ({
              ...data,
              value: (+data.value).toFixed(1)
            }))
            .sort((a, b) => {
              if (b.value === a.value) {
                // If same CoreTemp, sort by PSI
                const aPsi = this.props.selectedDatasession.latestData
                  .find(data => (+data.datatype === +PSI_DATATYPE) && (a.deviceId === data.deviceId));
                const bPsi = this.props.selectedDatasession.latestData
                  .find(data => (+data.datatype === +PSI_DATATYPE) && (b.deviceId === data.deviceId));
                // console.log(`${bPsi.value}  - ${aPsi.value}`, bPsi.value - aPsi.value);
                return (bPsi.value - aPsi.value);
              }
              return (b.value - a.value);
            });
        }
        // console.log("sortedDataIds:", sortedDataIds);
        let datasessionMeta = [...this.props.selectedDatasession?.datasession?.datasessionMeta];
        // console.log("datasessionMeta:", datasessionMeta);
        const sortedMeta = sortedDataIds.map(data => (
          datasessionMeta.find((meta) => (+meta.deviceId === +data.deviceId))
        ));
        // console.log("Sorted meta", sortedMeta);
        const sortedMetaIds = sortedMeta.map(meta => meta.id);
        const unsortedMeta = datasessionMeta.filter(meta => (sortedMetaIds.indexOf(meta.id) === -1));
        sortedMetaToShow = [...sortedMeta, ...unsortedMeta];
      } else {
        // Sort by name
        sortedMetaToShow = [...this.props.selectedDatasession?.datasession?.datasessionMeta]
          .sort((a, b) => {
            if (a.participantName > b.participantName) {
              return 1;
            }
            if (a.participantName < b.participantName) {
              return -1;
            }
            return 0;
          });
      }

      const dataMissingLast5Minutes = this.props.selectedDatasession.latestData.find(data => (
        +data.datatype === CORE_TEMPERATURE_DATATYPE && ((Date.now() - +data?.timestamp) > minutes5inMs)
      ));

      return (
        <Fragment>
          {
            this.state.measurementAlertPopUp ? (
              <div className="container-fluid bg-armor-red p-3 mb-1">
                <div className="row">
                  <div className="col-1">
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  </div>
                  <div className="col-10">
                    <i>Values may be inaccurate when measurements are shorter than 20 minutes!</i>
                  </div>
                  <button className="col-1 btn text-light" onClick={this.closePopup}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                  </button>
                </div>
              </div>
            ) : (
              ''
            )
          }
          {
            dataMissingLast5Minutes ? (
              <div className="container-fluid bg-armor-red p-3 mb-1">
                <div className="row">
                  <div className="col-1">
                    <FontAwesomeIcon icon={faExclamationTriangle} />
                  </div>
                  <div className="col-10">
                    <b>No data has been received for more than 5 minutes.</b> <i>Values may be inaccurate within 20 minutes after gap in measurement.</i>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )
          }
          
          <div className="col-12 mt-3 mb-5">
            <div className="armor-card">
            <div className="table-responsive-lg">
              <table className="table">
              <thead>
                <tr>
                  <th scope="col">Status</th>
                  <th scope="col">Alert type</th>
                  <th scope="col">Signal</th>
                  <th scope="col">Name/ID</th>
                  <th scope="col">Tcore</th>
                  <th scope="col">PSI</th>
                  <th scope="col">Heart Rate</th>
                  <th scope="col">View details</th>
                </tr>
              </thead>
              <tbody>
                {sortedMetaToShow.map((meta, i) => {
                  return (
                    <SessionLine meta={meta} key={i} />
                  )
                })}
              </tbody>
              </table>
              {/* <Pagination /> */}
            </div>
            </div>
          </div>
        </Fragment>
      )
    } else {
      return (
        <LoaderRoller />
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    selectedDatasession: state.datasessionReducer?.selectedDatasession,
    selectedDatasessionPolled: state.datasessionReducer?.selectedDatasessionPolled,
    pollingCounter: state.datasessionReducer?.pollingCounter,
    userSettings: state.userReducer.armorUser.settings
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchActiveDatasession,
    clearOneDatasession,
    fetchAllGroups,
    datasessionPolling,
    setPollingTimer
  })(GroupActiveSessionList)
);