import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { removeParticipant } from '../../actions/participant';
import { openFSPopUp } from '../../actions/screen-activities';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons';

const initialState = {
  openEdit: false
};

class ParticipantListLine extends Component {
  state = initialState;
  toggleEdit = () => {
    this.setState({
      ...this.state,
      openEdit: !this.state.openEdit
    })
  }

  render() {
    return (
      <Fragment>
        <tr>
          <td></td>
          <td>{this.props.participant?.name}</td>
          <td>{this.props.participant?.initialTemp}</td>
          <td>{this.props.participant.device.baceGoId}</td>
          <td>
            <Fragment>
              <button className="btn" onClick={() => this.props.openFSPopUp("EDIT_PARTICIPANT", this.props.participant.id)}>
                <FontAwesomeIcon icon={faPen} className="text-armor-red" />
              </button>
              <button 
                className="btn" 
                onClick={() => this.props.removeParticipant(this.props.participant.id)}
              >
                <FontAwesomeIcon icon={faTrash} className="text-armor-red" />
              </button>
            </Fragment>
          </td>
        </tr>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default withRouter(
  connect(mapStateToProps, {
    removeParticipant,
    openFSPopUp
  })(ParticipantListLine)
);