import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
// import { showInfo, showDanger } from '../../actions/notification';
import { refreshToken, tryUserAuth, logoutUser } from "../../actions/user";

import LoaderRoller from "../../components/loader/LoaderRoller";

import armorLogo from "../../images/logo/armor_logo.png"

class Authorization extends Component {
  componentDidMount = () => {
    const queryParams = new URLSearchParams(this.props.location.search);
    const token = queryParams.get("token");
    const expires = queryParams.get("expires");
    const refresh = queryParams.get("refresh");
    const expiration = new Date(expires);
    const dateNow = new Date();

    if (token && expires && refresh) {
      if (expiration < dateNow) {
        // Token have expired
        this.props.history.push("/");
        return;
      }
      const oneDayInMs = 86400000
      if ((expiration - (oneDayInMs * 7)) < dateNow) {
        // Token expires in less then 7 days
        this.props.refreshToken({
          token,
          refresh
        });
        return;
      }
      //  Try Authorization
      this.props.tryUserAuth({
        token,
        expires,
        refresh,
      });
    } else {
      // redirect to login in case token, expiration or refresh token are missing
      this.props.logoutUser();
      this.props.history.push("/");
    }
  }

  render() {
    return (        
      <div className="row">
        <div 
          className="col-12 text-center" 
          style={{
            height: "100%",
            marginTop: "20vh",
          }}
        >
          <div className="row justify-content-center">
            <div className="col-3"></div>
            <div className="col-6">
              <img 
                src={armorLogo} 
                className="img-fluid logo-height-15" 
                alt="Armor Logo"              />
            </div>
            <div className="col-3"></div>
          </div>
          <LoaderRoller />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer,
  };
}

export default withRouter(
  connect(mapStateToProps, { 
    // showInfo, 
    // showDanger, 
    refreshToken,
    tryUserAuth,
    logoutUser
  })(Authorization)
);
