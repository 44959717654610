import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router";
import AddNewDevice from "./modal-bodies/AddNewDevice";
import AddPersonBody from "./modal-bodies/AddParticipantBody";
import ChangeDeviceLocation from "./modal-bodies/ChangeDeviceLocation";
import DeleteDatasession from "./modal-bodies/DeleteDatasession";
import DeleteGroup from "./modal-bodies/DeleteGroup";
// import EditGroupBody from './modal-bodies/EditGroupBody';
import EditParticipant from "./modal-bodies/EditParticipant";
import LogOutBody from "./modal-bodies/LogOutBody";
import NewGroupBody from "./modal-bodies/NewGroupBody";
import SaveDatasession from "./modal-bodies/SaveDatasession";
import StopDatasession from "./modal-bodies/StopDatasession";
import StopDatasessionWithoutData from "./modal-bodies/StopDatasessionWithoutData";
// import UserSettings from './modal-bodies/UserSettings';

const LOG_OUT = "LOG_OUT";
// const INITIAL_CONFIGURATION = "INITIAL_CONFIGURATION";
const NEW_GROUP = "NEW_GROUP";
const ADD_PERSON = "ADD_PERSON";
// const EDIT_GROUP = "EDIT_GROUP";
const EDIT_PARTICIPANT = "EDIT_PARTICIPANT";
const ADD_NEW_DEVICE = "ADD_NEW_DEVICE";
const CHANGE_DEVICE_LOCATION = "CHANGE_DEVICE_LOCATION";
// const DELETE_GROUP = "DELETE_GROUP";
const ARCHIVE_GROUP = "ARCHIVE_GROUP";
const SAVE_DATASESSION = "SAVE_DATASESSION";
const DELETE_ONE_DATASESSION = "DELETE_ONE_DATASESSION";
const STOP_DATASESSION = "STOP_DATASESSION";
const STOP_DATASESSION_WITHOUT_DATA = "STOP_DATASESSION_WITHOUT_DATA";

class ModalBody extends Component {
  render() {
    if (this.props.modalAction) {
      if (this.props.modalAction === LOG_OUT) {
        return <LogOutBody />;
      }
      // else if (this.props.modalAction === INITIAL_CONFIGURATION) {
      //   return (
      //     <Fragment>
      //       <UserSettings />
      //     </Fragment>
      //   )
      // }
      else if (this.props.modalAction === NEW_GROUP) {
        return <NewGroupBody />;
      } else if (this.props.modalAction === ADD_PERSON) {
        return <AddPersonBody />;
      }
      // else if (this.props.modalAction === EDIT_GROUP) {
      //   return (
      //     <EditGroupBody />
      //   )
      // }
      else if (this.props.modalAction === EDIT_PARTICIPANT) {
        return <EditParticipant />;
      } else if (this.props.modalAction === ADD_NEW_DEVICE) {
        return (
          // TODO: Edit this after change applied for Admin
          <AddNewDevice />
        );
      } else if (this.props.modalAction === CHANGE_DEVICE_LOCATION) {
        return (
          // TODO: Edit this after change applied for Admin
          <ChangeDeviceLocation />
        );
        // } else if (this.props.modalAction === DELETE_GROUP) {
        //   return (
        //     <DeleteGroup />
        //   )
      } else if (this.props.modalAction === ARCHIVE_GROUP) {
        return <DeleteGroup />;
      } else if (this.props.modalAction === SAVE_DATASESSION) {
        return <SaveDatasession />;
      } else if (this.props.modalAction === DELETE_ONE_DATASESSION) {
        return <DeleteDatasession />;
      } else if (this.props.modalAction === STOP_DATASESSION) {
        return <StopDatasession />;
      } else if (this.props.modalAction === STOP_DATASESSION_WITHOUT_DATA) {
        return <StopDatasessionWithoutData />;
      } else {
        return (
          <Fragment>
            <div className="row">Please configure Modal Action</div>
          </Fragment>
        );
      }
    } else {
      return (
        <Fragment>
          <div className="row">Please configure Modal Action</div>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    modalAction: state.screenActivitiesReducer?.activeAction,
  };
}

export default withRouter(connect(mapStateToProps, {})(ModalBody));
