import { faStop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter, matchPath } from "react-router";

import { closeFSPopUp, openFSPopUp } from '../../../actions/screen-activities';
import { toggleDataSession } from "../../../actions/datasession";

import globalFunctions from '../../../helpers/globalFunctions';
const { timestampToDateTime } = globalFunctions;


class StopDatasessionWithoutData extends Component {
  stopSession = () => {
    // console.log(this.props.selectedDatasession);
    if (this.props.timerId) {
      clearInterval(this.props.timerId);
    }
    const matchGroup = matchPath(window.location.pathname, {
      path: "/group/:id/active-session",
    });
    if (matchGroup) {
      this.props.toggleDataSession(matchGroup.params.id, true);
    }

    this.props.closeFSPopUp();
  }


  showPreviousDataSessionDailog = () =>{
    const matchGroup = matchPath(window.location.pathname, {
      path: "/group/:id/active-session",
    });
    if (matchGroup) {
      const selectedGroup = 
        this.props.groupData ?
          (
            this.props.groupData
            .find((group) => (+group.id === +this.props.selectedDatasession?.datasession?.groupId))
          ) : null;

      this.props.openFSPopUp(
        "STOP_DATASESSION",
        matchGroup.params.id,
        {
          startedAt: timestampToDateTime(+this.props.selectedDatasession?.datasession?.startedAt),
          groupTitle: selectedGroup?.title || "--",
          participantCount: this.props.selectedDatasession?.datasession?.participantCount || '--',
        }
      );
    }
  }

  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-end px-3 pb-3">
          <div className="container bg-light modal-body-container-scrollable1">
            <div className="modal-body-container-internal">
              <div className="row mt-3">
                <div className="col-12">
                  <div className="row ms-3 mt-3">
                    <b className="ps-0">Are you sure? This session will not be stored.</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-end mb-3 me-3">
          <button className="btn btn-light me-3" onClick={() => this.showPreviousDataSessionDailog()}> 
            NO
          </button>
          <button className="btn btn-armor-red me-3" 
            onClick={
              () => {
                this.stopSession();
              }
            }
          >
            <FontAwesomeIcon icon={faStop} />{' '}
           YES
          </button>
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    datasessionId: state.screenActivitiesReducer?.id,
    datasessionMeta: state.screenActivitiesReducer?.metaObj,
    timerId: state.screenActivitiesReducer?.timerId,
    selectedDatasession: state.datasessionReducer?.selectedDatasession,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    closeFSPopUp,
    toggleDataSession,
    openFSPopUp
  })(StopDatasessionWithoutData)
);
