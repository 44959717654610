import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";

import Index from "../index/Index";
import Authorization from "../authorization/Authorization";

import GroupList from "../group/GroupList";
import EditOneGroup from "../edit-group/EditOneGroup";
// import __EditOneGroup from "../edit-group/__EditOneGroup";

import GroupActiveSession from "../active-session/GroupActiveSession";

import MainMenu from "../../components/menu/MainMenu";

import { tryUserAuth } from "../../actions/user";
import { clearRedirect } from "../../actions/redirect";

import bigBg from "../../images/login-img/heavy_physical_activities.png";

import "./style.css";
// import LocationManagement from "../location-management/LocationManagement";
import HistoricalDatasessions from "../historical-datasessions/HistoricalDatasessions";
import HistoryChart from "../chart/HistoryChart";
import Flash from "../../components/alerts/Flash";
import LeftMenu from "../../components/menu/LeftMenu";
import PsiTempSettings from "../../components/settings/PsiTempSettings";
import TopMenu from "../../components/menu/TopMenu";
import SubMenuFirst from "../../components/menu/SubMenuFirst";
import BreadcrumbMenu from "../../components/menu/BreadcrumbMenu";
import ActiveSessions from "../active-session/ActiveSessions";
import GroupActiveSessionList from "../active-session/GroupActiveSessionList";
import LocationManagement from "../location-management/LocationManagement";
import HistoricalDatasessionCardView from "../historical-datasessions/HistoricalDatasessionCardView";
import HistoricalDatasessionListView from "../historical-datasessions/HistoricalDatasessionListView";
import HistoryChartStopped from "../chart/HistoryChartStopped";
import MapParticipant from "../../components/map/MapParticipant";
import GroupActiveSessionMap from "../active-session/GroupActiveSessionMap";

class Main extends Component {
  componentDidMount = () => {
    this.checkLocalUser();
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.redirectTo &&
      this.props.redirectTo !== prevProps.redirectTo
    ) {
      this.props.history.push(this.props.redirectTo);
      this.props.clearRedirect();
    }
  }

  checkLocalUser = () => {
    if (!this.props.user) {
      const user = JSON.parse(localStorage.getItem("userData"));
      if (user) {
        this.props.tryUserAuth(user);
      } else {
        this.props.history.push("/");
      }
    }
  }

  render() {
    if (this.props.user && this.props.armorUser) {
      if (this.props.isAdmin) {
        // Admin routes
        return (
          <div className="container-fluid" style={{minHeight: "100vh"}}>
            <MainMenu />
            <div className="row">
              <div className="container-fluid pt-5">
                <div className="pt-3"></div>
                <Flash />
                <Switch>
                  <Route path="/" exact component={LocationManagement} />
                </Switch>
              </div>
            </div>
          </div>
        );
      } else  {
        // Non-admin logged in user routes
        return (
          <main>
            <div className="docs-wrapper">
              <LeftMenu />
              <div className="right px-3 px-md-5">
                <TopMenu />
                <BreadcrumbMenu />
                <SubMenuFirst />
                <div className="content-card main-content">
                  <div className="apiContent">
                    <Flash />
                    <Switch>
                      <Route path="/historical-datasessions/:page" component={HistoricalDatasessions} />
                      {/* <Route path="/group/:id/add-person" component={GroupActiveSession} /> */}

                      <Route path="/history/:deviceId/chart/:datasessionId/stopped" component={HistoryChartStopped} />
                      <Route path="/history/:deviceId/chart/:datasessionId" component={HistoryChart} />

                      <Route 
                        path="/history/:deviceId/map/:datasessionId/stopped" 
                        render={(props) => (<MapParticipant stopped={true} />)} 
                      >

                      </Route>
                      <Route 
                        path="/history/:deviceId/map/:datasessionId" 
                        render={(props) => (<MapParticipant stopped={false} />)}
                      />

                      <Route path="/group/:id/active-session/list" component={GroupActiveSessionList} />
                      <Route path="/group/:id/active-session/map" component={GroupActiveSessionMap} />

                      <Route path="/group/:id/active-session" component={GroupActiveSession} />

                      <Route path="/datasession/:id/card" component={HistoricalDatasessionCardView} />
                      <Route path="/datasession/:id/list" component={HistoricalDatasessionListView} />

                      <Route path="/group/:id/edit" component={EditOneGroup} />
                      <Route path="/settings" exact component={PsiTempSettings} />
                      <Route path="/groups" exact component={GroupList} />
                      <Route path="/active-sessions" exact component={ActiveSessions} />
                      <Route path="/" exact component={ActiveSessions} />
                    </Switch>
                  </div>
                </div>
              </div>
            </div>
          </main>
        );
      }
    } else {
      // Non User routes
      return (
        <div 
          className="container-fluid index-bg" 
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.7)), url(${bigBg})`,
          }}
        >
          {/* <MainMenu /> */}
          <Switch>
            <Route path="/authorization" component={Authorization} />
            <Route path="/" exact component={Index} />
          </Switch>
        </div>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.userReducer?.user,
    armorUser: state.userReducer?.armorUser,
    isAdmin: state.userReducer?.armorUser?.user?.isAdmin,
    redirectTo: state.redirectReducer.redirectTo,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    tryUserAuth,
    clearRedirect,
  })(Main)
);
