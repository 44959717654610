const initialState = {
  startedDatasession: null,
  selectedDatasession: null,
  histotricalDatasession: null,
  historicalDatasessions: null,
  oneDeviceData: null,
  pollingCounter: 1
};

export default function datasessionReducer(state = initialState, action) {
  switch (action.type) {
    case "DATASESSION_STARTED": {
      return {
        ...state,
        startedDatasession: action.data
      };
    }
    case "DATASESSION_STOPPED": {
      return{
        ...state,
        startedDatasession: null
      };
    }
    case "ACTIVE_DATASESSION_FETCHED": {
      return {
        ...state,
        selectedDatasession: action.data,
        // selectedDatasessionPolled: state.selectedDatasessionPolled + 1
      };
    }
    case "ONE_DATASESSION_FETCHED": {
      return {
        ...state,
        histotricalDatasession: action.data,
      };
    }
    case "ALL_DATASESSIONS_FETCHED": {
      return {
        ...state,
        historicalDatasessions: action.data
      };
    }
    case "ONE_DEVICE_DATA_FETCHED": {
      return {
        ...state,
        oneDeviceData: action.data
      };
    }
    case "ONE_DATASESSION_CLEARED": {
      return {
        ...state,
        selectedDatasession: null
      }
    }
    case "ONE_DEVICE_DATA_CLEARED": {
      return {
        ...state,
        oneDeviceData: null
      }
    }
    case "POLLING_DATASESSION": {
      return {
        ...state,
        pollingCounter: state.pollingCounter + 1
      }
    }
    case "RESET_POLLING_COUNTER": {
      return {
        ...state,
        pollingCounter: 1
      }
    }
    default: {
      return state;
    }
  }
};