import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { closeFSPopUp } from '../../../actions/screen-activities';
import { deleteOneDatasession } from "../../../actions/datasession";

class DeleteDatasession extends Component {
  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-end px-3 pb-3">
          <div className="container bg-light modal-body-container-scrollable">
            <div className="modal-body-container-internal">
              <div className="row mt-3">
                <div className="col-12">
                  <h4 className="ms-3">Are you sure you want to delete datasession data?</h4>
                  <div className="row ms-3 mt-3">
                    <b className="ps-0">Date range</b>
                  </div>
                  <div className="row ms-3 mt-3">
                    {this.props.datasessionMeta?.startedAt} to {this.props.datasessionMeta?.stoppedAt}
                  </div>
                  <div className="row ms-3 mt-3">
                    <b className="ps-0">Group name</b>
                  </div>
                  <div className="row ms-3 mt-3">
                    {this.props.datasessionMeta?.groupTitle}
                  </div>
                  <div className="row ms-3 mt-3">
                    <b className="ps-0">Group size</b>
                  </div>
                  <div className="row ms-3 mt-3">
                    {this.props.datasessionMeta?.participantCount} persons
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="text-end mb-3 me-3">
          <button className="btn btn-light me-3" onClick={this.props.closeFSPopUp}>
            CANCEL
          </button>
          <button className="btn btn-armor-red me-3" 
            onClick={
              () => {
                this.props.deleteOneDatasession(this.props.datasessionMeta?.datasessionId);
                this.props.closeFSPopUp();
              }
            }
          >
            <FontAwesomeIcon icon={faTrash} />{' '}
            DELETE
          </button>
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    datasessionId: state.screenActivitiesReducer?.id,
    datasessionMeta: state.screenActivitiesReducer?.metaObj
  };
}

export default withRouter(
  connect(mapStateToProps, {
    closeFSPopUp,
    deleteOneDatasession
  })(DeleteDatasession)
);
