const userLocale =
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.language;
const dateTimeOptions = { 
  year: 'numeric', 
  month: 'long', 
  day: 'numeric',
  hour: '2-digit', 
  minute:'2-digit',
  hour12: true,
};

const globalFunctions = {
  
  timestampToDateTime: (timestamp) => {
    const dateObj = new Date(+timestamp);
    return dateObj.toLocaleTimeString(userLocale || [], dateTimeOptions);
  },

  averageFromArr: (arr) => {
    return arr.reduce((a,b) => a + b, 0) / arr.length;
  }
}

export default globalFunctions;