import axios from "axios";

import CONFIG from "../config";
import { getUnusedDevices } from "./device";

const {
  armorServerUrl
} = CONFIG;

const DELETE_PARTICIPANT = "DELETE_PARTICIPANT";
const ADD_PARTICIPANT = "ADD_PARTICIPANT";
const EDIT_PARTICIPANT = "EDIT_PARTICIPANT";

const removeParticipantSuccess = (participantId) => ({
  type: DELETE_PARTICIPANT,
  participantId
});

const addNewParticipantSuccess = (data) => ({
  type: ADD_PARTICIPANT,
  data
});

const editParticipantSuccess = (data) => ({
  type: EDIT_PARTICIPANT,
  data
});

export const removeParticipant = (participantId) => (dispatch, getState) => {
  const { token } = getState().userReducer;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .delete(`${armorServerUrl}/participant/${participantId}`)
    .then((res) => {
      if (+res.status === 200) {
        dispatch(
          removeParticipantSuccess(participantId)
        );
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addNewParticipant = (participantData) => (dispatch, getState) => {
  const { token } = getState().userReducer;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .post(`${armorServerUrl}/participant`, {
      ...participantData
    })
    .then((res) => {
        dispatch(
          addNewParticipantSuccess(res.data)
        );
    })
    .catch((err) => {
      console.log(err);
    });
};

export const editParticipant = (participantData) => (dispatch, getState) => {
  const { token } = getState().userReducer;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .put(`${armorServerUrl}/participant/${participantData.id}`, {
      ...participantData
    })
    .then((res) => {
        dispatch(getUnusedDevices(res.data.groupId));
        dispatch(
          editParticipantSuccess(res.data)
        );
    })
    .catch((err) => {
      console.log(err);
    });
};