const initialState = {
  fullScreenPopUp: false,
  activeAction: null,
  id: null,
  timerId: null,
  download: {
    start: false,
    type: null,
  },
};

export default function screenActivitiesReducer(state = initialState, action) {
  switch (action.type) {
    case "OPEN_FULL_SCREEN_POPUP": {
      return {
        ...state,
        fullScreenPopUp: true,
        activeAction: action.action,
        id: action.id,
        metaObj: action?.metaObj,
      };
    }
    case "CLOSE_FULL_SCREEN_POPUP": {
      return {
        ...state,
        fullScreenPopUp: false,
        activeAction: action.action,
      };
    }
    case "TOGGLE_MOBILE_MENU": {
      return {
        ...state,
        mobileMenuOpened: !state.mobileMenuOpened,
      };
    }
    case "OPEN_MOBILE_MENU": {
      return {
        ...state,
        mobileMenuOpened: true,
      };
    }
    case "CLOSE_MOBILE_MENU": {
      return {
        ...state,
        mobileMenuOpened: false,
      };
    }
    case "NEW_POLLING_TIMER": {
      return {
        ...state,
        timerId: action.timerId,
      };
    }
    case "OPEN_USER_POPUP": {
      return {
        ...state,
        topUserPopup: true,
      };
    }
    case "CLOSE_USER_POPUP": {
      return {
        ...state,
        topUserPopup: false,
      };
    }

    case "START_DOWNLOAD": {
      return {
        ...state,
        download: {
          start: true,
          datasessionId: action?.datasessionId,
          type: action.downloadType,
        },
      };
    }
    case "STOP_DOWNLOAD": {
      return {
        ...state,
        download: {
          ...state.download,
          datasessionId: action?.datasessionId,
          start: false,
        },
      };
    }

    default: {
      return state;
    }
  }
}
