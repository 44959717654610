import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import moment from "moment";

import { Line, Chart } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import zoomPlugin from 'chartjs-plugin-zoom';

import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LoaderRoller from '../../components/loader/LoaderRoller';

import { fetchOneDeviceData, clearOneDeviceData } from "../../actions/datasession";
import { setPollingTimer } from "../../actions/screen-activities";

import CONFIG from '../../config';
const { datatypes, POLLING_INTERVAL, colorPalette } = CONFIG;

const LIVE_MODE = "LIVE_MODE";
const ZOOM_MODE = "ZOOM_MODE";

const {
  HEARTRATE_DATATYPE,
  CORE_TEMPERATURE_DATATYPE,
  PSI_DATATYPE
} = datatypes;

const initialState = {
  zoomLevel: 1,
  chartMode: LIVE_MODE,
  measurementAlertPopUp: false
};

const minutes5inMs = 300000;

class HistoryChart extends Component {
  state = initialState;
  lineReference = null;

  componentDidMount = () => {
    Chart.register([ChartDataLabels, zoomPlugin]);
    const { deviceId, datasessionId } = this.props.match.params;
    this.fetchData({ deviceId, datasessionId });
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.oneDeviceData?.data && this.props.oneDeviceData.data.length) {
      // Show alert, if HR data is 1
      const hrDataLast = [...this.props.oneDeviceData.data]
        .reverse()
        .find(datapoint => (datapoint.datatype === HEARTRATE_DATATYPE));

      if (+hrDataLast.value === 1 && !this.state.measurementAlertPopUp) {
        this.setState({
          ...this.state,
          measurementAlertPopUp: true
        });
        this.clearPollingTimer();
      }
      // console.log(hrDataLast);
    }


    if (this.state.chartMode !== prevState.chartMode) {
      if (this.state.chartMode === LIVE_MODE) {
        // Resume Polling with Zoom & Pan
        const { deviceId, datasessionId } = this.props.match.params;
        this.fetchData({ deviceId, datasessionId });
      } else {
        // Cancel Polling
        this.clearPollingTimer();
      }
    }
  }

  componentWillUnmount = () => {
    this.props.clearOneDeviceData();
    this.clearPollingTimer();
  }

  fetchData = ({ deviceId, datasessionId }) => {
    this.props.fetchOneDeviceData({ deviceId, datasessionId });

    this.timer = setInterval(
      () => this.props.fetchOneDeviceData({ deviceId, datasessionId }), 
    POLLING_INTERVAL);

    if (this.timer) {
      this.props.setPollingTimer(this.timer);
    }
  }

  clearPollingTimer = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  // closePopup = () => {
  //   this.setState({
  //     ...this.state,
  //     measurementAlertPopUp: false
  //   })
  // }

  render() {
    if (this.props.oneDeviceData) {
      let deviceData = [];
      let noDataInLast5Minutes = false;
      if (this.state.chartMode === LIVE_MODE) {
        deviceData = this.props.oneDeviceData.data.slice(-60);
      } else {
        deviceData = this.props.oneDeviceData.data;
      }

      const uniqueData = deviceData
        .filter(data => (+data.datatype === HEARTRATE_DATATYPE));
      // console.log(uniqueData);
      const coreTempData = deviceData
        .filter(data => (+data.datatype === CORE_TEMPERATURE_DATATYPE))
        .map(data => ( ((+data.value - 36) * 2) + 5 ));
      const heartrateData = deviceData
        .filter(data => (+data.datatype === HEARTRATE_DATATYPE))
        .map(data => ( (+data.value / 22) + 10 ));
      const psiData = deviceData
        .filter(data => (+data.datatype === PSI_DATATYPE))
        .map(data => (+data.value.toFixed(1)));

      const timestamps = uniqueData.map(data => {
        return moment(+data.timestamp).format("HH:mm");
      });

      if ((timestamps && timestamps.length) && (this.state.chartMode === LIVE_MODE)) {
        noDataInLast5Minutes = ((Date.now() - +uniqueData[uniqueData.length - 1]?.timestamp) > minutes5inMs);
      }

      const data = {
        labels: timestamps,
        datasets: [
          {
            label: "Heart Rate (bpm)",
            data: heartrateData,
            fill: false,
            borderColor: colorPalette?.chartRed,
            backgroundColor: colorPalette?.chartRed,
            tension: 0.1,
            pointRadius: 1

          },
          {
            label: "TCore (°C)",
            data: coreTempData,
            fill: false,
            borderColor: colorPalette?.chartGreen,
            backgroundColor: colorPalette?.chartGreen,
            tension: 0.1,
            pointRadius: 1
          },
          {
            label: "PSI",
            data: psiData,
            fill: false,
            borderColor: colorPalette?.chartOrange,
            backgroundColor: colorPalette?.chartOrange,
            tension: 0.1,
            pointRadius: 1
          }
        ]
      };

      const options = {
        normalized: true,
        layout: {
          padding: {
            top: 30
          }
        },
        plugins: {
          legend: {
            position: 'bottom',
            align: 'start',
            labels: {
              boxHeight: 0
            }
          },
          tooltip: {
            enabled: true,
            intersect: false,
            interaction: {
              mode: 'index'
            },
            callbacks: {
              label: function(context) {
                let value = context?.raw;
                if (context?.dataset?.label === "Heart Rate (bpm)") {
                  value = ((value - 10) * 22).toFixed(0);
                } else if (context?.dataset?.label === "TCore (°C)") {
                  value = (((value - 5) / 2) + 36).toFixed(1);
                } else {
                  value = value.toFixed(0);
                }
                return value;
              }
            }
          },

          datalabels: {
            align: 'top',
            formatter: function(value, context) {
              if (context.datasetIndex === 0) {
                // Heartrate
                return ((value - 10) * 22).toFixed(0);
              } else if (context.datasetIndex === 1) {
                // Core temperature
                return (((value - 5) / 2) + 36).toFixed(1);
              } else if (context.datasetIndex === 2) {
                // PSI
                return value.toFixed(0);
              }
              return value;
            }
          },
          zoom: {
            pan: {
              enabled: true,
              mode: 'x',
            },
            zoom: {
              wheel: {
                enabled: true,
              },
              pinch: {
                enabled: true
              },
              mode: 'x',
              // onZoomComplete({chart}) {
              //   console.log("zoomed in");
              //   chart.update('none');
              // }
            },
          }
        },
        scales: {
          yAxes: {
            ticks: {
              display: false,
            }
          },
          xAxes: {
            title: {
              display: true,
              align: 'end',
              text: 'Time',
              font: {
                weight: 'bolder',
                size: 16 
              }
            }
          }
        }
      };

      return (
        <Fragment>
          <div className="row">
            <div className="armor-card col-12">
              <div className="row">
                <div className="col-12">
                  {
                    this.state.measurementAlertPopUp ? (
                      <div className="row bg-armor-red p-3">
                        <div className="col-1 my-2">
                          <FontAwesomeIcon icon={faExclamationTriangle} />
                        </div>
                        <div className="col-10 my-2">
                          <b>Invalid data.</b> <i>HR data is invalid. “Measurement ended for this participant.”</i>
                        </div>
                      </div>
                  ) : (
                      ''
                    )
                  }
                  {
                    noDataInLast5Minutes ? (
                      <div className="row bg-armor-red p-3">
                        <div className="col-1">
                          <FontAwesomeIcon icon={faExclamationTriangle} />
                        </div>
                        <div className="col-10">
                          <b>No data has been received for more than 5 minutes.</b> <i>Values may be inaccurate within 20 minutes after gap in measurement.</i>
                        </div>
                      </div>
                    ) : (
                      ''
                    )
                  }
                  <div className="row">
                  <div className="col-12">
                      <div className="row">
                        <div className="col-6 d-flex align-items-center">
                          <h4>
                            <b>
                              {this.props.oneDeviceData?.datasessionMeta[0]?.participantName}
                            </b>
                          </h4>
                        </div>
                        <div className="col-6 text-end py-3">
                          <button 
                            className={`btn ${this.state.chartMode === LIVE_MODE ? ('btn-armor-red') : ('btn-outline-dark-green')}`}
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                chartMode: LIVE_MODE
                              })
                            }}
                          >
                            Live
                          </button>
                          <button 
                            className={`btn ms-1 ${this.state.chartMode === ZOOM_MODE ? ('btn-armor-red') : ('btn-outline-dark-green')}`}
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                chartMode: ZOOM_MODE
                              })
                            }}
                          >
                            Zoom & Pan
                          </button>
                        </div>
                      </div>
                      <Line
                        id="chartJs"
                        options={options}
                        data={data}
                        ref={(reference) => this.lineReference = reference}
                      />
                    </div>
                  </div>
               </div>
              </div>
            </div>
          </div>
        </Fragment>
      )
    } else {
      return (
        <LoaderRoller />
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    oneDeviceData: state.datasessionReducer.oneDeviceData
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchOneDeviceData,
    clearOneDeviceData,
    setPollingTimer
  })(HistoryChart)
);