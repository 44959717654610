import { connect } from 'react-redux';
import { withRouter } from "react-router";

import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import {
  openUserPopup,
  closeUserPopup,
} from "../../actions/screen-activities";

import UserPopup from './UserPopup';
import FullScreenModal from '../modals/FullScreenModal';

class TopMenu extends Component {

  render() {
    return (
      <div className="row top-menu py-3">
        <div className="container-fluid text-end">
          <button
            className="btn"
            onClick={
              this.props.topUserPopup
                ? this.props.closeUserPopup
                : this.props.openUserPopup
            }
          >
            <FontAwesomeIcon icon={faUser} size="1x" className="mx-1" /> 
          </button>
        </div>
        <UserPopup />

        {
          this.props.fullScreenPopUp ? (
            <FullScreenModal />
          ) : (
            <div></div>
          )
        }
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    userEmail: state.userReducer?.user?.email,
    topUserPopup: state.screenActivitiesReducer?.topUserPopup,
    fullScreenPopUp: state.screenActivitiesReducer?.fullScreenPopUp,
  };
}

export default withRouter(connect(mapStateToProps, { 
  openUserPopup,
  closeUserPopup,
})(TopMenu));