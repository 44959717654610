import React, { Component, Fragment } from "react";
import GoogleMapReact from "google-map-react";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import "./style.css";
import CONFIG from "../../config";
const { MAPS_JS_API_KEY } = CONFIG;

const closestMeasurement = (arr, locationTimestamp) =>
  arr.reduce((a, b) => {
    return Math.abs(+b.timestamp - +locationTimestamp) <
      Math.abs(+a.timestamp - +locationTimestamp)
      ? b
      : a;
  });

const Marker = ({
  timestamp,
  coreTempData,
  userSettings,
  psiData,
  lastLocation,
}) => {
  const temp = closestMeasurement(coreTempData, timestamp).value.toFixed(1);
  const psi = closestMeasurement(psiData, timestamp).value.toFixed(0);

  let warningReached,
    dangerReached = false;
  if (userSettings.assesmentPsiTemp === "PSI") {
    warningReached = psi > +userSettings.warningThreshold ? true : false;
    dangerReached = psi > +userSettings.dangerThreshold ? true : false;
  } else {
    warningReached = temp > +userSettings.tempWarningThreshold ? true : false;
    dangerReached = temp > +userSettings.tempDangerThreshold ? true : false;
  }

  const styleForPin = dangerReached
    ? "bg-traffic-red"
    : warningReached
    ? "bg-traffic-yellow"
    : "bg-traffic-green";
  return (
    <Fragment>
      <div
        className={`marker ${styleForPin}`}
        style={{ cursor: "pointer" }}
        title={"Name"}
      >
        <div>{` ${temp} °C`}</div>
      </div>
      {lastLocation ? <div className="pulse"></div> : ""}
      {/* <div className={`${styleForPin}`}>
       <FontAwesomeIcon icon={faMapMarkerAlt} size="2x" /><b>{` ${temp} °C`}</b>
     </div> */}
    </Fragment>
  );
};

class SimpleMap extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 13,
    mapTypeId: "satellite",
  };

  handleGoogleMapApi = (google, path) => {
    const lineSymbol = {
      path: "M 0,-1 0,1",
      strokeOpacity: 1,
      scale: 3,
    };

    const flightPath = new google.maps.Polyline({
      path,
      geodesic: true,
      strokeColor: "#989898",
      strokeOpacity: 0,
      icons: [
        {
          icon: lineSymbol,
          offset: "0",
          repeat: "20px",
        },
      ],
    });

    flightPath.setMap(google.map);
  };

  getMapOptions = (maps) => {
    return {
      mapTypeId: maps.MapTypeId.ROADMAP,
      styles: [
        {
          featureType: "all",
          elementType: "labels",
          stylers: [
            {
              visibility: "on",
            },
          ],
        },
      ],

      mapTypeControl: true,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.TOP_LEFT,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.HYBRID,
          maps.MapTypeId.TERRAIN,
          maps.MapTypeId.SATELLITE,
        ],
        labels: true,
      },
    };
  };

  render() {
    const pathCoordinates = this.props.locationData.map((data) => ({
      lat: data.arrayValue[0],
      lng: data.arrayValue[1],
    }));
    // console.log(pathCoordinates);
    return (
      <div style={{ height: "60vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: MAPS_JS_API_KEY }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          onGoogleApiLoaded={(g) => {
            if (pathCoordinates && pathCoordinates.length > 1) {
              // This will draw line between Markers
              return this.handleGoogleMapApi(g, pathCoordinates);
            }
            return null;
          }}
          //This will display satellite and Terrain options in the map
          options={this.getMapOptions}
        >
          {this.props.locationData.map((loc, i) => {
            return (
              <Marker
                key={i}
                timestamp={+loc.timestamp}
                lat={loc.arrayValue[0]}
                lng={loc.arrayValue[1]}
                coreTempData={this.props.coreTempData}
                psiData={this.props.psiData}
                userSettings={this.props.userSettings}
                lastLocation={this.props.locationData.length === i + 1}
              />
            );
          })}
        </GoogleMapReact>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userSettings: state.userReducer.armorUser.settings,
  };
}

export default withRouter(connect(mapStateToProps, {})(SimpleMap));
