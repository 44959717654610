import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';
import moment from "moment";

import { faCircle, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CONFIG from '../../config';

const { datatypes, colorPalette } = CONFIG;
const {
  HEARTRATE_DATATYPE,
  CORE_TEMPERATURE_DATATYPE,
  PSI_DATATYPE
} = datatypes;

const oneMinuteInMs = 60000;
const minutes5inMs = 300000;

class SessionLine extends Component {
  render() {
    const heartrateData = this.props.selectedDatasession.latestData.find(data => (
      +data.deviceId === +this.props.meta.deviceId && +data.datatype === HEARTRATE_DATATYPE
    ));
    // console.log("Heartrate: ", heartrateData);
    // console.log("Heartrate: ", heartrateData?.deviceId, new Date(+heartrateData?.timestamp));
    const tempData = this.props.selectedDatasession.latestData.find(data => (
      +data.deviceId === +this.props.meta.deviceId && +data.datatype === CORE_TEMPERATURE_DATATYPE
    ));
    // console.log("tempData: ", tempData?.deviceId, new Date(+tempData?.timestamp));

    const psiLatest = this.props.selectedDatasession.latestData.find(data => (
      +data.deviceId === +this.props.meta.deviceId && +data.datatype === PSI_DATATYPE
    ));
    const highestPsi = this.props.selectedDatasession.maxPsi.find(data => (
      +data.deviceId === +this.props.meta.deviceId && +data.datatype === PSI_DATATYPE
    ));
    const tooOldData = (Date.now() - +tempData?.timestamp > (oneMinuteInMs * 2));
    const dataMissingLast5Minutes = (Date.now() - +tempData?.timestamp > minutes5inMs);
    const maxPsiAndLatestPsiDifferent = (+highestPsi?.value > +psiLatest?.value);

    const warningReached = +psiLatest?.value > +this.props.userSettings.warningThreshold ? true : false;;
    const dangerReached = +psiLatest?.value > +this.props.userSettings.dangerThreshold ? true : false;;
    const tempWarningReached = +tempData?.value > +this.props.userSettings.tempWarningThreshold ? true : false;
    const tempDangerReached = +tempData?.value > +this.props.userSettings.tempDangerThreshold ? true : false;

    // console.log(this.props.meta);
    if (tempData && psiLatest) {
      if (this.props.userSettings.assesmentPsiTemp === "PSI") {
        if ((heartrateData && +heartrateData.value === 1) || dataMissingLast5Minutes) {
          // Data corrupt PSI line
          return (
            <Fragment>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>{this.props.meta.participantName}</td>
                <td>-- °C</td>
                <td>-- </td>
                <td>-- bpm</td>
                <td>
                  <Link
                    to={`/history/${+this.props.meta.deviceId}/chart/${+this.props.meta.datasessionId}`} 
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </Link>
                </td>
              </tr>
            </Fragment>                
          )
        }

        // PSI line - valid data
        return (
          <Fragment>
            <tr>
              <td>
                {
                  (dangerReached) ? (
                    <Fragment>
                      <FontAwesomeIcon icon={faCircle} color={colorPalette.cardRed} />{' '}
                      Critical
                    </Fragment>
                  ) : (
                    warningReached ? (
                      <Fragment>
                        <FontAwesomeIcon icon={faCircle} color={colorPalette.cardOrange} />{' '}
                        Warning
                      </Fragment>  
                    ) : (
                      <Fragment>
                        <FontAwesomeIcon icon={faCircle} color={colorPalette.cardGreen} />{' '}
                        OK
                      </Fragment>
                    )
                  )
                }
              </td>
              <td>
                {
                  (dangerReached) ? (
                    "High temperature"
                  ) : (
                    warningReached ? (
                      "Medium temperature"
                    ) : (
                      "--"
                    )
                  )
                }
              </td>
              <td>
                {tooOldData ? ( moment(+tempData?.timestamp).fromNow() ) : ("OK")}
              </td>
              <td>{this.props.meta.participantName}</td>
              <td>{tempData?.value.toFixed(1)} °C</td>
              <td>{psiLatest?.value} {maxPsiAndLatestPsiDifferent ? (`[${highestPsi?.value}]`) : ('')}</td>
              <td>{heartrateData?.value.toFixed(0)} bpm</td>
              <td>
                <Link
                  className="text-armor-red"
                  to={`/history/${+this.props.meta.deviceId}/chart/${+this.props.meta.datasessionId}`} 
                >
                  <FontAwesomeIcon icon={faEye} />
                </Link>
              </td>
            </tr>
          </Fragment>   
        )
      } else {
        if ((heartrateData && +heartrateData.value === 1) || dataMissingLast5Minutes) {
          // Data corrupt Temperature line
          return (
            <Fragment>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>{this.props.meta.participantName}</td>
                <td>-- °C</td>
                <td>-- </td>
                <td>-- bpm</td>
                <td>
                  <Link
                    to={`/history/${+this.props.meta.deviceId}/chart/${+this.props.meta.datasessionId}`} 
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </Link>
                </td>
              </tr>
            </Fragment>                
          )
        }

        // Temperature line - valid data
        return (
          <Fragment>
            <tr>
              <td>
                {
                  (tempDangerReached) ? (
                    <Fragment>
                      <FontAwesomeIcon icon={faCircle} color={colorPalette.cardRed} />{' '}
                      Critical
                    </Fragment>
                  ) : (
                    tempWarningReached ? (
                      <Fragment>
                        <FontAwesomeIcon icon={faCircle} color={colorPalette.cardOrange} />{' '}
                        Warning
                      </Fragment>  
                    ) : (
                      <Fragment>
                        <FontAwesomeIcon icon={faCircle} color={colorPalette.cardGreen} />{' '}
                        OK
                      </Fragment>
                    )
                  )
                }
              </td>
              <td>
                {
                  (tempDangerReached) ? (
                    "High temperature"
                  ) : (
                    tempWarningReached ? (
                      "Medium temperature"
                    ) : (
                      "--"
                    )
                  )
                }
              </td>
              <td>
                {tooOldData ? ( moment(+tempData?.timestamp).fromNow() ) : ("OK")}
              </td>
              <td>{this.props.meta.participantName}</td>
              <td>{tempData?.value.toFixed(1)} °C</td>
              <td>{psiLatest?.value} {maxPsiAndLatestPsiDifferent ? (`[${highestPsi?.value}]`) : ('')}</td>
              <td>{heartrateData?.value.toFixed(0)} bpm</td>
              <td>
                <Link
                  className="text-armor-red"
                  to={`/history/${+this.props.meta.deviceId}/chart/${+this.props.meta.datasessionId}`} 
                >
                  <FontAwesomeIcon icon={faEye} />
                </Link>
              </td>
            </tr>
          </Fragment>   
        )
      }
    } else {
      return (
        <Fragment>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td>{this.props.meta.participantName}</td>
            <td>-- °C</td>
            <td>--</td>
            <td>-- bpm</td>
            <td>
              <FontAwesomeIcon icon={faEye} className="color-armor-red" />
            </td>
          </tr>
        </Fragment>                
      )
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    selectedDatasession: state.datasessionReducer?.selectedDatasession,
    userSettings: state.userReducer.armorUser.settings
  };
}

export default withRouter(
  connect(mapStateToProps, {
  })(SessionLine)
);