import axios from "axios";

import CONFIG from "../config";
import { alertPrimary, alertWarning } from "./alert";

const {
  armorServerUrl
} = CONFIG;

const USER_SETTINGS_CHANGED = "USER_SETTINGS_CHANGED";

const resetUserSettingsSuccess = (settings) => ({
  type: USER_SETTINGS_CHANGED,
  settings,
});

const saveUserSettingsSuccess = (settings) => ({
  type: USER_SETTINGS_CHANGED,
  settings,
});

export const resetUserSettings = () => (dispatch, getState) => {
  const { token } = getState().userReducer;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .put(`${armorServerUrl}/settings/default`)
    .then((res) => {
      dispatch(alertPrimary("Thresholds reset to default and saved."));
      dispatch(
        resetUserSettingsSuccess(res.data)
      );
    })
    .catch((err) => {
      console.log(err);
    });
};

export const saveUserSettings = (settings) => (dispatch, getState) => {
  const { token } = getState().userReducer;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .put(`${armorServerUrl}/settings`, {...settings})
    .then((res) => {
      const { armorUser } = getState().userReducer;
      if (settings && settings?.tileSortType !== armorUser?.settings?.tileSortType) {
        dispatch(alertPrimary("New sorting rule applied."));
      } else {
        dispatch(alertPrimary("New thresholds saved."));
      }
      // console.log(state);
      // console.log(res);
      dispatch(
        saveUserSettingsSuccess(res.data)
      );
    })
    .catch((err) => {
      console.log(err);
      dispatch(alertWarning(err?.response?.data?.message || err?.message));
    });
};