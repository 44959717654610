import { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router";

const LOG_OUT = "LOG_OUT";
const INITIAL_CONFIGURATION = "INITIAL_CONFIGURATION";
const NEW_GROUP = "NEW_GROUP";
const ADD_PERSON = "ADD_PERSON";
const EDIT_GROUP = "EDIT_GROUP";
const EDIT_PARTICIPANT = "EDIT_PARTICIPANT";
const ADD_NEW_DEVICE = "ADD_NEW_DEVICE";
const CHANGE_DEVICE_LOCATION = "CHANGE_DEVICE_LOCATION";
// const DELETE_GROUP = "DELETE_GROUP";
const ARCHIVE_GROUP = "ARCHIVE_GROUP";
const SAVE_DATASESSION = "SAVE_DATASESSION";
const DELETE_ONE_DATASESSION = "DELETE_ONE_DATASESSION";
const STOP_DATASESSION = "STOP_DATASESSION";
const STOP_DATASESSION_WITHOUT_DATA = "STOP_DATASESSION_WITHOUT_DATA";

class ModalTitle extends Component {
  render() {
    if (this.props.modalAction) {
      if (this.props.modalAction === LOG_OUT) {
        return "Log Out";
      } else if (this.props.modalAction === INITIAL_CONFIGURATION) {
        return "User Settings";
      } else if (this.props.modalAction === NEW_GROUP) {
        return "Create Group";
      } else if (this.props.modalAction === ADD_PERSON) {
        return "Add Person";
      } else if (this.props.modalAction === EDIT_GROUP) {
        return "Edit Group";
      } else if (this.props.modalAction === EDIT_PARTICIPANT) {
        return "Edit Person";
      } else if (this.props.modalAction === ADD_NEW_DEVICE) {
        return "Add New Device";
      } else if (this.props.modalAction === CHANGE_DEVICE_LOCATION) {
        return "Change Device Location";
        // } else if (this.props.modalAction === DELETE_GROUP) {
        //   return (
        //     "Delete Group"
        //   )
      } else if (this.props.modalAction === ARCHIVE_GROUP) {
        return "Delete Group";
      } else if (this.props.modalAction === SAVE_DATASESSION) {
        return "Save Session";
      } else if (this.props.modalAction === DELETE_ONE_DATASESSION) {
        return "Delete Datasession";
      } else if (this.props.modalAction === STOP_DATASESSION) {
        return "Stop Session";
      } else if (this.props.modalAction === STOP_DATASESSION_WITHOUT_DATA) {
        return "Stop Session";
      } else {
        return "Please configure Modal Title";
      }
    } else {
      return "Please configure Modal Title";
    }
  }
}

function mapStateToProps(state) {
  return {
    modalAction: state.screenActivitiesReducer?.activeAction,
  };
}

export default withRouter(connect(mapStateToProps, {})(ModalTitle));
