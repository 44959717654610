const OPEN_FULL_SCREEN_POPUP = "OPEN_FULL_SCREEN_POPUP";
const CLOSE_FULL_SCREEN_POPUP = "CLOSE_FULL_SCREEN_POPUP";
const OPEN_MOBILE_MENU = "OPEN_MOBILE_MENU";
const CLOSE_MOBILE_MENU = "CLOSE_MOBILE_MENU";
const TOGGLE_MOBILE_MENU = "TOGGLE_MOBILE_MENU";
const NEW_POLLING_TIMER = "NEW_POLLING_TIMER";
const OPEN_USER_POPUP = "OPEN_USER_POPUP";
const CLOSE_USER_POPUP = "CLOSE_USER_POPUP";
const START_DOWNLOAD = "START_DOWNLOAD";
const STOP_DOWNLOAD = "STOP_DOWNLOAD";

const openFSPopUpSuccess = (action, id, metaObj) => ({
  type: OPEN_FULL_SCREEN_POPUP,
  action, id, metaObj
});

const closeFSPopUpSuccess = () => ({
  type: CLOSE_FULL_SCREEN_POPUP,
});

const openMobileMenuSuccess = () => ({
  type: OPEN_MOBILE_MENU,
});

const closeMobileMenuSuccess = () => ({
  type: CLOSE_MOBILE_MENU,
});

const toggleMobileMenuSuccess = () => ({
  type: TOGGLE_MOBILE_MENU,
});

const setPollingTimerSuccess = (timerId) => ({
  type: NEW_POLLING_TIMER,
  timerId
});

const openUserPopupSuccess = () => ({
  type: OPEN_USER_POPUP,
});

const closeUserPopupSuccess = () => ({
  type: CLOSE_USER_POPUP,
});

const startDownloadSuccess = (type, datasessionId) => ({
  type: START_DOWNLOAD,
  downloadType: type,
  datasessionId
});

const stopDownloadSuccess = () => ({
  type: STOP_DOWNLOAD,
});

export const openFSPopUp = (action, id, metaObj) => (dispatch) => {
  if (id && metaObj) {
    return dispatch(openFSPopUpSuccess(action, id, metaObj));
  }
  if (id) {
    return dispatch(openFSPopUpSuccess(action, id));
  }
  return dispatch(openFSPopUpSuccess(action));
};

export const closeFSPopUp = () => (dispatch) => {
  dispatch(closeFSPopUpSuccess());
};

export const openMobileMenu = () => (dispatch) => {
  dispatch(openMobileMenuSuccess());
};

export const closeMobileMenu = () => (dispatch) => {
  dispatch(closeMobileMenuSuccess());
};

export const toggleMobileMenu = () => (dispatch) => {
  dispatch(toggleMobileMenuSuccess());
};

export const setPollingTimer = (timerId) => (dispatch) => {
  dispatch(setPollingTimerSuccess(timerId));
};

export const openUserPopup = () => (dispatch) => {
  dispatch(openUserPopupSuccess());
};

export const closeUserPopup = () => (dispatch) => {
  dispatch(closeUserPopupSuccess());
};

export const startDownload = (type, datasessionId) => (dispatch) => {
  dispatch(startDownloadSuccess(type, datasessionId));
};

export const stopDownload = () => (dispatch) => {
  dispatch(stopDownloadSuccess());
};