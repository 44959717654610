import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter, matchPath } from "react-router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faList,
  faPlay,
  faRunning,
  faSearchLocation,
  faSquare,
  faStop,
  faThermometerFull,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";

import { RiLayoutMasonryFill } from "react-icons/ri";
import { FaListUl } from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";
import {
  toggleDataSession,
  fetchOneDatasession,
} from "../../actions/datasession";
import { fetchAllGroups } from "../../actions/group";
import { openFSPopUp } from "../../actions/screen-activities";
import { saveUserSettings } from "../../actions/settings";
import DownloadBtn from "../download/DownloadBtn";

import globalFunctions from "../../helpers/globalFunctions";

import CONFIG from "../../config";

const { MOBILE_SCREEN_RESOLUTION } = CONFIG;
const { timestampToDateTime } = globalFunctions;

const initialState = {
  btnClicked: false,
};

class SubMenuFirst extends Component {
  state = initialState;

  componentDidMount = () => {
    const pathname = this.props.location.pathname;
    if (!this.props.selectedDatasession && pathname.endsWith("/stopped")) {
      const matchChartPath = matchPath(window.location.pathname, {
        path: "/history/:deviceId/chart/:datasessionId/stopped",
      });
      if (!this.props.groupData || this.props.groupData.length === 0) {
        this.props.fetchAllGroups();
      }
      if (matchChartPath) {
        this.props.fetchOneDatasession(matchChartPath.params.datasessionId);
      }
    }
  };

  componentDidUpdate = (prevProps) => {
    const pathname = this.props.location.pathname;
    if (pathname !== prevProps.location.pathname) {
      //  Route change
      if (!pathname.endsWith("/active-session") && this.state.btnClicked) {
        this.setState({ ...initialState });
      }
    }
  };

  stopSession = () => {
    const matchGroup = matchPath(window.location.pathname, {
      path: "/group/:id/active-session",
    });
    if (matchGroup) {
      const selectedGroup = this.props.groupData
        ? this.props.groupData.find(
            (group) =>
              +group.id ===
              +this.props.selectedDatasession?.datasession?.groupId
          )
        : null;

      this.props.openFSPopUp("STOP_DATASESSION", matchGroup.params.id, {
        startedAt: timestampToDateTime(
          +this.props.selectedDatasession?.datasession?.startedAt
        ),
        groupTitle: selectedGroup?.title || "--",
        participantCount:
          this.props.selectedDatasession?.datasession?.participantCount || "--",
      });
    }
  };

  startDatasession = () => {
    const matchGroupEdit = matchPath(window.location.pathname, {
      path: "/group/:id/edit",
    });
    const groupId = matchGroupEdit?.params?.id;
    this.props.toggleDataSession(groupId);
  };

  render() {
    const pathname = this.props.location.pathname;
    const tooSmallScreen = window.innerWidth < MOBILE_SCREEN_RESOLUTION;

    if (pathname === "/groups") {
      return (
        <div className="row top-menu py-3">
          <div className="col-4 text-armor-red ">
            <span className="armor-active-link-border pb-2 px-3">
              <FontAwesomeIcon icon={faList} className="mx-2" />
              {tooSmallScreen ? "" : <b>List</b>}
            </span>
          </div>
          <div className="col-8 d-flex justify-content-end">
            <div className="d-flex align-items-center me-2">
              {this.props.groupData?.length} results
            </div>
            <button
              className="btn btn-light"
              onClick={() => this.props.openFSPopUp("NEW_GROUP")}
            >
              <FontAwesomeIcon
                icon={faUserPlus}
                className="me-2 text-armor-red"
              />
              <span className="d-none d-lg-inline">Add group</span>
            </button>
            {/* <button className="btn btn-disabled">
              <FontAwesomeIcon icon={faTrash} className="me-2 text-armor-red" />
              <span className="d-none d-lg-inline">Delete selected</span>
            </button> */}
          </div>
        </div>
      );
    } else if (pathname.endsWith("/stopped") && pathname.includes("/chart/")) {
      const matchChartPath = matchPath(window.location.pathname, {
        path: "/history/:deviceId/chart/:datasessionId/stopped",
      });
      const { datasessionId, deviceId } = matchChartPath.params;
      return (
        <div className="row top-menu py-3">
          <div className="col-6 col-md-6 text-armor-red ">
            <Link
              className="text-armor-red"
              to={`/history/${deviceId}/chart/${datasessionId}/stopped`}
            >
              <span className="armor-active-link-border pb-2 px-3">
                <FontAwesomeIcon icon={faChartLine} className="mx-2" />
                {tooSmallScreen ? "" : <b>Graph</b>}
              </span>
            </Link>
            <Link
              className="text-armor-red"
              to={`/history/${deviceId}/map/${datasessionId}/stopped`}
            >
              <span className="armor-link-border pb-2 px-3">
                <FontAwesomeIcon icon={faSearchLocation} className="mx-2" />
                <b>Map</b>
              </span>
            </Link>
          </div>
        </div>
      );
    } else if (pathname.endsWith("/stopped") && pathname.includes("/map/")) {
      const matchChartPath = matchPath(window.location.pathname, {
        path: "/history/:deviceId/map/:datasessionId/stopped",
      });
      const { datasessionId, deviceId } = matchChartPath.params;
      return (
        <div className="row top-menu py-3">
          <div className="col-6 col-md-6 text-armor-red">
            <Link
              className="text-armor-red"
              to={`/history/${deviceId}/chart/${datasessionId}/stopped`}
            >
              <span className="armor-link-border pb-2 px-3">
                <FontAwesomeIcon icon={faChartLine} className="mx-2" />
                {tooSmallScreen ? "" : <b>Graph</b>}
              </span>
            </Link>
            <Link
              className="text-armor-red"
              to={`/history/${deviceId}/map/${datasessionId}/stopped`}
            >
              <span className="armor-active-link-border pb-2 px-3">
                <FontAwesomeIcon icon={faSearchLocation} className="mx-2" />
                <b>Map</b>
              </span>
            </Link>
          </div>
        </div>
      );
    } else if (
      !pathname.endsWith("/stopped") &&
      pathname.startsWith("/history")
    ) {
      const matchChartPath = matchPath(window.location.pathname, {
        path: "/history/:deviceId/chart/:datasessionId",
      });
      const matchMapPath = matchPath(window.location.pathname, {
        path: "/history/:deviceId/map/:datasessionId",
      });
      let datasessionId,
        deviceId = null;
      if (matchChartPath) {
        datasessionId = matchChartPath.params.datasessionId;
        deviceId = matchChartPath.params.deviceId;
      } else {
        datasessionId = matchMapPath.params.datasessionId;
        deviceId = matchMapPath.params.deviceId;
      }
      return (
        <div className="row top-menu py-3">
          {pathname.includes("chart") ? (
            <div className="col-6 col-md-6 text-armor-red ">
              <Link
                className="text-armor-red"
                to={`/history/${deviceId}/chart/${datasessionId}`}
              >
                <span className="armor-active-link-border pb-2 px-3">
                  <FontAwesomeIcon icon={faChartLine} className="mx-2" />
                  {tooSmallScreen ? "" : <b>Graph</b>}
                </span>
              </Link>
              <Link
                className="text-armor-red"
                to={`/history/${deviceId}/map/${datasessionId}`}
              >
                <span className="armor-link-border pb-2 px-3">
                  <FontAwesomeIcon icon={faSearchLocation} className="mx-2" />
                  {tooSmallScreen ? "" : <b>Map</b>}
                </span>
              </Link>
            </div>
          ) : (
            <div className="col-6 col-md-6 text-armor-red ">
              <Link
                className="text-armor-red"
                to={`/history/${deviceId}/chart/${datasessionId}`}
              >
                <span className="armor-link-border pb-2 px-3">
                  <FontAwesomeIcon icon={faChartLine} className="mx-2" />
                  {tooSmallScreen ? "" : <b>Graph</b>}
                </span>
              </Link>
              <Link
                className="text-armor-red"
                to={`/history/${deviceId}/map/${datasessionId}`}
              >
                <span className="armor-active-link-border pb-2 px-3">
                  <FontAwesomeIcon icon={faSearchLocation} className="mx-2" />
                  {tooSmallScreen ? "" : <b>Map</b>}
                </span>
              </Link>
            </div>
          )}

          <div className="col-6 col-md-6 d-flex justify-content-end">
            {this.props.selectedDatasession?.datasession &&
            this.props.groupData ? (
              <DownloadBtn
                datasession={this.props.selectedDatasession?.datasession}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else if (
      pathname.startsWith("/datasession") &&
      (pathname.endsWith("/card") || pathname.endsWith("/list"))
    ) {
      // console.log(this.props.selectedDatasession?.datasession);
      // console.log(this.props.groupData);
      // const selectedGroup =
      //   this.props.groupData ?
      //     (
      //       this.props.groupData
      //       .find((group) => (+group.id === +this.props.selectedDatasession?.datasession?.groupId))
      //     ) : null;

      return (
        <div className="row top-menu py-3">
          {pathname.endsWith("/card") ? (
            <div className="col-6 col-md-6 text-armor-red ">
              <Link
                className="text-armor-red"
                to={`/datasession/${this.props.selectedDatasession?.datasession?.id}/card`}
              >
                <span className="armor-active-link-border pb-2 px-3">
                  <RiLayoutMasonryFill className="mx-2" />
                  {tooSmallScreen ? "" : <b>Card</b>}
                </span>
              </Link>
              {tooSmallScreen ? (
                ""
              ) : (
                <Link
                  className="text-armor-red"
                  to={`/datasession/${this.props.selectedDatasession?.datasession?.id}/list`}
                >
                  <span className="armor-link-border pb-2 px-3">
                    <FaListUl className="mx-2" />
                    <b>List</b>
                  </span>
                </Link>
              )}
            </div>
          ) : (
            <div className="col-6 col-md-6 text-armor-red ">
              <Link
                className="text-armor-red"
                to={`/datasession/${this.props.selectedDatasession?.datasession?.id}/card`}
              >
                <span className="armor-link-border pb-2 px-3">
                  <RiLayoutMasonryFill className="mx-2" />
                  {tooSmallScreen ? "" : <b>Card</b>}
                </span>
              </Link>
              {tooSmallScreen ? (
                ""
              ) : (
                <Link
                  className="text-armor-red"
                  to={`/datasession/${this.props.selectedDatasession?.datasession?.id}/list`}
                >
                  <span className="armor-active-link-border pb-2 px-3">
                    <FaListUl className="mx-2" />
                    <b>List</b>
                  </span>
                </Link>
              )}
            </div>
          )}

          <div className="col-6 col-md-6 d-flex justify-content-end">
            <div className="d-flex align-items-center me-2">
              {this.props.selectedDatasession?.datasession?.participantCount}{" "}
              results
            </div>
            {this.props?.armorUser?.settings?.tileSortType === "COLOR" ? (
              <button
                className="btn btn-light me-2"
                onClick={() => {
                  this.props.saveUserSettings({
                    tileSortType: "NAME",
                  });
                }}
                title="Sort By Name"
              >
                <FontAwesomeIcon icon={faUser} className="text-armor-red" />
              </button>
            ) : (
              <button
                className="btn btn-light me-2"
                onClick={() => {
                  this.props.saveUserSettings({
                    tileSortType: "COLOR",
                  });
                }}
                title="Sort by Color"
              >
                <FontAwesomeIcon
                  icon={faThermometerFull}
                  className="text-armor-red"
                />
              </button>
            )}
            {this.props.selectedDatasession?.datasession ? (
              <DownloadBtn
                datasession={this.props.selectedDatasession?.datasession}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      );
    } else if (pathname.startsWith("/historical-datasessions")) {
      // console.log(this.props.pagination);
      return (
        <div className="row top-menu py-3">
          <div className="col-4 text-armor-red ">
            <span className="armor-active-link-border pb-2 px-3">
              <FontAwesomeIcon icon={faList} className="mx-2" />
              {tooSmallScreen ? "" : <b>List</b>}
            </span>
          </div>
          <div className="col-8 d-flex justify-content-end">
            <div className="d-flex align-items-center me-2">
              {this.props.pagination?.recordCount} results
            </div>
          </div>
        </div>
      );
    } else if (pathname.startsWith("/group") && pathname.endsWith("/edit")) {
      const activeDatasession = this.props.selectedGroup?.datasessions
        ? this.props.selectedGroup.datasessions.find(
            (datasession) => !datasession.stoppedAt
          )
        : null;
      return (
        <div className="row top-menu py-3">
          <div className="col-4 text-armor-red ">
            <span className="armor-active-link-border pb-2 px-3">
              <FontAwesomeIcon icon={faList} className="mx-2" />
              {tooSmallScreen ? "" : <b>List</b>}
            </span>
          </div>
          <div className="col-8 d-flex justify-content-end">
            <div className="d-flex align-items-center me-2">
              {this.props.groupData?.participants?.length} results
            </div>
            {activeDatasession ? (
              <Link
                to={`/group/${this.props?.match?.params?.id}/active-session`}
                className="btn btn-md btn-orange"
                title="Create New Group"
              >
                Go to Session <FontAwesomeIcon icon={faRunning} />
              </Link>
            ) : (
              <button className="btn btn-light" onClick={this.startDatasession}>
                <FontAwesomeIcon
                  icon={faPlay}
                  className="me-2 text-armor-red"
                />
                <span className="d-none d-lg-inline">Start Group Session</span>
              </button>
            )}
            <button
              className="btn btn-light"
              onClick={() => this.props.openFSPopUp("ADD_PERSON")}
            >
              <FontAwesomeIcon
                icon={faUserPlus}
                className="me-2 text-armor-red"
              />
              <span className="d-none d-lg-inline">Add user</span>
            </button>
            {/* <button className="btn btn-light" onClick={() => this.props.openFSPopUp("ADD_PERSON")}>
              <FontAwesomeIcon icon={faUserPlus} className="me-2 text-armor-red" />
              <span className="d-none d-lg-inline">Edit device settings selected</span>
            </button> */}
            {/* <button className="btn btn-disabled">
              <FontAwesomeIcon icon={faTrash} className="me-2 text-armor-red" />
              <span className="d-none d-lg-inline">Delete selected</span>
            </button> */}
          </div>
        </div>
      );
    } else if (
      pathname.startsWith("/group") &&
      pathname.endsWith("/active-session")
    ) {
      return (
        <div className="row top-menu py-3">
          <div className="col-6 col-md-6 text-armor-red ">
            <Link
              className="text-armor-red"
              to={`/group/${this.props.selectedDatasession?.datasession?.groupId}/active-session`}
            >
              <span className="armor-active-link-border pb-2 px-3">
                <RiLayoutMasonryFill className="mx-2" />
                {tooSmallScreen ? "" : <b>Card</b>}
              </span>
            </Link>
            <Link
              className="text-armor-red"
              to={`/group/${this.props.selectedDatasession?.datasession?.groupId}/active-session/map`}
            >
              <span className="armor-link-border pb-2 px-3">
                <HiLocationMarker className="mx-2" />
                {tooSmallScreen ? "" : <b>Map</b>}
              </span>
            </Link>
            {tooSmallScreen}
            {tooSmallScreen ? (
              ""
            ) : (
              <Link
                className="text-armor-red"
                to={`/group/${this.props.selectedDatasession?.datasession?.groupId}/active-session/list`}
              >
                <span className="armor-link-border pb-2 px-3">
                  <FaListUl className="mx-2" />
                  <b>List</b>
                </span>
              </Link>
            )}
          </div>
          <div className="col-6 col-md-6 d-flex justify-content-end">
            <div className="d-flex align-items-center me-2">
              {this.props.selectedDatasession?.datasession?.participantCount}{" "}
              results
            </div>
            {this.props?.armorUser?.settings?.tileSortType === "COLOR" ? (
              <button
                className="btn btn-light me-2"
                onClick={() => {
                  this.props.saveUserSettings({
                    tileSortType: "NAME",
                  });
                }}
                title="Sort By Name"
              >
                <FontAwesomeIcon icon={faUser} className="text-armor-red" />
              </button>
            ) : (
              <button
                className="btn btn-light me-2"
                onClick={() => {
                  this.props.saveUserSettings({
                    tileSortType: "COLOR",
                  });
                }}
                title="Sort by Color"
              >
                <FontAwesomeIcon
                  icon={faThermometerFull}
                  className="text-armor-red"
                />
              </button>
            )}

            <button
              className="btn btn-light"
              onClick={() => this.stopSession()}
              title="Stop Datasession"
              // disabled={this.state.btnClicked}
            >
              {this.state.btnClicked ? (
                <div className="spinner-grow text-armor-red" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <Fragment>
                  <FontAwesomeIcon
                    icon={faStop}
                    className="me-2 text-armor-red"
                  />
                  <span className="d-none d-lg-inline">Stop session</span>
                </Fragment>
              )}
            </button>
          </div>
        </div>
      );
    } else if (
      pathname.startsWith("/group") &&
      pathname.endsWith("/active-session/map")
    ) {
      return (
        <div className="row top-menu py-3">
          <div className="col-6 col-md-6 text-armor-red ">
            <Link
              className="text-armor-red"
              to={`/group/${this.props.selectedDatasession?.datasession?.groupId}/active-session`}
            >
              <span className="armor-link-border pb-2 px-3">
                <RiLayoutMasonryFill className="mx-2" />
                {tooSmallScreen ? "" : <b>Card</b>}
              </span>
            </Link>
            <Link
              className="text-armor-red"
              to={`/group/${this.props.selectedDatasession?.datasession?.groupId}/active-session/map`}
            >
              <span className="armor-active-link-border pb-2 px-3">
                <HiLocationMarker className="mx-2" />
                {tooSmallScreen ? "" : <b>Map</b>}
              </span>
            </Link>
            {tooSmallScreen ? (
              ""
            ) : (
              <Link
                className="text-armor-red"
                to={`/group/${this.props.selectedDatasession?.datasession?.groupId}/active-session/list`}
              >
                <span className="armor-link-border pb-2 px-3">
                  <FaListUl className="mx-2" />
                  {tooSmallScreen ? "" : <b>List</b>}
                </span>
              </Link>
            )}
          </div>
          <div className="col-6 col-md-6 d-flex justify-content-end">
            <div className="d-flex align-items-center me-2">
              {this.props.selectedDatasession?.datasession?.participantCount}{" "}
              results
            </div>
            <button
              className="btn btn-light"
              onClick={() => this.stopSession()}
              title="Stop Datasession"
              // disabled={this.state.btnClicked}
            >
              {this.state.btnClicked ? (
                <div className="spinner-grow text-armor-red" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <Fragment>
                  <FontAwesomeIcon
                    icon={faStop}
                    className="me-2 text-armor-red"
                  />
                  <span className="d-none d-lg-inline">Stop session</span>
                </Fragment>
              )}
            </button>
          </div>
        </div>
      );
    } else if (
      pathname.startsWith("/group") &&
      pathname.endsWith("/active-session/list")
    ) {
      return (
        <div className="row top-menu py-3">
          <div className="col-6 col-md-6 text-armor-red ">
            <Link
              className="text-armor-red"
              to={`/group/${this.props.selectedDatasession?.datasession?.groupId}/active-session`}
            >
              <span className="armor-link-border pb-2 px-3">
                <RiLayoutMasonryFill className="mx-2" />
                {tooSmallScreen ? "" : <b>Card</b>}
              </span>
            </Link>
            <Link
              className="text-armor-red"
              to={`/group/${this.props.selectedDatasession?.datasession?.groupId}/active-session/map`}
            >
              <span className="armor-link-border pb-2 px-3">
                <HiLocationMarker className="mx-2" />
                {tooSmallScreen ? "" : <b>Map</b>}
              </span>
            </Link>
            {tooSmallScreen ? (
              ""
            ) : (
              <Link
                className="text-armor-red"
                to={`/group/${this.props.selectedDatasession?.datasession?.groupId}/active-session/list`}
              >
                <span className="armor-active-link-border pb-2 px-3">
                  <FaListUl className="mx-2" />
                  {tooSmallScreen ? "" : <b>List</b>}
                </span>
              </Link>
            )}
          </div>
          <div className="col-6 col-md-6 d-flex justify-content-end">
            <div className="d-flex align-items-center me-2">
              {this.props.selectedDatasession?.datasession?.participantCount}{" "}
              results
            </div>
            {this.props?.armorUser?.settings?.tileSortType === "COLOR" ? (
              <button
                className="btn btn-light me-2"
                onClick={() => {
                  this.props.saveUserSettings({
                    tileSortType: "NAME",
                  });
                }}
                title="Sort By Name"
              >
                <FontAwesomeIcon icon={faUser} className="text-armor-red" />
              </button>
            ) : (
              <button
                className="btn btn-light me-2"
                onClick={() => {
                  this.props.saveUserSettings({
                    tileSortType: "COLOR",
                  });
                }}
                title="Sort by Color"
              >
                <FontAwesomeIcon
                  icon={faThermometerFull}
                  className="text-armor-red"
                />
              </button>
            )}

            <button
              className="btn btn-light"
              onClick={() => this.stopSession()}
              title="Stop Datasession"
              // disabled={this.state.btnClicked}
            >
              {this.state.btnClicked ? (
                <div className="spinner-grow text-armor-red" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                <Fragment>
                  <FontAwesomeIcon
                    icon={faStop}
                    className="me-2 text-armor-red"
                  />
                  <span className="d-none d-lg-inline">Stop session</span>
                </Fragment>
              )}
            </button>
          </div>
        </div>
      );
    } else if (pathname.startsWith("/active-sessions")) {
      if (this.props.groupData && this.props.groupData.length > 0) {
        const activeDatasessions = this.props.groupData.filter(
          (group) => group.activeDatasession
        );
        return (
          <div className="row top-menu py-3">
            <div className="col-6 col-md-6 text-armor-red ">
              <span className="armor-active-link-border pb-2 px-3">
                <FontAwesomeIcon icon={faSquare} className="mx-2" />
                {tooSmallScreen ? "" : <b>Card</b>}
              </span>
            </div>
            <div className="col-6 col-md-6 d-flex justify-content-end">
              <div className="d-flex align-items-center me-2">
                {activeDatasessions.length} results
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className="row top-menu py-3">
            <div className="col-6 col-md-6 text-armor-red ">
              <span className="armor-active-link-border pb-2 px-3">
                <FontAwesomeIcon icon={faSquare} className="mx-2" />
                {tooSmallScreen ? "" : <b>Card</b>}
              </span>
            </div>
            <div className="col-6 col-md-6 d-flex justify-content-end">
              <div className="d-flex align-items-center me-2">0 results</div>
            </div>
          </div>
        );
      }
    } else {
      return <div></div>;
    }
  }
}

function mapStateToProps(state, ownProps) {
  const pathname = ownProps.location.pathname;
  if (pathname === "/groups") {
    return {
      groupData: state.groupReducer?.allGroups,
    };
  } else if (pathname.endsWith("/stopped")) {
    return {
      groupData: state.groupReducer?.allGroups,
      selectedDatasession: state.datasessionReducer?.histotricalDatasession,
    };
  } else if (
    pathname.startsWith("/datasession") &&
    (pathname.endsWith("/card") || pathname.endsWith("/list"))
  ) {
    return {
      groupData: state.groupReducer?.allGroups,
      selectedDatasession: state.datasessionReducer?.histotricalDatasession,
      armorUser: state.userReducer?.armorUser,
    };
  } else if (pathname.startsWith("/group") && pathname.endsWith("/edit")) {
    const matchGroupEdit = matchPath(window.location.pathname, {
      path: "/group/:id/edit",
    });
    const groupId = matchGroupEdit?.params?.id;
    if (
      state.groupReducer.allGroups &&
      state.groupReducer.allGroups.length > 0
    ) {
      return {
        selectedGroup: state.groupReducer.allGroups.find(
          (group) => +group.id === +groupId
        ),
        startedDatasession: state.datasessionReducer.startedDatasession,
      };
    }
    return {
      startedDatasession: state.datasessionReducer.startedDatasession,
      groupData: state.groupReducer?.oneGroup,
    };
  } else if (
    pathname.startsWith("/group") &&
    (pathname.endsWith("/active-session") ||
      pathname.endsWith("/active-session/list") ||
      pathname.endsWith("/active-session/map"))
  ) {
    return {
      selectedDatasession: state.datasessionReducer?.selectedDatasession,
      groupData: state.groupReducer?.allGroups,
      timerId: state.screenActivitiesReducer?.timerId,
      armorUser: state.userReducer?.armorUser,
    };
  } else if (pathname.startsWith("/active-sessions")) {
    return {
      groupData: state.groupReducer?.allGroups,
    };
  } else {
    return {
      pagination: state.datasessionReducer?.historicalDatasessions?.pagination,
    };
  }
}

export default withRouter(
  connect(mapStateToProps, {
    openFSPopUp,
    toggleDataSession,
    saveUserSettings,
    fetchOneDatasession,
    fetchAllGroups,
  })(SubMenuFirst)
);
