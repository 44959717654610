import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';

import { closeFSPopUp } from '../../actions/screen-activities';

import { connect } from "react-redux";
import { withRouter } from "react-router";

import "./style.css";
import ModalBody from './ModalBody';
import ModalTitle from './ModalTitle';

const body = document.body;
class FullScreenModal extends Component {
  componentDidMount = () => {
    body.setAttribute("style", "overflow: hidden;");
  } 

  componentWillUnmount = () => {
    body.setAttribute("style", "overflow: default;");
  }

  render() {
    return (
      <div className="export-popup">
        <div className="d-flex">
          <div className="col-1 col-md-2 col-lg-3"></div>
          <div className="col-10 col-md-8 col-lg-6 export-popup-container">
            <div className="row">
              <div className="col-10">
                <h4 className="m-2 ms-3">
                  <b><ModalTitle /></b>
                </h4>
              </div>
              <div className="col-2">
                <div className="d-flex justify-content-end">
                  <button 
                    className="btn text-armor-red"
                    onClick={this.props.closeFSPopUp}  
                  >
                    <FontAwesomeIcon 
                      icon={faWindowClose} 
                      className="fa-2x" 
                    />
                  </button>
                </div>
              </div>
            </div>

            {/* Add a modal content component, read this.props.modalAction and make dynamic if (this.props.modalAction === LOG_OUT) in modal body  */}
            <ModalBody />

          </div>
          <div className="col-1 col-md-2 col-lg-3"></div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
  };
}

export default withRouter(
  connect(mapStateToProps, {
    closeFSPopUp
  })(FullScreenModal)
);
