import axios from "axios";

import CONFIG from "../config";
import { alertWarning } from "./alert";

const {
  armorServerUrl
} = CONFIG;

const ALL_LOCATIONS_FETCHED = "ALL_LOCATIONS_FETCHED";
const NEW_DEVICE_ADDED = "NEW_DEVICE_ADDED";
const DEVICE_DELETED = "DEVICE_DELETED";
const ONE_DEVICE_EDITED = "ONE_DEVICE_EDITED";

const getAllLocationsSuccess = (data) => ({
  type: ALL_LOCATIONS_FETCHED,
  data
});

const addNewDeviceSuccess = (data) => ({
  type: NEW_DEVICE_ADDED,
  data
});

const deleteDeviceSuccess = (deviceId) => ({
  type: DEVICE_DELETED,
  deviceId
});

const editDeviceLocationSuccess = (data) => ({
  type: ONE_DEVICE_EDITED,
  data
});

export const getAllLocations = () => (dispatch, getState) => {
  const { token } = getState().userReducer;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .get(`${armorServerUrl}/admin/location`)
    .then((res) => {
        dispatch(getAllLocationsSuccess(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addNewDevice = (device) => (dispatch, getState) => {
  const { token } = getState().userReducer;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .post(`${armorServerUrl}/device`, {
      ...device
    })
    .then((res) => {
        dispatch(addNewDeviceSuccess(res.data));
    })
    .catch((err) => {
      console.log(err);
      dispatch(alertWarning(err?.response?.data?.message || err?.message));
    });
};

export const deleteDevice = (deviceId) => (dispatch, getState) => {
  const { token } = getState().userReducer;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .delete(`${armorServerUrl}/device/${deviceId}`)
    .then((res) => {
        dispatch(deleteDeviceSuccess(deviceId));
    })
    .catch((err) => {
      console.log(err);
      dispatch(alertWarning(err?.response?.data?.message || err?.message));
    });
};

export const editDeviceLocation = (device) => (dispatch, getState) => {
  const { token } = getState().userReducer;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .put(`${armorServerUrl}/device/${device.deviceId}`, {
      ...device
    })
    .then((res) => {
        dispatch(editDeviceLocationSuccess(res.data));
    })
    .catch((err) => {
      console.log(err);
      dispatch(alertWarning(err?.response?.data?.message || err?.message));
    });
};