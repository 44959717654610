let serverUrl,
  armorServerUrl = "";
let CONFIG = {};

const location = new URL(window.location.href);
const localDev = location.port && +location.port === 3000;

//  Can be replaced with ENV variable
const MAPS_JS_API_KEY = "AIzaSyAcDdDgwuZMY3acBLHe6Tb4ltB7dz6ccOk";

const MOBILE_SCREEN_RESOLUTION = 800;

//  Datatypes
const GNSS_ARR_DATATYPE = 5102;
const HEARTRATE_DATATYPE = 6000;
const CORE_TEMPERATURE_DATATYPE = 10000;
const ESTIMATED_VARIANCE_CORE_TEMP_DATATYPE = 10001;
const PSI_DATATYPE = 10002;

const datatypes = {
  GNSS_ARR_DATATYPE,
  HEARTRATE_DATATYPE,
  CORE_TEMPERATURE_DATATYPE,
  ESTIMATED_VARIANCE_CORE_TEMP_DATATYPE,
  PSI_DATATYPE,
};

const colorPalette = {
  armorLightGrey: "#F5F5F5",
  armorDarkRed: "#7A0000",
  armorOrange: "#DA551C",
  cardRed: "#EC0000",
  cardOrange: "#F26D00",
  cardGreen: "#00AE11",
  chartGreen: "#36481E",
  chartOrange: "#F26D00",
  chartRed: "#EC0000",
};

const liveDevURL = "evalan-dev-armor-dashboard-react.azurewebsites.net";
const liveSandboxURL =
  "evalan-prod-armor-sandbox-dashboard-react.azurewebsites.net";
const liveSandboxURLwithDomain = "sandbox.armorheatmonitor.com";
const liveProdURL = "evalan-prod-armor-dashboard-react.azurewebsites.net";
const liveProdURLwithDomain = "dashboard.armorheatmonitor.com";

const liveDev = location.host === liveDevURL;
const liveSandbox = location.host === liveSandboxURL;
const liveSandboxDomain = location.host === liveSandboxURLwithDomain;
const liveProd = location.host === liveProdURL;
const liveProdDomain = location.host === liveProdURLwithDomain;

// Development Slot
const devServerUrl = "https://evalan-dev-bace-phpfrontend.azurewebsites.net";
const devLocalServerUrl = "http://localhost:4000";
// const devLocalServerUrl = "http://172.23.200.58:4000";
const devArmorServerUrl = "https://evalan-dev-armor-api-v1.azurewebsites.net";
// Sandbox Slot
const sandboxServerUrl = "https://sandbox.bace-iot.com";
const sandboxArmorServerUrl =
  "https://evalan-prod-armor-sandbox-api-v1.azurewebsites.net";
// Production Slot
const prodServerUrl = "https://dashboard.bace-iot.com";
const prodArmorServerUrl = "https://evalan-prod-armor-api-v1.azurewebsites.net";

const DEV_POLLING_INTERVAL = 5000;
const PROD_POLLING_INTERVAL = 5000;

const ALERT_TIMEOUT = 5000;

if (localDev) {
  serverUrl = devServerUrl;
  armorServerUrl = devLocalServerUrl;
} else if (liveDev) {
  serverUrl = devServerUrl;
  armorServerUrl = devArmorServerUrl;
} else if (liveSandbox || liveSandboxDomain) {
  serverUrl = sandboxServerUrl;
  armorServerUrl = sandboxArmorServerUrl;
} else if (liveProd || liveProdDomain) {
  serverUrl = prodServerUrl;
  armorServerUrl = prodArmorServerUrl;
}

// Development local configuration
const devConfig = {
  serverUrl,
  armorServerUrl,
  datatypes,
  MAPS_JS_API_KEY,
  POLLING_INTERVAL: DEV_POLLING_INTERVAL,
  ALERT_TIMEOUT,
  MOBILE_SCREEN_RESOLUTION,
  colorPalette,
  clientId: "DTZcRMTd2Ijeg12aSVVQubd2Jy5gADsP",
  clientSecret: "2C8mg2aS3vI4EJXyFI9HygauYAt5lVvq",
  baseUrl: `${serverUrl}/api/v2`,
  loginUrl: `${serverUrl}/site/oauth2`,
  passResetUrl: `${serverUrl}/site/request-password-reset`,
  projectBaseUrl: `https://${liveDevURL}`,
};

// Development deployed configuration
const devLiveConfig = {
  serverUrl,
  armorServerUrl,
  datatypes,
  MAPS_JS_API_KEY,
  POLLING_INTERVAL: DEV_POLLING_INTERVAL,
  ALERT_TIMEOUT,
  MOBILE_SCREEN_RESOLUTION,
  colorPalette,
  clientId: "03D7kSLrWdOQHNRGeRL-jFYLvKOV3fJ5",
  clientSecret: "ZkgYUlPiww7LkVFYJior6w6cVplj21Ii",
  baseUrl: `${serverUrl}/api/v2`,
  loginUrl: `${serverUrl}/site/oauth2`,
  passResetUrl: `${serverUrl}/site/request-password-reset`,
  projectBaseUrl: `https://${liveDevURL}`,
};

// Sandbox configuration
const sandboxConfig = {
  serverUrl,
  armorServerUrl,
  datatypes,
  MAPS_JS_API_KEY,
  POLLING_INTERVAL: PROD_POLLING_INTERVAL,
  ALERT_TIMEOUT,
  MOBILE_SCREEN_RESOLUTION,
  colorPalette,
  clientId: "mIrtWN2dPBRE-_T2dm5BLrL7t2oITHUg",
  clientSecret: "MeTDCMZgo87CgO1DbxThL4TgNCLpFUlC",
  baseUrl: `${serverUrl}/api/v2`,
  loginUrl: `${serverUrl}/site/oauth2`,
  passResetUrl: `${serverUrl}/site/request-password-reset`,
  projectBaseUrl: `https://${liveSandboxURL}`,
};

// Sandbox configuration with Domain https://sandbox.armorheatmonitor.com/
const sandboxWithDomainConfig = {
  serverUrl,
  armorServerUrl,
  datatypes,
  MAPS_JS_API_KEY,
  POLLING_INTERVAL: PROD_POLLING_INTERVAL,
  ALERT_TIMEOUT,
  MOBILE_SCREEN_RESOLUTION,
  colorPalette,
  clientId: "nyk9-TSGDQN-z09M818PP_4YHR1c-7C3",
  clientSecret: "tGLnUSmOEAxmV3DJ3ZvokoM2-5cFLQBA",
  baseUrl: `${serverUrl}/api/v2`,
  loginUrl: `${serverUrl}/site/oauth2`,
  passResetUrl: `${serverUrl}/site/request-password-reset`,
  projectBaseUrl: `https://${liveSandboxURL}`,
};

// Production configuration
const prodConfig = {
  serverUrl,
  armorServerUrl,
  datatypes,
  MAPS_JS_API_KEY,
  POLLING_INTERVAL: PROD_POLLING_INTERVAL,
  ALERT_TIMEOUT,
  MOBILE_SCREEN_RESOLUTION,
  colorPalette,
  clientId: "kI37Mbly8GYGP3RMyDmwnsplptxRBZgD",
  clientSecret: "bBG-YAB02Ki8qf9ZSd7Paco50fKNPEqa",
  baseUrl: `${serverUrl}/api/v2`,
  loginUrl: `${serverUrl}/site/oauth2`,
  passResetUrl: `${serverUrl}/site/request-password-reset`,
  projectBaseUrl: `https://${liveProdURL}`,
};

// Sandbox configuration with Domain https://dashboard.armorheatmonitor.com/
const prodWithDomainConfig = {
  serverUrl,
  armorServerUrl,
  datatypes,
  MAPS_JS_API_KEY,
  POLLING_INTERVAL: PROD_POLLING_INTERVAL,
  ALERT_TIMEOUT,
  MOBILE_SCREEN_RESOLUTION,
  colorPalette,
  clientId: "9ak-NllqFnWW_DDkh3mHBbGdUBFK-RRv",
  clientSecret: "hnwUxpUxt2R_44XPgvuE3NcWM77Ymb_x",
  baseUrl: `${serverUrl}/api/v2`,
  loginUrl: `${serverUrl}/site/oauth2`,
  passResetUrl: `${serverUrl}/site/request-password-reset`,
  projectBaseUrl: `https://${liveProdURL}`,
};

if (localDev) {
  CONFIG = devConfig;
} else if (liveDev) {
  CONFIG = devLiveConfig;
} else if (liveSandbox) {
  CONFIG = sandboxConfig;
} else if (liveSandboxDomain) {
  CONFIG = sandboxWithDomainConfig;
} else if (liveProd) {
  CONFIG = prodConfig;
} else if (liveProdDomain) {
  CONFIG = prodWithDomainConfig;
}

export default CONFIG;
