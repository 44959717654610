import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { fetchAllDatasessions } from "../../actions/datasession";
import { fetchAllGroups, fetchGroups } from "../../actions/group";
import HistoricalDsLine from "../../components/historical-ds-list/HistoricalDsLine";
import LoaderRoller from "../../components/loader/LoaderRoller";
import FullScreenModal from "../../components/modals/FullScreenModal";
import Pagination from "../../components/pagination/Pagination";

class HistoricalDatasessions extends Component {
  componentDidMount = () => {
    const { page } = this.props.match.params;
    this.getData(page);
    this.props.fetchGroups();
  };

  componentDidUpdate = (prevProps) => {
    const { page } = this.props.match.params;
    if (prevProps.match.params.page !== page) {
      this.getData(page);
    }
  };

  getData = (page) => {
    this.props.fetchAllDatasessions(page);
    if (!this.props.allGroups) {
      this.props.fetchAllGroups();
    }
  };

  render() {
    // if (this.props.historicalDatasessions && this.props.historicalDatasessions.length && this.props.allGroups && this.props.allGroups.length) {
    if (
      this.props.historicalDatasessions &&
      this.props.historicalDatasessions.length
    ) {
      const datasessions = this.props.historicalDatasessions
        .filter((datasession) => datasession.stoppedAt !== null)
        .sort((a, b) => +b.stoppedAt - +a.stoppedAt);
      return (
        <Fragment>
          <div className="col-12 mt-3 mb-5">
            <div className="armor-card">
              <div className="table-responsive-lg">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Start date</th>
                      <th scope="col">End date</th>
                      <th scope="col">Group name</th>
                      <th scope="col">Group size</th>
                      <th scope="col">TCore max</th>
                      <th scope="col">PSI max</th>
                      <th scope="col">Heart Rate max</th>
                      <th scope="col">View/Export/Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {datasessions.map((datasession) => {
                      return (
                        <HistoricalDsLine
                          datasession={datasession}
                          key={datasession.id}
                        />
                      );
                    })}
                  </tbody>
                </table>
                <Pagination />
                {this.props.fullScreenPopUp ? <FullScreenModal /> : <div></div>}
              </div>
            </div>
          </div>
        </Fragment>
      );
    } else if (
      this.props.historicalDatasessions &&
      this.props.historicalDatasessions.length
    ) {
      return <div>No datasessions.</div>;
    } else {
      return <LoaderRoller />;
    }
  }
}

function mapStateToProps(state) {
  return {
    historicalDatasessions:
      state.datasessionReducer?.historicalDatasessions?.data,
    pagination: state.datasessionReducer?.historicalDatasessions?.pagination,
    allGroups: state.groupReducer.allGroups,
    groups: state.groupReducer?.groups,
    fullScreenPopUp: state.screenActivitiesReducer?.fullScreenPopUp,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchAllDatasessions,
    fetchAllGroups,
    fetchGroups,
  })(HistoricalDatasessions)
);
