import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import { openFSPopUp } from "../../actions/screen-activities";

import {
  openUserPopup,
  closeUserPopup,
} from "../../actions/screen-activities";

import "./style.css";

class UserPopUp extends Component {
  render() {
    return (
      <Fragment>
        <div
          className={`${this.props.topUserPopup ? 'd-flex' : 'd-none'} justify-content-end`}
        >
          <div className="user-dialog">
            <div className="col">
              <div className="user-name">{this.props.userEmail}</div>
              <button className="logout-btn" onClick={() => this.props.openFSPopUp("LOG_OUT")}>
                <FontAwesomeIcon icon={faSignOutAlt} />
                &nbsp; Log out
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}


function mapStateToProps(state) {
  return {
    userEmail: state.userReducer?.user?.email,
    topUserPopup: state.screenActivitiesReducer?.topUserPopup,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    openUserPopup,
    closeUserPopup,
    openFSPopUp
  })(UserPopUp)
);