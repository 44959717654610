import axios from "axios";

import { newRedirect } from "./redirect";

import CONFIG from "../config";
import { alertWarning } from "./alert";

const { armorServerUrl } = CONFIG;

const POLLING_ERROR_BEFORE_REDIRECT = 10;

const DATASESSION_STARTED = "DATASESSION_STARTED";
const DATASESSION_STOPPED = "DATASESSION_STOPPED";
const ACTIVE_DATASESSION_FETCHED = "ACTIVE_DATASESSION_FETCHED";
const ONE_DATASESSION_FETCHED = "ONE_DATASESSION_FETCHED";
const ALL_DATASESSIONS_FETCHED = "ALL_DATASESSIONS_FETCHED";
const ONE_DEVICE_DATA_FETCHED = "ONE_DEVICE_DATA_FETCHED";
const ONE_DATASESSION_CLEARED = "ONE_DATASESSION_CLEARED";
const ONE_DEVICE_DATA_CLEARED = "ONE_DEVICE_DATA_CLEARED";
const POLLING_DATASESSION = "POLLING_DATASESSION";
const RESET_POLLING_COUNTER = "RESET_POLLING_COUNTER";
const ONE_DATASESSION_DELETED = "ONE_DATASESSION_DELETED";

const datasessionStartSuccess = (data) => ({
  type: DATASESSION_STARTED,
  data,
});

const datasessionStopSuccess = (data) => ({
  type: DATASESSION_STOPPED,
  data,
});

const fetchOneActiveDatasessionSuccess = (data) => ({
  type: ACTIVE_DATASESSION_FETCHED,
  data,
});

const fetchOneDatasessionSuccess = (data) => ({
  type: ONE_DATASESSION_FETCHED,
  data,
});

const fetchAllDatasessionsSuccess = (data) => ({
  type: ALL_DATASESSIONS_FETCHED,
  data,
});

const fetchOneDeviceDataSuccess = (data) => ({
  type: ONE_DEVICE_DATA_FETCHED,
  data,
});

const clearOneDatasessionSuccess = () => ({
  type: ONE_DATASESSION_CLEARED,
});

const clearOneDeviceDataSuccess = () => ({
  type: ONE_DEVICE_DATA_CLEARED,
});

const datasessionPollingSuccess = () => ({
  type: POLLING_DATASESSION,
});

const clearPollingCounterSuccess = () => ({
  type: RESET_POLLING_COUNTER,
});

const deleteOneDatasessionSuccess = (data) => ({
  type: ONE_DATASESSION_DELETED,
  data,
});

export const toggleDataSession =
  (groupId, clearData) => (dispatch, getState) => {
    // On start add validation, that this validation is actulaly still running
    const { token } = getState().userReducer;

    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios
      .put(
        `${armorServerUrl}/datasession/toggle${
          clearData ? "?clearData=true" : ""
        }`,
        {
          groupId,
        }
      )
      .then((res) => {
        if (+res.status === 200) {
          if (res.data.status === "STARTED") {
            dispatch(datasessionStartSuccess(res.data));
            dispatch(newRedirect(`/group/${groupId}/active-session`));
          } else if (res.data.status === "STOPPED_AND_DELETED") {
            // dispatch(datasessionStopSuccess(res.data));
            dispatch(newRedirect("/groups"));
          } else {
            dispatch(datasessionStopSuccess(res.data));
            dispatch(newRedirect("/historical-datasessions/1"));
          }
        }
      })
      .catch((err) => {
        dispatch(alertWarning(err?.response?.data?.message || err?.message));
      });
  };

export const fetchOneDatasession = (datasessionId) => (dispatch, getState) => {
  const { token } = getState().userReducer;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .get(`${armorServerUrl}/datasession/${datasessionId}`)
    .then((res) => {
      dispatch(fetchOneDatasessionSuccess(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchActiveDatasession =
  (groupId, redirect) => (dispatch, getState) => {
    const { token } = getState().userReducer;

    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios
      .get(`${armorServerUrl}/datasession/${groupId}/active-datasession`)
      .then((res) => {
        dispatch(clearPollingCounter());
        dispatch(fetchOneActiveDatasessionSuccess(res.data));
      })
      .catch((err) => {
        const { pollingCounter } = getState().datasessionReducer;
        if (redirect && pollingCounter > POLLING_ERROR_BEFORE_REDIRECT) {
          dispatch(newRedirect("/"));
          dispatch(clearPollingCounter());
        }
        console.log(err);
      });
  };

export const fetchAllDatasessions = (page) => (dispatch, getState) => {
  const { token } = getState().userReducer;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .get(
      `${armorServerUrl}/datasession/historical?${page ? "page=" + page : ""}`
    )
    .then((res) => {
      dispatch(fetchAllDatasessionsSuccess(res.data));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchOneDeviceData =
  ({ deviceId, datasessionId }) =>
  (dispatch, getState) => {
    const { token } = getState().userReducer;

    axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
    axios
      .get(`${armorServerUrl}/device/${deviceId}/data/${datasessionId}`)
      .then((res) => {
        dispatch(fetchOneDeviceDataSuccess(res.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const clearOneDatasession = () => (dispatch) => {
  dispatch(clearOneDatasessionSuccess());
};

export const clearOneDeviceData = () => (dispatch) => {
  dispatch(clearOneDeviceDataSuccess());
};

export const datasessionPolling = () => (dispatch) => {
  dispatch(datasessionPollingSuccess());
};

export const clearPollingCounter = () => (dispatch) => {
  dispatch(clearPollingCounterSuccess());
};

export const deleteOneDatasession = (datasessionId) => (dispatch, getState) => {
  const { token } = getState().userReducer;
  const { pagination } = getState()?.datasessionReducer?.historicalDatasessions;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token.token}`;
  axios
    .delete(`${armorServerUrl}/datasession/${datasessionId}`)
    .then((res) => {
      dispatch(deleteOneDatasessionSuccess(res.data));
      if (pagination?.page) {
        dispatch(fetchAllDatasessions(pagination?.page));
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
