import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import { connect } from "react-redux";
import { withRouter } from "react-router";

class Pagination extends Component {
  render() {
    if (this.props?.pagination?.pageCount > 1) {
      const paginationSize = this.props.paginationSize || 2;

      const { pathname } = this.props.location;
      const { page } = this.props.match.params;
      const allPageNrArray = Array.from({length: this.props?.pagination?.pageCount}, (_, i) => i + 1);
      let pageNrArray = allPageNrArray
        .filter(page => {
          return +(+page - +paginationSize <= +page &&  +page + +paginationSize >= +page);
        });
      
      if (+this.props?.pagination?.pageCount > 6) {
        if (!pageNrArray.includes(1)) {
          pageNrArray = [1, ...pageNrArray];
        }
        if (!pageNrArray.includes(+this.props?.pagination?.pageCount)) {
          pageNrArray = [...pageNrArray, +this.props?.pagination?.pageCount];
        }
      }
      const baseRoute = page ? (
        pathname.substring(0, pathname.lastIndexOf("/"))
      ) : (
        pathname
      );

      return (
        <nav>
          <ul className={`${
            (this.props.justify && this.props.justify === 'start') ? (
              'justify-content-start'
            ) : (
              'justify-content-end'
            )
          } pagination m-1 mt-3`}>
            {pageNrArray.map(pageNr => (
              <li 
                className={`armor-page-item mx-2 ${+pageNr === +page ? "active armor-active-link-border mx-0 text-center" : ""}`}
                key={pageNr}
              >
                <Link 
                  to={`${baseRoute}/${pageNr}`} 
                  className="armor-page-link"
                >
                  {pageNr}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      )
    } else {
      return (
        <div></div>
      )
    }
  }
}

function mapStateToProps(state) {
  return {
    pagination: state.datasessionReducer?.historicalDatasessions?.pagination,
  };
}

export default withRouter(connect(mapStateToProps, { })(Pagination));
