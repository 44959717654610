import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from 'react-router-dom';

import { faThermometerFull } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class HistoricalSessionCard extends Component {
  render() {
    const maxHr = this.props.selectedDatasession?.maxHr.find(data => (
      +data.deviceId === +this.props.meta.deviceId
    ));
    const maxPsi = this.props.selectedDatasession?.maxPsi.find(data => (
      +data.deviceId === +this.props.meta.deviceId
    ));
    const maxTemp = this.props.selectedDatasession?.maxTemp.find(data => (
      +data.deviceId === +this.props.meta.deviceId
    ));
    const warningReached = +maxPsi?.value > +this.props.userSettings.warningThreshold ? true : false;;
    const dangerReached = +maxPsi?.value > +this.props.userSettings.dangerThreshold ? true : false;;
    const tempWarningReached = +maxTemp?.value > +this.props.userSettings.tempWarningThreshold ? true : false;
    const tempDangerReached = +maxTemp?.value > +this.props.userSettings.tempDangerThreshold ? true : false;

    if (maxHr && maxPsi && maxTemp) {
      if (this.props.userSettings.assesmentPsiTemp === "PSI") {
        // PSI card
        return (
          <Fragment>
            <Link 
              to={{
                pathname: `/history/${+this.props.meta.deviceId}/chart/${+this.props.meta.datasessionId}/stopped`,
                state: {
                  fromRoute: window?.location?.pathname
                }
              }} 
              className="col-11 col-md-5 col-xl-4 col-xxl-3 m-1 m-md-3 pb-3 card-shadow"
            >
              <div 
                className={
                  `row card-top-border
                    ${
                      (dangerReached) ? (
                        'bg-traffic-red'
                      ) : (
                        warningReached ? (
                          'bg-traffic-yellow'
                        ) : (
                          'bg-traffic-green'
                        )
                      )
                    }
                  `
                }
                style={{opacity: "20%"}}
              ></div>
              <div className="row px-3 pt-3">
                <div className="col-6">
                  <div>Name/ID</div>
                  <h4><b>{this.props.meta.participantName}</b></h4>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-9"></div>
                    <div className="col-3 text-armor-red">
                      {
                        (dangerReached) ? (
                          <FontAwesomeIcon icon={faThermometerFull} />
                        ) : (
                          ''
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="row px-3">
                <div className="col-12">
                  <div>PSI max</div>
                  <h2><b>{maxPsi?.value}</b></h2>
                </div>
              </div>
              <div className="row px-3 pt-3">
                <div className="col-6">
                  <div>Tcore max</div>
                  <div><b>{maxTemp?.value.toFixed(1)} °C</b></div>
                </div>
                <div className="col-6 text-end">
                  <div>HR max</div>
                  <div><b>{maxHr?.value.toFixed(0)} bpm</b></div>
                </div>
              </div>
            </Link>
          </Fragment>
        )
      } else {
        // Temperature card
        return (
          <Fragment>
            <Link 
              to={{
                pathname: `/history/${+this.props.meta.deviceId}/chart/${+this.props.meta.datasessionId}/stopped`,
                state: {
                  fromRoute: window?.location?.pathname
                }
              }} 
              className="col-11 col-md-5 col-xl-4 col-xxl-3 m-1 m-md-3 pb-3 card-shadow"
            >
              <div 
                className={
                  `row card-top-border
                    ${
                      (tempDangerReached) ? (
                        'bg-traffic-red'
                      ) : (
                        tempWarningReached ? (
                          'bg-traffic-yellow'
                        ) : (
                          'bg-traffic-green'
                        )
                      )
                    }
                  `
                }
                style={{opacity: "20%"}}
              ></div>
              <div className="row px-3 pt-3">
                <div className="col-6">
                  <div>Name/ID</div>
                  <h4><b>{this.props.meta.participantName}</b></h4>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col-9"></div>
                    <div className="col-3">
                      {
                        (tempDangerReached) ? (
                          <FontAwesomeIcon icon={faThermometerFull} />
                        ) : (
                          ''
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="row px-3">
                <div className="col-12">
                  <div>Tcore max</div>
                  <h2><b>{maxTemp?.value.toFixed(1)} °C</b></h2>
                </div>
              </div>
              <div className="row px-3 pt-3">
                <div className="col-6">
                  <div>PSI max</div>
                  <div><b>{maxPsi?.value}</b></div>
                </div>
                <div className="col-6 text-end">
                  <div>HR max</div>
                  <div><b>{maxHr?.value.toFixed(0)} bpm</b></div>
                </div>
              </div>
            </Link>
          </Fragment>
        )
      }
    } else {
      return (
        <Fragment>
          {/* No data card */}
          <div className="col-11 col-md-5 col-xl-4 col-xxl-3 m-1 m-md-3 pb-3 card-shadow">
            <div className="row card-top-border bg-traffic-grey"></div>
            <div className="row mt-3 p-3">
              <div className="col-8">
                <div>Name/ID</div>
                <h4><b>{this.props.meta.participantName}</b></h4>
                <div>Tcore max</div>
                <h2><b>-- °C</b></h2>
              </div>
              <div className="col-4"></div>
            </div>
            <div className="row p-3">
              <div className="col-6">
                <div>PSI max</div>
                <div><b>--</b></div>
              </div>
              <div className="col-6 text-end">
                <div>HR max</div>
                <div><b>-- bpm</b></div>
              </div>
            </div>
          </div>
        </Fragment>
      )
    }
  }
}

function mapStateToProps(state, ownProps) {
  return {
    selectedDatasession: state.datasessionReducer?.histotricalDatasession,
    userSettings: state.userReducer.armorUser.settings
  };
}

export default withRouter(
  connect(mapStateToProps, {
  })(HistoricalSessionCard)
);