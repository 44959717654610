import React, { Component, Fragment } from "react";
import GoogleMapReact from "google-map-react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import "./style.css";
import CONFIG from "../../config";
import LoaderRoller from "../loader/LoaderRoller";
import { Link } from "react-router-dom";
const { MAPS_JS_API_KEY } = CONFIG;

const Marker = ({ data, userSettings }) => {
  const temp = data.coreTemp?.value.toFixed(1);
  const psi = data.psi?.value.toFixed(0);

  let warningReached,
    dangerReached = false;
  if (userSettings.assesmentPsiTemp === "PSI") {
    warningReached = psi > +userSettings.warningThreshold ? true : false;
    dangerReached = psi > +userSettings.dangerThreshold ? true : false;
  } else {
    warningReached = temp > +userSettings.tempWarningThreshold ? true : false;
    dangerReached = temp > +userSettings.tempDangerThreshold ? true : false;
  }

  const styleForPin = dangerReached
    ? "bg-traffic-red"
    : warningReached
    ? "bg-traffic-yellow"
    : "bg-traffic-green";

  return (
    <Fragment>
      <Link to={`/history/${data.deviceId}/map/${data.datasessionId}`}>
        <div
          className={`marker ${styleForPin}`}
          style={{ cursor: "pointer" }}
          title={data?.participantName}
        >
          <div>{` ${temp} °C`}</div>
        </div>
      </Link>
    </Fragment>
  );
};

class GroupMap extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 13,
  };
  getMapOptions = (maps) => {
    return {
      styles: [
        {
          featureType: "all",
          elementType: "labels",
          stylers: [
            {
              visibility: "on",
            },
          ],
        },
      ],
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.TOP_LEFT,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.HYBRID,
          maps.MapTypeId.TERRAIN,
          maps.MapTypeId.SATELLITE,
        ],
        labels: true,
      },
    };
  };

  render() {
    if (this.props.userSettings) {
      return (
        <div style={{ height: "70vh", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: MAPS_JS_API_KEY }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
            //This will display satellite and Terrain options in the map
            options={this.getMapOptions}
          >
            {this.props.groupData.map((loc, i) => {
              return (
                <Marker
                  key={i}
                  timestamp={+loc.timestamp}
                  userSettings={this.props.userSettings}
                  lat={loc.locationData?.arrayValue[0]}
                  lng={loc.locationData?.arrayValue[1]}
                  data={loc}
                />
              );
            })}
          </GoogleMapReact>
        </div>
      );
    } else {
      return <LoaderRoller />;
    }
  }
}

function mapStateToProps(state) {
  return {
    userSettings: state.userReducer.armorUser.settings,
  };
}

export default withRouter(connect(mapStateToProps, {})(GroupMap));
