import { faSave, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component, Fragment } from 'react';

import { connect } from "react-redux";
import { withRouter, matchPath } from "react-router";

import { closeFSPopUp } from '../../../actions/screen-activities';
import { logoutUser } from "../../../actions/user";
import { getAllDevices, getUnusedDevices } from '../../../actions/device';
import { addNewParticipant } from '../../../actions/participant';

const initialState = {
  name: "",
  initialTemp: 37,
  maxInitialTemp: 38,
  minInitialTemp: 36,
  groupId: null,
  deviceId: null,
  wrongInitialTemperature: false
};

class AddParticipantBody extends Component {
  state = initialState;

  componentDidMount = () => {
    this.props.getAllDevices();
    const matchGroup = matchPath(window.location.pathname, {
      path: "/group/:id/edit",
    });

    this.props.getUnusedDevices(matchGroup.params.id);

    this.setState({
      ...this.state,
      groupId: matchGroup.params.id,
    })
  }

  handleChange = (e) => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  }

  submitForm = (e) => {
    e.preventDefault();
    if (this.state.initialTemp > this.state.maxInitialTemp || this.state.initialTemp < this.state.minInitialTemp) {
      return this.setState({
        ...this.state,
        wrongInitialTemperature: true
      });
    }
    this.props.addNewParticipant(this.state);
    this.props.closeFSPopUp();
  }

  render() {
    return (
      <Fragment>
        <div className="d-flex justify-content-end px-3 pb-3">
          <div className="container bg-light modal-body-container-scrollable">
            <div className="modal-body-container-internal">
              <div className="row mt-3">
                <h4 className="text-center">Add participiant</h4>
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <form onSubmit={(e) => this.submitForm(e)}>
                    <input type="submit" className="d-none" />
                    <div className="row">
                      <label htmlFor="participantNameOrNumber" className="col-12 col-md-4 form-label">
                        Name/Number
                      </label>
                      <div className="col-12 col-md-8">
                        <input 
                          name="name"
                          onChange={(e) => this.handleChange(e)}
                          value={this.state.name}
                          type="text" 
                          className="form-control" 
                          id="participantNameOrNumber" 
                        />
                      </div>
                    </div>

                    <div className="row mt-3">
                      <label htmlFor="initialTemperature" className="col-12 col-md-4 form-label">
                        Intial temp
                      </label>
                      <div className="col-12 col-md-8">
                        <input 
                          name="initialTemp"
                          onChange={(e) => this.handleChange(e)}
                          value={this.state.initialTemp}
                          type="number"
                          min="36"
                          max="38"
                          step="0.1" 
                          className="form-control" 
                          id="initialTemperature" 
                        />
                      </div>
                      {
                        this.state.wrongInitialTemperature ? (
                          <small className="text-traffic-red mt-1">Please set an initial temperature between 36° C and 38° C.</small>
                        ) : (
                          ""
                        )
                      }
                    </div>

                    <div className="row mt-3">
                      <label htmlFor="assignedDevice" className="col-12 col-md-4 form-label">
                        Sensor #
                      </label>
                      <div className="col-12 col-md-8">
                        <div className="input-group">
                          <input 
                            type="text" 
                            className="form-control" 
                            id="assignedDevice"
                            value={(this.props.allDevices && this.state.deviceId) ? (
                              this.props.allDevices.find(device => (+device.id === +this.state.deviceId))?.baceGoId
                            ) : ("Select Device below")}
                            disabled={true}

                          />
                          <button 
                            className="input-group-text"
                            type="button"
                            title="Remove device"
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                deviceId: null
                              })
                            }}
                          >
                            <FontAwesomeIcon icon={faTimesCircle} />
                          </button>
                        </div>

                      </div>
                    </div>

                    <div className="row mt-3">
                      <p>Select one of the devices</p>
                      {(this.props.unusedDevices && this.props.unusedDevices.length > 0) ? (
                        this.props.unusedDevices.map(device => {
                          return (
                            <button 
                              className="btn btn-sm btn-outline-secondary col-auto mx-2"
                              title="Add device"
                              key={device.baceGoId}
                              type="button"
                              onClick={() => this.setState({
                                ...this.state,
                                deviceId: device.id
                              })}
                            >
                              {device.baceGoId}
                            </button>
                          )
                        })
                      ) : (
                        !this.props.unusedDevices ? (
                          <div className="text-success">Searching for unasigned devices.</div>
                          ) : (
                          <div className="text-danger">Sorry, unasigned devices not found in this group.</div>
                        ) 
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-end m-3">
          <button 
            type="submit"
            onClick={(e) => this.submitForm(e)}
            className="btn btn-md btn-armor-red"
          >
            Save <FontAwesomeIcon icon={faSave} />
          </button>
        </div>
      </Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    allDevices: state.deviceReducer.allDevices,
    unusedDevices: state.deviceReducer.unusedDevices,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    closeFSPopUp,
    logoutUser,
    getAllDevices,
    addNewParticipant,
    getUnusedDevices
  })(AddParticipantBody)
);
