import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { fetchAllGroups } from "../../actions/group";
import { openFSPopUp } from "../../actions/screen-activities";

import LoaderRoller from '../../components/loader/LoaderRoller';
import ActiveSessionsCard from '../../components/session-cards/ActiveSessionsCard';

import "./style.css";

class ActiveSessions extends Component {
  componentDidMount = () => {
    this.props.fetchAllGroups();
  }
  render() {
    if (this.props.groupData) {
      const activeDatasessions = this.props.groupData.filter((group) => (group.activeDatasession));
      return (
        <Fragment>
          <div className="row">
            {
              (activeDatasessions.length > 0) ? (
                activeDatasessions.map((group) => {
                  return (
                    <ActiveSessionsCard group={group} key={group.id} />
                  )
                })
              ) : (
                <div className="col-12">
                  <h4><b>No Active datasessions</b></h4>
                </div>
              )
            }
          </div>
        </Fragment>
      )
    } else {
      return (
        <LoaderRoller />
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    groupData: state.groupReducer?.allGroups,
    fullScreenPopUp: state.screenActivitiesReducer?.fullScreenPopUp,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchAllGroups,
    openFSPopUp
  })(ActiveSessions)
);